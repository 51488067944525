.user-settings {
  border: 1px solid #d5d8df;
  position: relative;
  border-radius: 4px;
  padding: 40px;
  background-color: #ffffff;
  width: calc(100% - 10px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-right: 91px;

  .user-profile-details-disabled-edit{
    background-color: #F9FAFB;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;

    @media (max-width: 550px) {
      margin-top: 24px;
    }
  }

  @media (max-width: 550px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    box-shadow: none;
    border-bottom: none;
  }

  &-form-group {
    color: #333333;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    @media (max-width: 550px) {
      margin-top: 24px;
    }

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      margin-bottom: 7px;
    }
    input {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #d5d8df;
      border-radius: 4px;
      height: 42px;
      padding: 13px 12px;
      line-height: 17px;
      color: #666666;
    }
    select {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #d5d8df;
      border-radius: 4px;
      line-height: 17px;
      color: #666666;
      height: 42px;
      width: 100%;
    }
    
    textarea {
      resize: none;
      display: block;
      height: 117px;
      color: #666666;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      background: #ffffff;
      border: 1px solid #d5d8df;
      border-radius: 4px;
    }
  }
}

.social-media-section {
  border: 1px solid #d5d8df;
  border-radius: 4px;
  background-color: #ffffff;
  width: calc(100% - 10px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-right: 91px;
  padding-left: 40px;

  @media (max-width: 550px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    margin: 0 5px;
    box-shadow: none;
    border-top: none;
    margin-top: -38px;
    z-index: 2;
  }

  label {
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }

  .social-media {
    display: flex;
    border: 1px solid #d5d8df;
    border-radius: 4px;
    color: #767a8a;
    margin-top: 6px;
    width: 100%;

    .social-media-icon {
      height: 42px;
      margin-inline: 8px;
      padding: 8px 9px;
      display: flex;
      align-items: center;
      border-right: 1px solid #d5d8df;
    }

    input {
      margin: 0;
      padding: 0;
      border: none;
      color: #767a8a;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      border-radius: 4px;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0px 9999px white;
    }
  }
}

.social-media-section-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
  margin-left: 5px;
  margin-block: 24px;

  @media (max-width: 550px) {
    display: none;
  }
}

.profile-action-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
  margin-bottom: 89px;
  margin-right: 5px;

  @media (max-width: 550px) {
    justify-content: center;
    flex-direction: column-reverse;
  }

  .btn-save {
    border-radius: 4px;
    padding: 10px;
    background: #1e1e1e;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-left: 35px;

    @media (max-width: 550px) {
      margin-left: 0;
      margin-bottom: 32px;
      margin-inline: 16px;
    }
  }
  .btn-disard {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin-right: 8px;

    @media (max-width: 550px) {
      margin-right: 0;
     
    }
  }
}

.profile-details,
.update-password,
.billing-dashboard {
  .settings-wrap {
    max-width: 100%;
    .settings-container {
      display: flex;
      padding-bottom: 0;
      .settings-tab-wrap {
        width: auto;
        .settings-tab-menu {
          ul {
            width: 150px;
            padding-left: 10px;
            & > a {
              color: #365de6;
              display: inline-block;
              margin: 0 0 15px;
              svg {
                transform: rotate(-90deg);
              }
            }
            li {
              display: block;
              margin: 0 0 15px;
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
      .settings-content {
        width: calc(100% - 150px);
        max-width: 850px;
        .settings-content-body {
          height: 100%;
          margin-bottom: 15px;
          .settings-content-body--wrap {
            padding: 30px 30px 0;
            height: calc(100% - 86px);
            .settings-content-body--container {
              margin: 0;
              width: 100%;
              .settings-group-media {
                .settings-user-media--profile-picture {
                  align-items: normal;
                  border-bottom: 1px solid #d5d8df;
                  padding-bottom: 20px;
                  margin-bottom: 20px;
                  .settings-user-media--profile-picture-wrap {
                    margin-bottom: 10px;
                  }
                }
              }
              .settings-edit-group {
                margin: 0;
                .form-group {
                  max-width: 460px;
                  &:nth-of-type(1) {
                    max-width: 100%;
                    border-bottom: 1px solid #d5d8df;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    .form-group-header,
                    input {
                      max-width: 460px;
                    }
                  }
                }
              }
            }
          }
          .settings-edit-user {
            max-width: 460px;
          }
        }
        .settings-group-action-save {
          padding: 0;
          width: calc(100% - 60px);
          margin: auto;
          justify-content: normal;
          display: block;
          height: 86px;
          .settings-group-action-delete {
            float: left;
            margin-top: 25px;
            .btn {
              width: 136px;
              height: 37px;
              background-color: #fff;
              border: solid 1px #dd213d;
              color: #dd213d;
              border-radius: 4px;
              text-decoration: none;
              &:hover {
                background-color: #dd213d;
                color: #fff;
              }
            }
          }
          .btn-link {
            float: right;
            width: 125px;
            height: 37px;
            border: solid 1px #365de6;
            text-decoration: none;
            background-color: #fff;
            color: #365de6;
            margin-top: 25px;
            &:hover {
              background-color: #365de6;
              color: #fff;
            }

            &.disabled {
              background-color: #cfd0d2;
              border: none;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .user-subscriptions-wrapper {
    max-width: 100%;
    .user-subscriptions-content {
      height: 100%;
      .user-subscriptions {
        grid-template-columns: 1fr 1fr 1fr;
        .sub-item {
          .sub-item-details {
            .group-name {
              font-size: 16px;
            }
            .plan-name {
              margin-top: 10px;
              font-size: 14px;
            }
            .manage-sub {
              margin-top: 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .manage-subscription-wrapper {
    max-width: 100%;
    .manage-subscription-content {
      .plan-details {
        max-width: 510px;
      }
      .card-details {
        max-width: 510px;
      }
      .users-invoices {
        max-width: 510px;
      }
    }
  }
  .sidebar-content-loader {
    display: none !important;
    &-closed {
      display: none !important;
    }
  }
}
