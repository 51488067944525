.link-preview {
  margin-top: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .details {
    background-color: #e9ebee;
    padding: 4px 8px;
    margin-bottom: 8px;
    border: 1px solid #dadde1;

    &--announcement {
      margin-bottom: 0;
      border-radius: 0 0 4px 4px;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: 262px;
    object-fit: cover;
  }

  p {
    text-align: left;
    margin: 0 0 4px;
  }

  .title {
    font-weight: 600;
    color: #1d2129;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  .link {
    text-transform: uppercase;
  }

  .description,
  .link {
    color: #606770;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1px;
    overflow: hidden;
  }
}
