@media (max-width: 991px) {
  header.g-header {
    .header-action {
      display: block;
    }
    .header-wrap {
      .header-logo {
        margin-left: 20px;
      }
      .header-search input {
        // width: 230px;
      }
      .header-create-group {
        right: 134px;
      }
    }
  }
  .header-notifications {
    .res-notifications-link {
      display: block !important;
      color: #666;

      span{
        display: flex;
      }
    }
    .header-notifications__top-bar--button {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  header.g-header .header-wrap {
    .header-logo {
      margin-left: 10px;
    }
    .header-search {
      input {
        // width: 220px;
        font-size: 16px;
      }
    }
    .header-create-group {
      display: none;
    }
  }
  .header-notifications .header-notifications__top-bar--button {
    width: 15px;
    height: 20px;
    overflow: hidden;
  }
}
@media (max-width: 550px) {
  header.g-header {
    .header-wrap {
      padding: 0 15px;
      .header-logo {
        margin-left: 5px;
        svg {
          width: 45px;
        }
      }
      .header-search {
        input {
          // width: 185px;
          padding-right: 35px;
        }
        .search-results {
          // width: 320px;
          left: 0;
        }
      }
      .header-settings svg:not(:first-child) {
        display: none;
      }

      .header-settings {
        right: -4px;
      }

      .header-notifications {
        right: 70px;
      }

      .top-bar-mes-container {
        right: 40px;
      }    
      .header-create-group {
        right: 78px;
        width: 85px;
        font-size: 11px;
      }
    }
    .header-action {
      width: 40px;
      margin-left: -10px;
    }
  }
  #master {
    .read-more,
    .card.card-post .card-content-body {
      font-size: 14px;
    }
    .post-comment-input .comment-input {
      min-width: 200px;
      width: 100%;
    }
    .PostEditor__container {
      width: calc(100% - 42px);
    }
  }
}

@media (max-width: 340px) {
  header.g-header .header-wrap .header-search input {
    width: 130px;
  }
}
