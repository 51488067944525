@import "../../../assets/styles/components/dropdown";

.no-chats-icon{
  right: 40px !important;
}

.header-notifications {
  @media(max-width: 550px) {
    right: 50px !important;
  }
  .dropdown{
    overflow: hidden;
  }

  &-lecture {
    right: 90px !important;

    @media(max-width: 550px) {
      right: 72px !important;
    }
  }

  .res-notifications-link {
    display: none;

    &--span{
      display: flex;
    }
    .notifications-count {
      background-color: #ffbb51;
      color: #3a3834;
      border-radius: 50%;
      position: absolute;
      left: 12px;
      top: -12px;
      font-size: 11px;
      font-weight: 700;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      z-index: 99;
    }
  }
  .header-notifications__top-bar--button {
    position: relative;

    .top-bar-notifications-icon{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:focus {
      outline: none;
    }

    .notifications-count {
      background-color: #ffbb51;
      color: #3a3834;
      border-radius: 50%;
      position: absolute;
      left: 12px;
      top: -12px;
      font-size: 11px;
      font-weight: 700;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }

  &.dropdown-menu {
    .dropdown {
      width: 320px;
      top: 38px;
    }
  }

  .header-notifications-wrap {
    border-radius: .3rem !important;
    width: 400px !important;
    .notification-js-area {
      height: 439px;
    }
    .header-notifications-box {
      .header-notifications-item {
        padding: 15px;
        border-bottom: 1px solid #eff0f2;
        height: 60px;
        cursor: default;

        &:hover {
          background-color: #f8f8f8;
        }
        &:last-of-type {
          border: 0;
        }

        .header-notifications-item--image {
          cursor: default;
          .profile-image {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }

        .header-notifications-item--message {
          margin-left: 15px;

          time {
            display: flex;
            font-size: 12px;
            color: gray;
          }

          span {
            color: #1c1e21;
            font-size: 12px;
          }
          .header-notifications-item--message__content {
            cursor: default;
            a {
              color: #365de6;
              padding: 25px 250px 20px 250px;
              margin: -25px -250px -20px -250px;
            }
          }
        }
      }
      .header-notifications-no-items {
        color: #1c1e21;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 439px;
        cursor: default;
      }
    }
    .header-notifications-footer {
      height: 50px;
      border-top: 1px solid #eff0f2;
      a {
        color: #365de6;
        font-size: 13px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #f8f8f8;
          color: #365de6!important;
        }
      }
    }
  }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {
    .header-notifications-lecture-ipad{
      right: 105px !important;
    }
}

@media(max-width: 768px) {
  .header-notifications-lecture-ipad{
    right: 105px !important;
  }
}

@media(max-width: 550px) {
  .header-notifications-lecture-ipad{
    right: 80px !important;
  }
}
