@media (max-width: 991px) {
  .sidebar-channel-only-wrap-is-open {
    .sidebar-channel-only__wrap {
      left: 0;
    }
  }
  #master main#site-content {
    // z-index: -2;
    .main-wrap.sidebar-channel-only-wrap-is-open .content-wrap.no-auth {
      left: 300px;
    }
  }
}
