* {
    min-height: auto;
}

.cancel-subscription {
    .dialog-header {
        padding: 24px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;

        &-text {
            font-size: 22px;
            color: #33353b;
            font-weight: 700;
        }

        .dialog-close-icon {
            color: #767a8a;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .dialog-body {
        padding: 18px 14px;

        &-desc {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            line-height: 24px;

            &-member {
                font-weight: 700;
                color: #33353b;
            }
        }

        &-options {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 14px;
            font-weight: 700;
            font-size: 16px;
            color: #33353b;

            &-text {
                margin-right: 30px;
            }

            &-body {
                &-immediately {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;
                    &-choice {
                        width: 15px;
                        height: 15px;
                        margin: 0;
                        margin-right: 4px;
                    }
                    
                    &-label {
                        padding-top: 2px;
                        font-weight: 400;
                        // background-color: #365de6;
                        font-size: 16px;
                        list-style: 15px;
                    }
                }

                &-later {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;
                    margin-top: 12px;

                    &-choice {
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                        margin: 0;
                        padding: 0;
                        margin-right: 4px;
                    }
                    
                    &-label {
                        padding-top: 2px;
                        font-weight: 400;
                        font-size: 16px;
                        list-style: 15px;
                    }
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 14px;
        border-top: 1px solid #e6e6e6;

        &-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 24px;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            text-align: center;
            border: 1px solid #767a8a;
            background-color: #365de6;
            color: #fff;
        }
    }
}
