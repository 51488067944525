.custom-user {
    display: flex;
    position: relative;

    .header {
        .member-profile-img {
            display: flex;
            align-self: baseline;
            justify-content: center;
            align-items: center;
            height: 43px;
            width: 43px;
            min-height: 43px;
            min-width: 43px;
            border-radius: 50%;
            overflow: hidden;
            color: white;
            object-fit: cover;
            border-radius: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: bold;

            img {
                background-color: #767a8a;
                padding: 1px;
            }
        }
    }

    .right {
        margin-left: 16px;
        display: flex;
        flex-direction: column;

        .email {
            color: #666;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 175% */
        }
        .name {
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;

            &:hover {
                color: #365de6 !important;
            }
        }
    }

    .mini-profile {
        position: absolute;
        width: 236px;
        height: auto;
        left: 50px;
        top: 16px;
        z-index: 2;
        background: #ffffff;
        border: 1px solid #d5d8df;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 4px 0px 4px 4px;
        padding: 16px;
        cursor: auto;

        @media (max-width: 550px) {
            left: 50px;
            top: 16px;
        }

        .mini-profile-upper {
            display: flex;
            margin-bottom: 20px;

            .profile-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
                background-color: #767a8a;
            }

            .no-profile-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                color: #ffffff;
                margin-right: 11px;
            }
            .mini-profile-names {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .member-name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #333333;
                    max-width: 150px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .mini-profile-lower {
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;

            .view-profile-btn {
                background: #ffffff;
                border: 2px solid #1e1e1e;
                border-radius: 4px;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: #1e1e1e;
                padding: 10px 0;
                width: 100%;
                // margin-right: 12px;
            }

            .message-btn {
                background: #1e1e1e;
                border: 2px solid #1e1e1e;
                border-radius: 4px;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: #ffffff;
                padding: 10px 0;
                width: 100%;
                margin-left: 12px;
            }
        }
    }
}
