.app-loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &-noparent {
        min-height: calc(100vh - 280px);
    }
}