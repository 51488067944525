.gp-bunny-video-player {
    &-container {
        position: relative;
        padding-top: 56.25%;
    }
    
    &-iframe {
        border: none; 
        position: absolute; 
        top: 0; 
        height: 100%; 
        width: 100%;
    }
}