$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

.group-table-grid {
  display: flex;
  flex-direction: column;

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eff0f2;
    margin-top: 3px;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .user-component {

    .name,
    .info {
      width: auto !important;
      color: #33353b !important;

      font-size: 14px !important;
      line-height: 24px;
      font-weight: unset !important;
    }
  }

  table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns: 0.7fr 3fr 3fr 2fr 2fr 1.5fr 1fr;

    &.public {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.modal-member {
      padding: 0;
      display: flex;
      flex-direction: column;

      thead {
        tr {
          display: grid;
          grid-template-columns: minmax(12px, 0.2fr) 1fr 1fr 1fr;

          th {
            padding: 0 0 0 20px;
            margin: auto 0;
            border-bottom: 1px solid #d5d8df;
            height: 40px;
            display: flex;
            align-items: center;
            font-weight: 700;

            &:first-child {
              padding: 0;
            }

            &:last-child {
              justify-content: flex-end;
              font-size: 11px;
            }

            input[type="checkbox"] {
              margin: 0;
            }
          }
        }
      }

      tbody {
        height: 250px;
        display: block;
        overflow-y: auto;

        tr {
          display: grid;
          grid-template-columns: minmax(12px, 0.25fr) 1fr 1fr 1fr;

          td {
            &:first-child {
              margin: 0;
              padding: 0;
            }
          }

          .row-actions {
            overflow: visible;
          }

          .dropdown-menu {
            .menu-dots {
              transform: rotate(90deg);
            }

            .dropdown {
              right: -9px;
              top: 30px;
            }
          }

          input[type="checkbox"] {
            margin: 0;
          }
        }
      }
    }

    &.modal-menage-member {
      display: flex;
      flex-direction: column;

      thead {
        tr {
          display: grid;
          grid-template-columns: minmax(12px, 0.2fr) 1fr 1fr 1fr;

          th {
            padding: 0 0 0 20px;
            margin: auto 0;
            border-bottom: 1px solid #d5d8df;
            height: 40px;
            display: flex;
            align-items: center;

            &:first-child {
              padding: 0;
            }

            input[type="checkbox"] {
              margin: 0;
            }
          }
        }
      }

      tbody {
        height: 250px;
        display: block;
        overflow-y: auto;

        tr {
          display: grid;
          grid-template-columns: minmax(12px, 0.25fr) 1fr 1fr 1fr;

          td {
            &:first-child {
              margin: 0;
              padding: 0;
            }
          }

          input[type="checkbox"] {
            margin: 0;
          }
        }
      }
    }

    thead {
      tr:first-child {
        display: none;
      }
    }

    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      position: sticky;
      top: 0;
      text-align: left;
      font-weight: normal;
      font-size: 1.1rem;
      color: white;
    }

    th:last-child {
      border: 0;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #808080;
      border-bottom: 1px solid #eff0f2;
    }

    tbody {
      tr {
        margin: 5px 0;
      }
    }

    th {
      text-align: left;
      color: #767a8a;
      font-size: 13px;
      line-height: 24px;
      font-weight: 700;
    }

    td {
      color: #33353b !important;
      font-size: 14px !important;
      text-transform: capitalize;
      align-items: center;
      display: flex;
    }
  }

  .group-pagination {
    color: #33353b;
    font-size: 12px;
    align-items: center;
    display: flex;

    .selected {
      font-weight: 600;
      margin-left: 5px;

      span {
        font-weight: 400;
      }
    }
  }

  .table-actions {
    display: flex;
    flex-direction: row;
    padding: 12px 20px;

    .btn-secondary.blue {
      margin-right: 8px;
      padding-left: 10px;
      padding-right: 10px;
      width: auto;
    }
  }

  input[type="checkbox"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 19px;
    width: 19px;
    border: 1px solid #d5d8df;
    background-color: #ffffff;
    font-family: "Font Awesome 5 Free";
    // appearance: none;
    padding: 0;
    border-radius: 0;

    &:checked {
      background: #365de6;
      border: 1px solid #63656d;
    }

    button {
      height: 28px;
    }
  }
}

.main-wrap {
  .members-list {
    .dropdown-menu {
      .dropdown {
        width: 150px;
        padding: 10px 0;
        right: -6px;
        top: 40px;

        &:before {
          margin-left: -11px;
          border-width: 11px;
          right: 8px;
        }

        &:after {
          margin-left: -11px;
          border-width: 10px;
        }
      }
    }
  }
}

.group-table {
  flex-direction: column;

  .table-header {
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #eff0f2;
    margin-top: 3px;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .user-component {

    .name,
    .info {
      width: auto !important;
      color: #33353b !important;
      font-size: 14px !important;
      line-height: 24px;
      font-weight: unset !important;
    }
  }

  table {
    overflow: auto;
    display: block;
    padding-bottom: 110px !important;

    &.public {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.modal-member {
      padding: 0;
      flex-direction: column;

      thead {
        tr {
          th {
            padding: 0 0 0 20px;
            margin: auto 0;
            border-bottom: 1px solid #d5d8df;
            height: 40px;
            align-items: center;

            &:first-child {
              padding: 0;
            }

            &:last-child {
              justify-content: flex-end;
              font-size: 11px;
            }

            input[type="checkbox"] {
              margin: 0;
            }
          }
        }
      }

      tbody {
        height: 250px;
        overflow-y: auto;

        tr {
          grid-template-columns: minmax(12px, 0.25fr) 1fr 1fr 1fr;

          td {
            &:first-child {
              margin: 0;
              padding: 0;
            }
          }

          input[type="checkbox"] {
            margin: 0;
          }
        }
      }
    }

    &.modal-menage-member {
      thead {
        tr {
          th {
            padding: 0 0 0 20px;
            margin: auto 0;
            border-bottom: 1px solid #d5d8df;
            height: 40px;
            align-items: center;
            font-weight: 700;

            &:first-child {
              padding: 0;
            }

            input[type="checkbox"] {
              margin: 0;
            }
          }
        }
      }

      tbody {
        height: 250px;
        overflow-y: auto;

        tr {
          td {
            &:first-child {
              margin: 0;
              padding: 0;
            }
          }

          input[type="checkbox"] {
            margin: 0;
          }
        }
      }
    }

    thead {
      tr:first-child {
        display: none;
      }
    }

    thead,
    tbody,
    tr {}

    th,
    td {
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      position: sticky;
      top: 0;
      text-align: left;
      font-weight: normal;
      font-size: 1.1rem;
      color: white;
      background-color: white;
    }

    th:last-child {
      border: 0;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #808080;
      border-bottom: 1px solid #eff0f2;
    }

    tbody {
      tr {
        margin: 5px 0;
        position: relative;

        .user-component{
          cursor: pointer;

          .mini-profile{
            position: absolute;
            left: 100px;
            top: 23px
          }
        }

    
      }
    }

    th {
      text-align: left;
      color: #333333;;
      font-size: 13px;
      line-height: 24px;
      font-weight: 700;
      position: sticky;
    }

    td {
      color: #33353b !important;
      font-size: 14px !important;
      text-transform: capitalize;
      align-items: center;
    }
  }

  .group-pagination {
    color: #33353b;
    font-size: 12px;
    align-items: center;
    display: flex;

    .selected {
      font-weight: 600;
      margin-left: 5px;

      span {
        font-weight: 400;
      }
    }
  }

  .table-actions {
    display: flex;
    flex-direction: row;
    padding: 12px 20px;

    .btn-secondary.blue {
      margin-right: 8px;
      padding-left: 10px;
      padding-right: 10px;
      width: auto;
    }
  }

  input[type="checkbox"] {
    width: 19px;
    height: 19px;
    display: flex;
  }
}

.main-wrap {
  .members-list {
    .dropdown-menu {
      .dropdown {
        width: 200px;
        padding: 10px 0;
        right: -6px;
        top: 40px;

        &:before {
          margin-left: -11px;
          border-width: 11px;
          right: 8px;
        }

        &:after {
          margin-left: -11px;
          border-width: 10px;
        }
      }
    }
  }
}