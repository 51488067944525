  .headlineButtonWrapper {
    display: inline-block;
  }
  
  .headlineButton {
    background: none;
    color: #888;
    font-size: 19px;
    border: 0;
    padding-top: 1px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    font-weight: bolder;
  }
  
  .headlineButton:hover,
  .headlineButton:focus {
    background: #fbfbfb;
  }