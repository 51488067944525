@media (max-width: 1500px) {
  .profile-details,
  .update-password,
  .billing-dashboard {
    .user-subscriptions-wrapper {
      .user-subscriptions-content {
        .user-subscriptions {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .profile-details,
  .update-password,
  .billing-dashboard {
    .settings-wrap {
      .settings-container {
        .settings-content {
          width: 100%;
        }
        .settings-tab-wrap {
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
          margin-left: -150px;
          .settings-tab-menu {
            ul {
              margin-top: 30px;
            }
          }
        }
        .settings-content {
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
      }
    }
    &.sidebar-is-open {
      .settings-wrap {
        .settings-container {
          .settings-tab-wrap {
            margin-left: 0;
          }
          .settings-content {
            margin-right: -150px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .profile-details,
  .update-password,
  .billing-dashboard {
    .user-subscriptions-wrapper {
      .user-subscriptions-content {
        .user-subscriptions {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .profile-details,
  .update-password,
  .billing-dashboard {
    .settings-wrap {
      .settings-container {
        .settings-content {
          .settings-content-body {
            .settings-content-body--wrap {
              padding: 15px 15px 0;
            }
          }
          .settings-group-action-save {
            width: calc(100% - 30px);
          }
        }
        .settings-tab-wrap {
          .settings-tab-header {
            height: 0px;
          }
        }
      }
    }
  }
}
