.notification-item {
  font-size: 12px;
  padding: 15px;
  border-bottom: 1px solid #eff0f2;
  height: 50px;
  cursor: pointer;
  margin: 2.5px 0;
  position: relative;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;

  &:hover {
    background-color: #f8f8f8;
    .notification-options {
      display: flex !important;
    }
  }
  &:last-of-type {
    border: 0;
  }

  .notification-options {
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    right: 2px;
    top: 8px;
    padding: 10px;
    z-index: 100;
    border-radius: 100%;
    display: none !important;

    &.topbarview {
      padding: 0px !important;
      width: 25px; 
      height: 25px;
      right: 10px;

      svg {
        path {
          fill: #000000;
        }
        font-size: smaller !important;
        margin: auto;
        margin-left: 7px;
      }
    }

    @media(max-width: 550px) {
      display: flex !important;

    }

    &--visible {
      display: flex;
    }
  }

  .notification-item--image {
    cursor: pointer;
    .profile-image {
      color: white;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  .notification-item--message {
    margin-left: 15px;

    time {
      display: flex;
      color: gray;
    }

    span {
      color: #1c1e21;
    }
    .notification-item--message__content {
      cursor: pointer;
      
      span {
        color: #365de6;
      }
    }
  }
}

.active-notification {
  background-color: rgb(237, 242, 250);
  position: relative;
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #365de6;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .notification-item--message {
    width: calc(100% - 60px);
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
}

.not-active-notification {
  position: relative;
 
  .notification-item--message {
    width: calc(100% - 60px);
    &__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
    }
  }
}

.dot {
  color: #1c1e21 !important;
}

.notification {
  &-container {
    height: fit-content !important;
  }

  &-postable-name {
    word-wrap: break-word;
  }
}

.short-content{
  max-width: calc(100% - 40px);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 550px) {
    max-width: 230px;
    width: 100%;
  }
  
  &-text{
    color: #333333 !important;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden; 
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }


  
}