.groupapp-toggle {
    position: relative;
    display: inline-block;
    width: 62px;
    height: 32px;
    margin-left: 5px;

    &-small {
      height: 10px;
      width: 40px;
    }

    &-medium {
      height: 22.29px;
      width: 49.19px;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #D5D8DF;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;

        &.small {
          &::before {
            bottom: -3.5px;
            left: - 1.5px;
            width: 18px;
            height: 18px;
          }
        }

        &.medium {
          &::before {
            bottom: 0px;
            left: 0px;
            width: 22.29px;
            height: 22.29px;
          }
        }

        &.dark-handle {
          &::before {
            background-color: #717070;
          }
        }
    }

    span:before {
        position: absolute;
        content: "";
        height: 28px;
        width: 28px;
        left: 4px;
        bottom: 1.7px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: #365DE6;
      }
      
      input:focus + span {
        box-shadow: 0 0 1px #365DE6;
      }
      
      input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
}