@media (max-width: 767px) {
  .post-comment {
    .comment-content {
      .options {
        height: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
@media (max-width: 550px) {
  .post-comment .comment-content {
    .comment-content-background {
      max-width: 100%;
      width: 100%;
    }
    .options {
      right: 10px;
      bottom: inherit;
      top: 10px;
    }
  }
}
