.modal {
  min-height: 370px;
  max-width: 695px;
  width: 100%;
  border: 1px solid #d5d8df;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  margin: 80px auto 50px;
  padding: 50px;
  position: absolute;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    left: inherit !important;
  }
}
