@import "../../assets/styles/components/button";
.form-wrap {
  .form-header {
    height: 30px;
    padding: 15px;
    h1 {
      color: #000000;
      font-size: 18px;
    }
    .modal-close {
      i {
        width: 14px;
        height: 14px;
      }
      &:hover {
        path {
          fill: #4c4c4c;
        }
      }
    }
  }
}
.ga-modal {
  .close-modal {
    width: 14px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .popup__sign-up-footer {
    text-align: center;
    font-size: 12px;
    max-width: 300px;
    color: #767a8a;
    margin: 0 auto;

    span {
      color: #365de6;
    }
  }
  .separate-on-modal {
    text-align: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 340px;
    margin: 0 auto;

    .separate-on-modal--text__wrap {
      padding: 5px;
      margin: 5px 0;
      display: block;

      &:before {
        content: "" !important;
        position: absolute !important;
        border-bottom-width: 1px !important;
        border-bottom-style: solid !important;
        border-bottom-color: rgb(228, 228, 228) !important;
        top: 50% !important;
        right: 55% !important;
        width: 100% !important;
      }
      span {
        line-height: 1.28571em !important;
        color: #767a8a !important;
        margin: 0px !important;
        font-size: 14px;
      }
      &:after {
        content: "" !important;
        position: absolute !important;
        border-bottom-width: 1px !important;
        border-bottom-style: solid !important;
        border-bottom-color: rgb(228, 228, 228) !important;
        top: 50% !important;
        left: 55% !important;
        width: 100% !important;
      }
    }
  }
  .poop-login-sign__up {
    width: 100%;
    max-width: 340px;
    height: 48px !important;
    font-size: 17px !important;
    margin: 0 auto;
    display: block;
  }
  .error-message {
    height: 32px;
    width: 100%;
    max-width: 340px;
    border: 1px solid #f53f5e;
    border-radius: 5px;
    background-color: #fce1e5;
    margin-top: 20px;
    p {
      color: #f53254;
      font-size: 13px;
    }
  }
}
.upload-limit-popup {
  text-align: center;
  padding: 40px 20px 40px;
  .close-modal {
    width: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .message-content {
    p {
      &.heading {
        font-weight: 700;
      }
    }
    button {
      border-radius: 4px;
      background-color: #fff;
      color: #365de6;
      border: 1px solid #365de6;
       
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      padding: 3px 10px;
    }
  }
}