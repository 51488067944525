.container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .label-closed-container {
        box-sizing: border-box;
        width: 695px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-self: center;
        padding: 40px 60px;
        height: 243px;
        margin-top: 80px;
        justify-content: center;
    
        @media (max-width: 550px) {
            margin-left: 0 !important;
        }
    
        &-header {
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: #1e1e1e;
        }
    
        &-subtext {
            margin-top: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: #767a8a;
            margin-bottom: 16px;
        }

        &-link{
            color: #365de6;
            
        }
    }
}

