.groupapp-select {
    &-container {
        position: relative;
        width: 100%;
    }

    &-input {
        &-label {
            margin: 0;
            padding: 0;
             
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #333333;

            &-required-asterisk {
                margin-left: 2px;
                color: #EB5758;
            }
        }

        &-wrapper {
            margin-top: 5px;
            width: 100% !important;
            border: 1px solid #D5D8DF;
            box-sizing: border-box;
            border-radius: 4px;
            // height: 42px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            min-height: 42px;
            background-color: #ffffff;

            &.highlighted {
                outline: none;
                border: 1px solid #365de6;
            }

            &.no-label {
                margin-top: 0;
            }
        }

        &-input {
            margin-top: 5px;
            width: 100% !important;
            border: 1px solid #D5D8DF;
            box-sizing: border-box;
            border-radius: 4px;
            height: 42px;
            padding-left: 10px;
            cursor: pointer;

            &:focus {
                outline: none;
                border: 1px solid #365de6;
            }

            &.no-label {
                margin-top: 0;
            }

            &.no-caret {
                caret-color: transparent;
            }

            &.cursor-pointer {
                cursor: pointer;
            }
        }

        &-icon {
            position: absolute;
            right: 15px;
            top: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            // pointer-events: none;
            cursor: pointer;

            &.no-label {
                top: 18px;
            }
        }
    }

    &-popup {
        width: 100%;
        position: absolute;
        border: 1px solid #D5D8DF;;
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 2147483647;
        top: 70px;
        background-color: #ffffff;
        overflow: hidden;

        &.no-label {
            top: 50px;
        }

        &--tippy {
            width: fit-content;
            min-width: 150px;
            position: absolute;
            border: 1px solid #D5D8DF;;
            box-sizing: border-box;
            border-radius: 4px;
            z-index: 99999;
            top: 0px;
            left: -80px;
            background-color: #ffffff;
        }
    }
}