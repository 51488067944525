ul {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.confirmation-modal {
    height: 242px;
    width: 654px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    &-content {
        .confirmation-msg {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        .title {
            color: #000000;

            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin: 0;
        }
        .subtitle {
            margin: 24px 40px;
            color: #767a8a;

            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            margin-top: 10px;
        }
        .confirmation-btns {
            display: flex;
            margin-top: 6px;
            .del-btn {
                box-sizing: border-box;
                height: 37px;
                width: 125px;
                border: 1px solid #dd213d;
                border-radius: 4px;
                color: #dd213d;

                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
                transition: 0.5s;
                margin-right: 12px;
                &:hover {
                    color: #ffffff;
                    background-color: #dd213d;
                }
            }
            .cancel-btn {
                box-sizing: border-box;
                height: 37px;
                width: 125px;
                border: 1px solid #365de6;
                color: #365de6;
                border-radius: 4px;

                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
                transition: 0.5s;
                margin-left: 12px;
                &:hover {
                    color: #ffffff;
                    background-color: #365de6;
                }
            }
        }
    }
}

.messages-sidebar {
    padding: 16px 0;
    border-right: 1px solid #d5d8df;
    max-width: 380px;
    width: 380px;

    &-mes-menu {
        padding: 0px 14px;
        width: 350px;
        margin-top: -6px;

        @media (max-width: 550px) {
            padding: 0 14px;
            width: 380px;
        }

        .dropdown {
            right: -12px !important;
            width: auto !important;
            top: 22px !important;
        }
    }

    .chats-list {
        width: 360px;
        padding: 4px 8px;
        height: 80vh;
        overflow: hidden;
        margin-bottom: 50px;

        @media (max-width: 550px) {
            width: calc(100vw - 10px);
            overflow: auto;
            margin-bottom: 10px;
        }

        &:hover {
            overflow-y: auto;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #aaa;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
            border-radius: 10px;
        }

        .ScrollSpinner {
            width: 330px !important;
            overflow: hidden !important;
            height: 100%;
            
            p{
                cursor: pointer;
            }
        }

        &.active {
            background-color: rgba(54, 92, 230, 0.06);
            border-radius: 3px;
        }

        .mes-open {
            cursor: pointer;
        }

        .messages-body {
            display: flex;
            width: 353px;
            position: relative;
            padding: 6px 10px;
            margin-bottom: 6px;

            @media (max-width: 550px) {
                width: calc(100vw - 12px);
            }

            &-image {
                background-color: #767a8a;
                height: 35px;
                width: 35px;
                border-radius: 100%;
                padding: 1px;
                margin-right: 8px;

                .profile-image {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }

            &:hover {
                border-radius: 3px;
                background-color: rgba(54, 92, 230, 0.06);
                img {
                    &.dots {
                        display: block;
                        cursor: pointer;
                    }
                }
            }
            img {
                margin-right: 10px;
                &.dots {
                    display: none;
                }
            }

            &.active {
                background-color: rgba(54, 92, 230, 0.06);
                border-radius: 3px;
            }

            .profile-no-image {
                margin-right: 8px;
                height: 35px;
                width: 35px;
                color: #ffffff;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
            }

            .group-profile-pic {
                height: 35px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                margin-right: 8px;

                .loader {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background-color: #ccc;
                    position: relative;
                }

                .loading-bar {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    background-color: #c3c3c3; /* You can change the color to your desired loader color */
                    position: absolute;
                    animation: fadeInOut 500ms linear infinite;
                }

                @keyframes fadeInOut {
                    0%,
                    100% {
                        opacity: 0.2;
                    }
                    50% {
                        opacity: 1;
                    }
                }

                li {
                    width: 30px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:not(:first-child) {
                        margin-left: -12px;
                    }
                }

                .message-plus-members {
                    position: relative;
                    display: flex;

                    .member-count {
                        background-color: rgba(0, 0, 0, 0.7);
                        height: 35px !important;
                        color: #ffffff;
                        width: 35px !important;
                        border-radius: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 14px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 99;
                        margin-left: 3px;
                        font-weight: 700;
                    }
                }
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;

                .message-content-count {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .message-timestamp {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 4px;
                    height: 23px;

                    .recipient-name {
                        color: #19283a;
                        font-weight: 700;
                        font-size: 14px;
                        max-width: 120px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .recipient-timestamp {
                        font-size: 12px;
                        color: #767a8a;
                        font-weight: normal;
                        display: flex;

                        .ago {
                            margin: 0 3px;
                        }
                    }
                    .member-actions-dropdown {
                        margin-top: -10px;
                        width: 10px;
                        font-weight: normal !important;

                        .member-actions-dropdown {
                            right: -10px !important;
                            overflow: hidden;
                            top: 14px;
                            width: 100px !important;
                        }
                    }
                }
                .message-content {
                    font-size: 14px;
                    color: #767a8a;
                    font-weight: 400;
                    line-height: 16px;
                    max-width: 220px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .unread-message-content {
                    font-size: 13px;
                    font-weight: 700;
                    color: #19283a;
                    line-height: 16px;
                    max-width: 220px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .member-actions-dropdown {
            padding-top: 16px;

            .dropdown {
                right: -22px !important;
            }
        }

        .unread-messages-container {
            // margin: 0px 5px;
            margin-right: 12px;
            background-color: #365de6;
            height: 12px;
            width: 12px;
            border-radius: 50%;
        }
    }

    .inbox-header {
        border-bottom: 1px solid #d5d8df;
        width: 100%;
        color: #484848;
        margin: 14px 0;
        padding: 0 14px;
        padding-bottom: 13px;

        &-inbox {
            margin-right: 26px;
            cursor: pointer;
        }

        &-unread {
            cursor: pointer;
        }

        .active {
            text-decoration: underline;
            text-underline-offset: 16px;
            text-decoration-color: #365de6;
            text-decoration-thickness: 2px;
            font-weight: 700;
        }
    }

    .no-messages {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 15px;
        color: #767a8a;
        font-weight: 700;

        .icon {
            color: #666;
            width: 52px;
            height: 52px;
            display: block;
            margin-bottom: 3px;
        }

        .heading {
            margin-bottom: 3px;
            color: #484848;
        }
        .sub-heading {
            font-weight: 400;
        }
    }

    .no-messages {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;

        &-text {
            font-size: 15px;
            color: #767a8a;
            font-weight: 700;
        }
    }
}

@media (max-width: 550px) {
    .messages-sidebar {
        overflow-y: auto !important;
        max-width: 100vw;
        width: 100%;

        .direct-messages-dropdown {
            padding-bottom: 60px;
        }
    }
}
