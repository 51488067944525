// p{
//   margin: 0;
// }

.empty-payload{
  color: #484848;
  font-weight: 600;
  font-size: 16.38px;
  text-align: center;
}

.user-profile-body-middle-comments {
  margin: 24px 0;
  line-height: 22px;
  color: #1e1e1e;
  font-weight: 600;
  font-size: 18px;
  
  @media (max-width: 550px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.comment-container {
  list-style: none;
  background-color: white;
  border: 1px solid #d5d8df;
  padding: 19px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 10px);
  margin: 0 auto;
  margin-bottom: 24px;

  .comments-inner-container {
    display: flex;
    align-items: flex-start;
    padding: 0;

    .timestamp-menu-dots {
      display: none;
    }

    &:hover {
      .timestamp-menu-dots {
        position: absolute;
        right: 24px;
        top: 18px;
        display: block;
      }
    }

    .timestamp-menu-dots-small {
      display: block;
      position: absolute;
      right: 24px;
      top: 18px;
    }
    @media (min-width: 550px) {
      .timestamp-menu-dots-small {
        display: none;

        &:hover {
          .timestamp-menu-dots {
            display: none;
          }
        }
      }
    }

    @media (max-width: 550px) {
      &:hover {
        .timestamp-menu-dots {
          display: none;
        }
      }
    }

    .comment-icon {
      margin-right: 10px;
      color: #767a8a;
    }

    @media (max-width: 450px) {
      .commen-icon {
        margin-right: 8px;
      }
    }

    .comment-header-wrapper {
      width: 100%;
      margin-right: 35px;

      @media (max-width: 450px) {
        margin-right: 0;
      }

      .comment-header-wrapper-post-name {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #666666;
        margin-bottom: 6px;
        display: flex;

        .comment-header-section {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          padding: 0;
          
          @media (max-width: 550px) {
            margin-top: -2px;
          }

          .commented-on {
            display: flex;
            padding: 0;
            justify-content: space-between;
            width: 100%;

            .commented-on-text {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #666666;
            }

            @media (max-width: 550px) {
              flex-direction: column;
            }

            .commented-one {
              margin: 0;
              padding: 0;
              margin-bottom: 11px;
              @media (max-width: 450px) {
                margin-bottom: 4px;
              }
              .post-slug {
                color: #365de6;
                text-decoration: underline;
                text-underline-offset: 4px;
              }
            }

            .timestamp {
              display: flex;
              justify-content: flex-end;
              width: 120px;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #767a8a;
              margin-inline: 8px;

              @media (max-width: 450px) {
                justify-content: flex-start;
                margin: 0;
                margin-bottom: 24px;
              }

              span {
                margin-left: 2px;
              }
            }
            .channel-tooltip {
              background: rgba(213, 216, 223, 0.5);
              border-radius: 4px;
              width: fit-content;
              margin-bottom: 24px;
              display: flex;

              @media (max-width: 450px) {
                margin-bottom: 4px;
              }

              .channel-tooltip-name {
                padding: 4px 8px;
                gap: 10px;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #767a8a;
              }
            }
          }
        }
      }
      .comment-container-content {
        background-color: #f2f2f2;
        padding: 12px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: #333333;

        figure{
          margin: 0;
          padding: 0;
      
          img{
            width: 140px;
            height: 140px;
            border-radius: 8px;
            object-fit: cover;
            margin-top: 10px;
          }
        }

        @media (max-width: 550px) {
          width: 110%;
          margin-left: -30px;
        }
      }
    }
  }
}

.user-profile-comments-loader-container {
  width: calc(100% - 6px) !important;
  margin: 0 auto;
}

.comment-container:last-child {
  margin-bottom: 87px;

  @media (max-width: 550px) {
    margin-bottom: 41px;
  }
}
