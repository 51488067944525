@import "../../../assets/styles/components/modal";
@import "../../../assets/styles/components/button";
@import "../../../assets/styles/layout/forms";

.login-wrapper {
  width: 100%;

  -webkit-font-smoothing: subpixel-antialiased !important;
  border: none !important;
  padding-bottom: 20px !important;
  align-items: center;

  &.loading {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    color: #1c1e21;
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    text-align: center;

  }

  p {
    color: #767a8a;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    margin: 0;

  }

  &.popup {
    h1 {

      font-size: 24px;
      font-weight: 900;
    }

    p {

      font-size: 16px;
      letter-spacing: 0;
    }

    form {
      margin-top: 30px;
      max-width: 414px;
      width: 100%;

      input {
        width: 100%;

        &[type="password"] {
          margin-bottom: 10px;
        }
      }

      .link-group {
        justify-content: flex-end;
        margin-bottom: 15px;

        a {
          font-size: 14px;
          color: #0000FF;
        }
      }

      button {
        margin-top: 5px;
        color: #ffffff;
        // background-color: #1E1E1E;
        max-width: 100%;
        font-size: 14px;
        // border: 1px solid #1E1E1E;
        height: 40px;

        &:hover {
          border: 1px solid #1E1E1E;
        }
      }
    }

    .signup_link {
      color: #365de6;
    }
  }

  .modal-footer {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    max-width: 100%;
    color: #767a8a;

    a {
      margin-left: 3px;
      color: #0000FF;
    }
  }

  .error-message {
    height: 32px;
    width: 100%;
    max-width: 340px;
    border: 1px solid #f53f5e;
    border-radius: 5px;
    color: red;
    background-color: #fce1e5;
    margin-top: 20px;
  }

  .gp-login-oauth {
    &-container {
      margin-top: 15px;
      width: 100%;

      &.mail-view {
        margin-top: 0;

        p {
          margin: 10px 0;
        }
      }
    }

    &-action {
      &-default {
        display: block;
        margin: 0 auto;
        border: 1px solid #365de6;
        background-color: #365de6;
        border-radius: 4px;
        height: 50px;
        width: 350px;

        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;

        color: #ffffff;
      }

      &-oauth {
        display: block;
        margin: 0 auto;
        margin-top: 15px;
        border: 1px solid #365de6;
        background-color: #ffffff;
        border-radius: 4px;
        height: 50px;
        width: 350px;

        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        text-align: center;

        color: #365de6;
      }
    }
  }
}