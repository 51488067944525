.admin-link {
  display: none;
}

@media (max-width: 1150px) {
  .admin-link {
    display: none;
  }

  .plus {
    margin: 0 auto;
  }

  .sidebar__header-title {
    display: none;
  }

  .sidebar-is-open .sidebar .sidebar__wrap .sidebar__content .sidebar__content-group-list .sidebar__content-group-list--container {
    width: 70px;

    .sidebar__group-item .sidebar__group-item--info {
      display: none;
    }
  }

  .sidebar .sidebar__wrap .sidebar__content .sidebar__content-group-list .sidebar__content-group-list--container .sidebar__group-item .sidebar__group-item--info {
    display: none;
  }

  #master main#site-content .main-wrap.sidebar-is-open.channel-preview .content-wrap,
  #master main#site-content .main-wrap.channel-preview .content-wrap,
  #master main#site-content .main-wrap.sidebar-is-open .content-wrap {
    //left: 347px;
    //padding: 50px 0 0 0;
    //width: calc(100% - 347px);
    position: relative;
  }

  .public-members {
    padding: 50px 100px 15px 100px;
  }

  #master.res-no-sub-nav main#site-content .main-wrap.sidebar-is-open.channel-preview .content-wrap,
  #master.res-no-sub-nav main#site-content .main-wrap.channel-preview .content-wrap,
  #master.res-no-sub-nav main#site-content .main-wrap.sidebar-is-open .content-wrap {
    padding: 50px 0 0 0 !important;

    &.content-wrap--landing-page {
      padding: 0 !important;
    }
  }

  body {
    #master.res-courses main#site-content .main-wrap.sidebar-is-open.res-course-lecture .content-wrap {
      left: 0;
    }

    #master.res-courses main#site-content .main-wrap.sidebar-is-open .content-wrap {
      padding-left: 0;
      left: 350px;
    }
  }

  .sidebar-is-open {
    .sidebar.new-user {
      width: 70px;

      .sidebar__wrap {
        .sidebar__content {
          .btn-create-group {
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .sidebar {
    .sidebar__wrap {
      .sidebar__header {
        .sidebar__header-title {
          font-size: 9px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .admin-link {
    display: none;
  }

  .plus {
    margin: 0 auto;
  }

  .sidebar__header-title {
    display: none;
  }

  .sidebar {
    left: -360px;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 1);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  .sidebar-is-open .sidebar {
    left: 0;
  }

  #master main#site-content .main-wrap.sidebar-is-open.channel-preview .content-wrap,
  #master main#site-content .main-wrap.channel-preview .content-wrap,
  #master main#site-content .main-wrap.sidebar-is-open .content-wrap {
    width: 100%;
    // padding-top: 50px;
    left: 0;
  }

  #master {
    main#site-content .main-wrap {
      overflow: hidden;

      .content-wrap {
        padding-left: 0 !important;
        left: 0;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
      }

      &.channel-preview {

        &.sidebar-is-open .content-wrap,
        #master main#site-content .main-wrap.channel-preview .content-wrap {
          width: 100%;
        }

        .content-wrap,
        #master main#site-content .main-wrap.channel-preview .content-wrap {
          left: 0;
          width: 100%;
          overflow: hidden;
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }

        .content-wrap {
          padding-left: 0;
        }
      }
    }

    .public-members {
      padding: 5px;
    }

    .public-members-content {
      height: 87vh;
    }

    .public-members-content .list-of-members {}

    height: 74vh;

    &.res-admin-page {
      main#site-content {
        .main-wrap {
          display: block;

          .content-wrap {
            left: -140px;
            width: calc(100% + 160px);
          }
        }
      }

      .sidebar-is-open {
        .settings-wrap {
          .settings-container {
            .settings-content {
              width: calc(100% - 150px);

              &.people-settings {
                width: calc(100% - 145px);
              }
            }
          }
        }
      }

      .settings-wrap {
        .settings-container {
          padding-right: 0;

          .settings-content {
            width: calc(100% - 120px);

            &.people-settings {
              width: calc(100% - 140px);
            }
          }
        }
      }
    }
  }

  span[data-text="true"] {
    font-size: 16px !important;
  }

  .card-share-post-textarea-wrap .DraftEditor-root .public-DraftStyleDefault-ltr {
    font-size: 16px !important;
  }

  #master.res-no-sub-nav.res-create-group-container main#site-content .main-wrap.sidebar-is-open .content-wrap {
    padding-left: 0;
  }

  body #master.res-courses main#site-content .main-wrap.sidebar-is-open.res-course-lecture .content-wrap {
    width: 100%;
  }

  #master.res-admin-page {
    .sidebar-is-open {
      .settings-wrap {
        .settings-container {
          .settings-tab-wrap {
            margin-left: 130px;
            min-width: 120px;

            .settings-tab-header {
              button {
                text-align: left;
              }
            }
          }

          .settings-content {
            margin-right: -145px;
            width: calc(100% + 85px) !important;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .admin-link {
    display: block;
    width: 100%;
    text-align: left;
    height: 15px;
    color: #365de6;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 23px;
    padding: 0 0 15px;
    display: flex;
  }

  #master {
    .public-members {
      .header {
        padding: 0;
        height: auto;
        margin-bottom: 15px;
      }
    }

    .public-members-content {

      .list-of-members,
      .settings-content-body--wrap {
        padding: 9px 15px;
      }
    }

    &.res-admin-page {
      main#site-content {
        .main-wrap {
          .content-wrap {
            left: -145px;
            width: calc(100% + 145px);
          }
        }
      }
    }
  }

  .sidebar .sidebar__wrap .sidebar__content .sidebar__content-group-list .sidebar__content-group-list--container .sidebar__group-item--container .sidebar__scrollarea .infinite-scroll-component {
    .btn-create-group-responsive {
      display: block;
      width: 42px;
      height: 40px;
      margin: 0 0 0 12px;
      border: 1px solid #365de6;
      border-radius: 3px;
    }
  }

  .kolona .plan-selection-wrapper .chose-plan .sub-heading {
    padding: 0 15px;
  }
}

@media (max-width: 550px) {
  .admin-link {
    display: block;
    width: 100%;
    text-align: left;
    height: 15px;
    color: #365de6;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 23px;
    padding: 0 0 15px;
    display: flex;
    width: fit-content;
  }

  #master {
    .public-members-content {
      height: 89vh;
    }

    .search.search-page-wrap {
      width: 100%;
    }

    .kolona .plan-selection-head,
    .sub-header-wrapper,
    .kolona .plan-selection-wrapper,
    .kolona .plan-selection-wrapper .chose-plan {
      width: 100%;
    }

    &.res-admin-page {
      main#site-content {
        .main-wrap {
          .content-wrap {
            left: -130px;
            width: calc(100% + 150px);
          }
        }
      }

      .settings-wrap {
        .settings-container {
          .settings-content {
            width: calc(100% - 145px);
          }
        }
      }
    }
  }

  .public-members-content .settings-content-body--wrap .settings-content-body--container .member-search input {
    width: calc(100% - 21px) !important;
    font-size: 16px !important;
  }

  #master.res-admin-page {
    .sidebar-is-open {
      .settings-wrap {
        .settings-container {
          .settings-tab-wrap {
            margin-left: 130px;

            .settings-tab-header {
              button {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 410px) {
  #master .public-members-content .list-of-members .public-member {
    margin: 5px auto;
  }

  .admin-link {
    display: block;
    width: 100%;
    text-align: left;
    height: 15px;
    color: #365de6;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 23px;
    padding: 0 0 15px;
    display: flex;
    width: fit-content;
  }
}