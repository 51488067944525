.tippy-box[data-theme~="light-border"] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  min-width: fit-content;
  border: 1px solid #d5d8df;
  border-radius: 3px;

  .tippy-content {
    padding: 0;
  }
}

.tippy-invisible {
  visibility: hidden;
}

.hide-tippy-arrow {
  .tippy-arrow {
    display: none;
  }
}
