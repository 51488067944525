.invite-wrap-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .send-button {
    box-sizing: border-box;
    height: 43px;
    width: 145px;
    border: 1px solid #365de6;
    border-radius: 4px;
    background-color: #365de6;
    color: #ffffff;
    transition: 0.5s;
    margin-top: 1rem;
  }

  .send-button:hover {
    background-color: #ffffff;
    color: #365de6;
  }

  .heading {
    height: 22px;
    width: 351px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
  }

  .sub-heading {
    color: #33353b;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .input-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .input-fields-labels {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .fields {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      padding-right: 1rem;

      &:hover {
        overflow-y: overlay;
      }

      .add-field {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add-button {
          color: #365de6;
          font-size: 12px;
          line-height: 19px;
          margin-top: 1px;
        }
      }

      .input-div {
        display: flex;
        width: 100%;
        height: 100%;

        .remove-button {
          margin-left: 8px;
          font-size: 1.3rem;
        }
        .invite-input {
          width: 94%;
          border: 1px solid #d5d8df;
          border-radius: 4px;
          background-color: #ffffff;
          font-size: 14px;
          line-height: 16px;
          padding: 13px 7px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }

    p {
      margin: 0;
      margin-bottom: -5px;
      margin-top: 14px;
      color: #33353b;
      font-size: 13px;
      line-height: 24px;
    }
  }

  .invite-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .invite-link-lables {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
        margin-bottom: 2px;
        color: #33353b;
        font-size: 13px;
        line-height: 24px;
      }

      .copy-link-btn {
        color: #365de6;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        margin-right: 1.85rem;
      }
    }

    .invite-input {
      width: 94%;
      border: 1px solid #d5d8df;
      border-radius: 4px;
      background-color: #ffffff;
      color: #365de6;
      font-size: 14px;
      line-height: 16px;
      padding: 13px 7px;
    }
  }
}
