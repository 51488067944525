.app-info-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #add8e6;
    width: 100%;
    position: fixed;
    padding: 5px 0px;
    z-index: 99;
    top: 50px;
    transition: 0.5s;

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    @media (max-width: 550px) {
      padding: 5px 8px;
    }

    span {
      min-width: fit-content;
    }

    img {
      margin-right: 5px;
    }

    .upgrade-now {
      text-decoration: underline;
      color: #365de6;
    }

    a {
      cursor: pointer;
    }
  }

  .gp-app-info-banner-remover-container {
    display: flex;
    align-items: center;
    justify-content: center;
  
    position: absolute;
    right: 30px;
    cursor: pointer;
  
    svg {
      path {
        fill: #000000;
      }
    }
  }