.onboarding-steps-overlay {
    position: relative;
    left: 0;
    top: 0;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
        margin: 0 auto;
    }

    .onboarding-step {
        width: 1203px;
        height: 90%;
        border-radius: 4px;
        border: 1px solid #d5d8df;
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 54px;
        justify-content: space-between;
        overflow: auto;
        overflow-x: hidden;

        @media (max-width: 768px) {
            width: calc(100vw - 10px);
            margin: 0 auto;
            padding-top: 27px;
        }

        &-header {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            flex-direction: column;

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: center;
            }

            .steps-indicator {
                display: flex;
                margin-top: 12px;
                .indicator {
                    width: 20px;
                    height: 3px;
                    background: #d5d8df;
                    border-radius: 10px;
                    margin-right: 4px;
                }
            }
        }

        &-body {
            display: flex;
            width: 1203px;
            justify-content: center;

            @media (max-width: 768px) {
                width: calc(100vw - 10px);
                margin: 0 auto;
                padding: 8px;
            }
        }
        &-button-footer {
            width: 610px;
            display: flex;
            justify-content: flex-end;
            margin: 28px 0;
            align-items: center;

            @media (max-width: 768px) {
                width: 100%;
                margin: 0 auto;
                justify-content: center;
                align-items: center;
                margin-bottom: 36px;
            }

            button {
                padding: 12px 24px;
                border-radius: 4px;
                color: #fff;
                background: #365de6;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: center;
                margin-left: 8px;

                @media (max-width: 768px) {
                    width: 100%;
                    margin: 0 5px;
                    margin-top: 22px;
                }
            }

            .previous {
                background: transparent;
                color: #365de6;
                border: 1px solid #365de6;
            }
        }

        .custom-field-footer {
            width: 986.02px;
            @media (max-width: 768px) {
                width: calc(100vw - 10px);
            }
        }

        .profile-field-btn{
            margin-top: 8px;
        }
    }

    .full-default-screen{
        align-items: flex-start;
        height: 100%;

        @media (max-width: 1600px) {
            height: auto;
        }
    }
}
