.channel-selector {
    &-container {
        width: 100%;
        height: 136px;
        border-radius: 4px;
    }

    &-empty {
        &-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            margin: 0;
            padding: 0;
             
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
        }
    }

    &-search {
        &-container {
            width: 100%;
            height: 36px;
            position: relative;
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #D5D8DF;
        }

        &-input {
            width: calc(100% - 30px);
            height: 100%;
            border-radius: 4px 4px 0 0;
            border: none;
            padding: 10px;
        }

        &-icon {
            position: absolute;
            right: 10px;
            top: 5px;
            cursor: pointer;
        }
    }

    &-channels {
        &-container {
            width: 100%;
            height: 100%;
            border-radius: 0 0 4px 4px;
            padding: 5px 0;
            padding-left: 10px;
        }
        &-scrollarea {
            width: 100% !important;
            height: 100% !important;
            border-radius: 0 0 4px 4px !important;
            background-color: #ffffff !important;
        }
    }

    &-channel {
        &-container {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding: 5px 0;
        }

        &-name {
            margin: 0;
            padding: 0;
             
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #333333;
        }

        &-emoji {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3px;
        }
    }
}