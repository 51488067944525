.post-attachment {
    &-upload-attachment {
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 20px 10px;
        }

        &-details {
            margin-left: 10px;
            &-title {
                margin: 0;
                padding: 0;

                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #333333;
                max-width: 500px;
                word-wrap: break-word;
                margin-bottom: 5px;

                @media (max-width: 550px) {
                    max-width: 270px;
                }
            }

            &-size {
                margin: 0;
                padding: 0;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #767a8a;
            }

            &-container {
                display: flex;
                align-items: center;
            }
        }

        &-action {
            &-container {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    cursor: pointer;
                }
            }
        }

        &-upload-progress {
            &-container {
                width: 40px;
                height: 40px;
            }

            &-spinner {
                &-percentage {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    color: #333333;
                }
            }
        }
    }

    &-url-attachment {
        &-container {
            width: 100%;
            padding: 10px 10px;
        }

        &-wrapper {
            width: 100%;
        }

        &-label {
            margin: 0;
            padding: 0;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #333333;
            margin-bottom: 5px;
        }

        &-input {
            border: none;
            width: 90%;
            height: 90%;
            padding-left: 5px;

            @media (max-width: 550px) {
                width: 80%;
            }

            &-container {
                width: 100%;
                display: flex;
                align-items: center;
                border: 1px solid #365de6;
                border-radius: 4px;
                height: 42px;
            }
        }

        &-action {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;

            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #365de6;

            @media (max-width: 550px) {
                width: 20%;
            }
        }
    }
}
.attachment-wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    margin-left: 10px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 550px) {
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .image-attachment-upload-attachment-container {
        display: flex;
        margin-right: 16px;
        border-radius: 14px;
        border: 2px solid #dfdfdf;
        width: 130px;
        height: 120px;
        flex-direction: column;
        position: relative;

        @media (max-width: 550px) {
            width: 80px;
            height: 70px;

            &:nth-child(5) {
                margin-left: 140px;
            }
            
        }

        &:last-child {
            margin-right: 40px;
        }

        .loading-image-text {
            color: #bab9b9;
            font-size: 14px;
            text-align: center;
            width: 130px;
            height: 120px;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .image-attachment-upload-attachment-details-image {
            width: 128px;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;

            @media (max-width: 550px) {
                width: 76px;
                object-fit: cover;
                height: 100%;
            }
        }

        .image-attachment-upload-attachment-details-image:hover + .image-attachment-upload-attachment-details-footer,
        .image-attachment-upload-attachment-details-footer:hover {
            display: flex;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            transform: translate(-50%, -50%);
            position: absolute;
            width: 45px;
            height: 45px;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            cursor: pointer;

            @media (max-width: 550px) {
                display: flex;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                right: auto;
                bottom: auto;
                transform: translate(-50%, -50%);
                position: absolute;
                width: 18px;
                height: 18px;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                cursor: pointer;
            }
        }
        .image-attachment-upload-attachment-details-footer {
            display: none;
        }
    }

    .add-image-attachment {
        display: flex;
        border-radius: 14px;
        border: 2px dotted #dfdfdf;
        padding: 0 40px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @media (max-width: 550px) {
            padding: 0 26px;
        }

        .add-icon {
            color: #bab9b9;
            width: 44px;
            height: 44px;
            cursor: pointer;

            @media (max-width: 550px) {
                width: 20px;
                height: 20px;
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }

            &:hover {
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }
        }
    }

    .add-image-attachment-small {
        display: flex;
        border-radius: 14px;
        border: 2px dotted #dfdfdf;
        padding: 0 140px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
}

.file-attachment {
    display: flex;
    padding-left: 12px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;

    &::-webkit-scrollbar { 
        display: none;
    }

    &-wrapper {
        display: flex;
        width: 400px;
        margin: 8px 12px;
        margin-left: 0;
        border-radius: 8px;
        padding: 0 12px;
        justify-content: space-between;
        border: 2px solid #dfdfdf;

        .file-attachment-details {
            display: flex;
            align-items: center;
            padding: 8px 0;

            .file-attachment-details-icon {
                width: 20px;
                color: #888;
                height: 20px;
                margin-right: 12px;
            }

            &-body {
                display: flex;
                flex-direction: column;
                margin-right: 12px;
                justify-content: space-between;

                &-title {
                    max-width: 150px;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: relative;

                    .title-text {
                        white-space: nowrap; /* prevent the title from wrapping */
                        text-overflow: ellipsis; /* add an ellipsis at the end of the text if it overflows */
                        overflow: hidden; /* hide any overflow */
                    }
                }
            }
        }
    }


    .add-file-attachment {
        display: flex;
        border-radius: 14px;
        border: 2px dotted #dfdfdf;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        padding: 12px 100px;

        .add-file-icon {
            color: #bab9b9;
            width: 34px;
            height: 34px;
            cursor: pointer;

            @media (max-width: 550px) {
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }

            &:hover {
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }
        }
    }
}
