.header-member-detail {
    padding: 20px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d5d8df;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    z-index: 2;

    @media (max-width: 768px) {
        width: calc(100vw - 16px);
        margin: 0 auto;
    }

    &-head {
        display: flex;
        flex: 1;
        width: 100%;

        .profile-pic-container {
            .profile-image {
                display: flex;
                align-self: baseline;
                justify-content: center;
                align-items: center;
                height: 58px;
                width: 58px;
                min-height: 58px;
                min-width: 58px;
                overflow: hidden;
                color: white;
                object-fit: cover;

                border-radius: 100%;
                padding: 1px;

                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }

            img {
                background-color: #767a8a;
            }
        }

        .name-actions {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .name-details {
                // width: 245px;
                margin: 0 12px;

                .name {
                    display: flex;
                    h1 {
                        padding: 0;
                        margin: 0;
                        color: #333;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-right: 4px;
                        z-index: 2;
                    }
                }

                .date-joined {
                    margin: 4px 0;
                    h4 {
                        padding: 0;
                        margin: 0;
                        color: #767a8a;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 150% */
                        z-index: 2;
                    }
                }

                .role {
                    display: flex;
                    padding: 4px 12px;
                    width: fit-content;
                    justify-content: center;
                    align-items: flex-start;
                    border-radius: 4px;
                    background: #fff6e9;

                    span {
                        padding: 0;
                        margin: 0;
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                    }
                }
            }

            .non-name-details{
                display: flex;
                align-items: center;
            }

            .member-actions {
                .dropdown {
                    width: max-content !important;
                    text-align: left;
                }
            }
        }
    }

    .middle {
        margin: 0;
        margin-top: 20px;

        .first {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            h1 {
                padding: 0;
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 150% */
                margin: 0 8px;
                cursor: pointer;
            }

            .bell-icon {
                margin-right: 10px;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #9f9f9f;
                    margin-top: 4px;
                }
            }

            svg {
                color: #9f9f9f;
            }

            .copy-icon {
                display: flex;
                svg {
                    width: 18px !important;
                    height: 18px !important;
                }
                cursor: pointer;
            }
        }

        .second {
            display: flex;

            h4 {
                padding: 0;
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 150% */
                margin: 0 8px;
            }

            svg {
                color: #9f9f9f;
            }
        }
    }

    .footer {
        display: flex;
        color: #365de6;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;
        h4 {
            padding: 0;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px;
        }
    }

    .enrollment-status{
        display: flex;
        margin-top: 10px;
        align-items: center;

        svg{
            color: #9f9f9f;
        }

        h4{
            margin: 0 8px;
            padding: 0;
            padding: 0;
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        span{
            margin: 0;
            padding: 0;
            padding: 2px 8px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            width: fit-content;
        }

        &-days-left{
            padding-left: 0 !important;
        }
    }
}
