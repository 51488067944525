@import "../../../assets/styles/abstracts/_variables";

form {
  label {
    font-size: 14px;
    color: #33353b;

    .required {
      color: $warning;
    }
  }

  .error-label {
    color: #f53254;
    font-size: 13px;
  }

  .form-group {
    width: 100%;

    textarea {
      min-height: 82px;
      padding: 15px !important;
    }

    input,
    textarea,
    select {
      height: 42px;
      width: 100%;
      border: 1px solid #d5d8df;
      border-radius: 4px;
      background-color: #ffffff;
      margin-bottom: 15px;
      font-size: 13px;
      padding: 0 15px;
      display: block;
      outline: 0;
      color: #000000;

      &:focus {
        border-color: #365de6;
      }
    }

    select {
      padding: 0 10px;
    }

    &.error {

      input,
      textarea {
        border-color: #f53254;
      }
    }
  }

  .link-group {
    width: 100%;
  }

  .form-radio-select {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    input {
      width: 17px;
      height: 17px;
    }

    p {
      margin: 0;
    }

    div {
      margin-top: 7px;
      height: 17px;
      display: flex;
      flex-direction: row;
    }
  }
}

input,
button {
  &:focus {
    outline: none;
  }
}

input:not([type=radio]):not([type=checkbox]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.upload-box-active {
  display: flex !important;
}

.upload-box-inactive {
  display: none;
}

.drag-drop-item {
  width: 100%;
  box-sizing: border-box;
  height: 189px;
  max-width: 782px;
  border: 1px solid #D4D7DD;
  border-radius: 4px;
  background-color: #EFF0F2;
  display: flex;
  //display: none;
  justify-content: center;
  align-items: center;
}

.library-content-drag-drop-item {
  width: 100%;
  box-sizing: border-box;
  height: 189px;
  border: 1px solid #D4D7DD;
  border-radius: 4px;
  background-color: #EFF0F2;
  //display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}