.ReactModal__Body--open {
    .people-details-popup {
        margin: 0;
        padding: 24px 36px;
        border-radius: 4px;
        border: 1px solid #d5d8df !important;
        background: #ffffff;
        flex: 1;
        box-sizing: border-box;
        margin: 50px auto;
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate3d(-50%, -50%, 0);
        display: flex;
        justify-content: center;
        width: 94%;
        height: 90%;
        overflow: hidden !important;
        padding-top: 10px;
        flex-direction: column;

        @media (max-width: 550px) {
            padding: 4px;
            padding: 0 2px;
            width: calc(100vw - 10px);
        }

        &::backdrop {
            background: rgba(0, 0, 0, 0.55);
        }

        &-body {
            height: 100%;
            overflow-x: hidden;
            flex: 1;
            padding: 0 2px;
            padding-bottom: 56px;
            overflow-y: auto;

            &:hover {
                overflow-y: auto;
            }

            &::-webkit-scrollbar {
                width: 6px;
                transition: width 0.3s;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #aaa;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
                border-radius: 10px;
            }
        }

        &-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 10px;
            border-bottom: 1px solid #d5d8df;
            padding-bottom: 8px;

            @media (max-width: 550px) {
                padding: 8px 0;
            }

            .icon {
                display: flex;
                cursor: pointer;
                padding-bottom: 3px;

                @media (max-width: 550px) {
                }
    
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
