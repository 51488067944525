.comm-intro {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    &-title {
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 22.5px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        margin-bottom: 16px;
        max-width: 620px;

        @media (max-width: 768px) {
            width: calc(100vw - 10px);
        }
    }

    &-desc {
        margin-bottom: 40px;
        width: 620px;

        @media (max-width: 768px) {
            max-width: calc(100vw - 10px);
            margin: 0 auto;
            width: 100%;
            margin-bottom: 40px;
        }

        .draft-content-wrapper .public-DraftStyleDefault-block {
            line-height: normal !important;
        }

        .public-DraftStyleDefault-ul{
            margin-left: 0px !important;
        }

        .public-DraftStyleDefault-ol{
            margin-left: 6px !important;

            div {
                padding-top: 2px;
            }
        }
    }

    .intro-member-post {
        display: flex;
        flex-direction: column;
        width: 618px;

        @media (max-width: 768px) {
            max-width: calc(100vw - 10px);
            margin: 0 auto;
            width: 100%;
        }

        .post-GIF-container{
            top: 16px;
        }

        .new-post-editor-container {
            border-radius: 1%;
            border: 1px solid #d5d8df !important;
        }

        .postable-editor-footer-container {
            border-top: 1px solid #d5d8df !important;
            width: 100%;
        }

        .postable-draft-container {
            border-radius: 4px;
            margin-bottom: 20px;

            .public-DraftStyleDefault-block {
                line-height: unset !important;
            }

            .t16lpgj {
                display: flex;
                align-items: center;
                border: none;
                border-bottom: 1px solid #d5d8df !important;

                .post-draft-image-button-container,
                .post-draft-gif-button-container {
                    margin-top: 22px;
                }
            }
        }

        &-container {
            display: flex;
            align-items: center;
            margin: 10px;

            &-profile {
                display: flex;
                align-self: baseline;
                justify-content: center;
                align-items: center;
                margin-right: 13px !important;
                height: 35px;
                width: 35px;
                min-height: 35px;
                min-width: 35px;
                border-radius: 50%;
                overflow: hidden;
                color: white;
                margin-left: 0px;
                position: relative;
                top: 0px;
                object-fit: cover;

                border-radius: 100%;
                padding: 1px;

                text-align: center;
                font-size: 16px;
                font-weight: bold;
            }

            img {
                background-color: #767a8a;
            }

            &-name {
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
        }
    }
}
