.new-message-menu {
    input::placeholder {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
         
    }

    .search-members-dropdown {
        width: 200px !important;
        display: block !important;
        top: 60px;
        .search-section {
            padding: 10px;
            input {
                width: 100% !important;
            }
            .search-icon {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 20px;
                top: 17px;
            }
        }
        .loading-results{
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #33353B;
        }
        ul {
            width: 100%;
            margin: 0;
            padding: 0;
            li {
                cursor: pointer;
                width: 100%;
                text-align: left;
                justify-content: flex-start !important;
                flex-direction: row !important;
                 
                .user-component {
                    padding: 7px 15px;
                    .info .name {
                        color: #33353B;
                         
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 18px;
                        font-weight: normal !important;
                    }
                    .profile-image {
                        width: 25px !important;
                        height: 25px !important;
                        min-height: 25px !important;
                        min-width: 25px !important;
                        font-size: 13px;
                    }
                }
                
            }
        }
    }
}
