.user-profile-details-body-wrapper-two {
  border: 1px solid #d5d8df;
  border-radius: 4px;
  padding: 24px 25px;
  background-color: #ffffff;
  width: calc(100% - 10px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;

  @media (max-width: 768px) {
    padding: 16px;
    padding-bottom: 50px;
  }

  .user-profile-details-body-titles {
    font-weight: 600;
    font-size: 14px;
    color: #1e1e1e;
    margin: 0;
    margin-bottom: 12px;
  }

  .user-profile-details-body-values {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    text-align: justify;
    padding: 0;
  }
  .bio-details {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #666666;
    text-align: left;
    letter-spacing: normal;
  }

  &-icons {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .user-profile-detail-icon-header {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      margin-bottom: 18px;
    }

    .user-profile-detail-icon-body {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      color: #767a8a;

      .user-profile-detail-icon-body-link {
        margin-left: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #666666;
      }
    }
  }
}
.user-profile-body-middle {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 5px;

  .user-profile-body-middle-profile-info-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-left: 20px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .user-profile-body-middle-button-edit {
    display: flex;
    align-items: center;
    margin-left: 26px;

    .user-profile-body-middle-text-edit {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #365de6;
      margin-left: 16px;
    }
  }
}

.user-profile-action-delete {
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  &-btn {
    color: #1e1e1e;
    display: flex;
    align-items: center;
  }
  &-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin-left: 15px;
  }
}

.user-delete-action-on-custom {
  bottom: 0;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 38px;
}

.custom-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  .custom-header-div {
    display: flex;
    align-items: center;

    &-edit-mode {
      display: flex;
      align-items: center;
      margin-left: 26px;

      span{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #365de6;
        margin-left: 16px;
      }
    }
  }

  .custom-fields-edit-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    cursor: pointer;
    margin: 24px 0;
  }
}

.questions-loader {
  display: flex;
  background-color: #ffffff;
  width: 688px;
  margin: 40px 0;
  flex-direction: column;
  padding-top: 40px;

  @media (max-width: 768px) {
    max-width: 480px;
    margin: 0 auto;
    margin: 40px 0;
  }
}
