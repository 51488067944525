.new-signup-accordion {
  text-align: center;
  span {
    color: #a7a7a7;
    font-size: 14px;
    display: inline-block;
    &.active {
      color: #365de6;
    }
  }
  svg {
    margin: 0 7.5px;
  }
}
button {
  &.btn-new-signup {
    background-color: #365de6;
    color: #fff;
    display: block;
    padding: 15px;
    width: 100%;
    font-size: 15px;
    border-radius: 5px;
  }
}
#master.self-signup {
  main#site-content .main-wrap .content-wrap {
    padding: 0;
  }
}
.new-verification-content {
  border: solid 1px #d5d8df;
  border-radius: 3px;
  max-width: 620px;
  width: 100%;
  margin: 50px auto;
  overflow: hidden;
  background-color: #fff;
  padding: 30px 40px 40px;
  text-align: center;
  h2 {
    font-size: 24px;
    color: #000000;
  }
  p {
    font-size: 14px;
    color: #767a8a;
  }
  .verification-code {
    max-width: 331px;
    margin: 15px auto 0;
    .error {
      display: block;
      color: red;
      margin: -20px 0 20px;
      text-align: left;
    }
    p {
      float: left;
      width: 100%;
      span {
        font-size: 13px;
        float: left;
        color: #33353b;
      }
      button {
        font-size: 12px;
        color: #365de6;
        float: right;
      }
    }
    .verification-code-input {
      margin-bottom: 20px;
      input {
        border: solid 1px #d5d8df;
        border-radius: 4px;
        width: 51px;
        height: 61px;
        text-align: center;
        font-size: 18px;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}

.gp-verify-change-email {
  color: #365de6;
  margin-top: 15px;
}
