html {
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

.ct--bottom {
  display: none !important; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 1 auto; }

body {
  font-family: -apple-system, Arial !important;
  font-size: 14px !important;
  line-height: 1.43 !important;
  color: #484848 !important;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F4F7 !important;
  overflow-x: hidden;
  overflow: overlay; }

@-moz-document url-prefix() {
  body {
    overflow: auto; } }
  body.ReactModal__Body--open {
    overflow: hidden; }
  body.overlay-body {
    overflow: overlay; }

@-moz-document url-prefix() {
  body.overlay-body {
    overflow: auto; } }
  body.hide-scroll {
    overflow: hidden; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    body {
      font-family: -apple-system, Arial !important;
      font-size: 14px !important;
      line-height: 1.43 !important;
      color: #484848 !important;
      margin: 0 !important; } } }

.Toastify__toast-container {
  width: fit-content !important;
  z-index: 2147483647 !important; }

.Toastify__toast {
  width: fit-content; }

.Toastify__toast-body {
  padding-right: 30px !important;
  min-width: 220px !important; }

/* force toast to stay on the right in mobile breakpoints */
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    right: 0 !important;
    left: auto !important;
    margin-right: 10px !important; } }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

input,
textarea,
select,
button,
a {
  font-family: inherit;
  font-size: 100%; }

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 25px;
  margin-bottom: 10px; }

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0; }

[tabindex="-1"]:focus {
  outline: 0; }

button {
  padding: 0;
  background-color: transparent;
  border: 0; }

.border-bottom {
  border-width: 1px;
  border-style: solid; }

#app {
  height: 100%; }

.flex {
  display: flex; }
  .flex.flex-direction-column {
    flex-direction: column; }
  .flex.flex-direction-row {
    flex-direction: row; }
  .flex.flex-align-center {
    align-items: center; }
  .flex.flex-justify-space-between {
    justify-content: space-between; }
  .flex.flex-justify-space-evenly {
    justify-content: space-evenly; }
  .flex.flex-justify-flex-start {
    justify-content: flex-start; }
  .flex.flex-justify-flex-end {
    justify-content: flex-end; }
  .flex.flex-justify-center {
    justify-content: center; }

.flex-w-wrap {
  flex-wrap: wrap; }
