.dropdown-menu {
  position: relative;
  .dropdown {
    margin: 0;
    padding: 0;
    position: absolute;
    border: 1px solid #d5d8df;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: 32px;
    width: 100px;
    display: flex;
    justify-content: center;
    right: 0;
    z-index: 9;

    li {
      display: flex;
      flex-direction: column;
      padding: 0px 0 !important;
      font-size: 14px;
      color: #33353b;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &:before {
      content: "";
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ccc
        rgba(255, 255, 255, 0);
      right: 8px;
      margin-left: -11px;
      border-width: 5px;
      border-style: solid;
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
        rgba(255, 255, 255, 1) rgba(255, 255, 255, 0);
      right: 9px;
      margin-left: -10px;
      border-width: 4px;
      border-style: solid;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
  }
}

.dropdown-menu-header {
  position: relative;
  .dropdown-header {
    margin: 0;
    padding: 0;
    position: absolute;
    border: 1px solid #d5d8df;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: 32px;
    width: 100px;
    display: flex;
    justify-content: center;
    right: 0;
    z-index: 9;

    li {
      display: flex;
      flex-direction: column;
      padding: 8px 0 !important;
      font-size: 14px;
      color: #33353b;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &:before {
      content: "";
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #d5d8df
        rgba(255, 255, 255, 0);
      right: 8px;
      margin-left: -11px;
      border-width: 5px;
      border-style: solid;
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
        rgba(255, 255, 255, 1) rgba(255, 255, 255, 0);
      right: 9px;
      margin-left: -10px;
      border-width: 4px;
      border-style: solid;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
  }

  .dropdown-header-notifications {
    margin: 0;
    padding: 0;
    position: absolute;
    border: 1px solid #d5d8df;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: 30px;
    width: 144px;
    display: flex;
    justify-content: center;
    right: -11px;
    z-index: 9;

    li {
      display: flex;
      flex-direction: column;
      padding: 8px 0 !important;
      font-size: 14px;
      color: #33353b;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &:before {
      content: "";
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #d5d8df
        rgba(255, 255, 255, 0);
      right: 8px;
      margin-left: -11px;
      border-width: 5px;
      border-style: solid;
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
        rgba(255, 255, 255, 1) rgba(255, 255, 255, 0);
      right: 9px;
      margin-left: -10px;
      border-width: 4px;
      border-style: solid;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
  }
}

.dropdown-menu-about {
  position: relative;
  .dropdown-about {
    margin: 0;
    padding: 0;
    position: absolute;
    border: 1px solid #d5d8df;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: 32px;
    width: 150px;
    height: auto;
    display: flex;
    justify-content: center;
    right: 0;
    z-index: 9;

    li {
      display: flex;
      flex-direction: column;
      padding: 0px 0 !important;
      font-size: 14px;
      color: #33353b;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    &:before {
      content: "";
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #d5d8df
        rgba(255, 255, 255, 0);
      right: 8px;
      margin-left: -11px;
      border-width: 5px;
      border-style: solid;
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0)
        rgba(255, 255, 255, 1) rgba(255, 255, 255, 0);
      right: 9px;
      margin-left: -10px;
      border-width: 4px;
      border-style: solid;
      content: " ";
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
  }
}

.click-dropdown {
  box-sizing: border-box;
  border: 1px solid #D5D8DF;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  padding: 15px;
  position: absolute;
  top: calc(100% + 15px);
  right: -4px;
  z-index: 1;
  &:before {
    content: "";
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ccc rgba(255, 255, 255, 0);
    right: 6px;
    margin-left: -11px;
    border-width: 8px;
    border-style: solid;
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    bottom: 100%;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) white rgba(255, 255, 255, 0);
    right: 7px;
    margin-left: -10px;
    border-width: 7px;
    border-style: solid;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    bottom: 100%;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      color: #33353B;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 25px;
      min-width: 100px;
      cursor: pointer;
    }
  }
}