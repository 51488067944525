@import "../../../assets/styles/components/card";
@import "../../../assets/styles/components/button";
@import "../../../assets/styles/abstracts/variables";

.authentication-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  .card--auth {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 571px;
    width: 100%;
    border-radius: 4px;
    padding: 2rem 1rem;
    background-color: #ffffff;
    flex-direction: column;
    border: 1px solid #D5D8DF;

    .auth-group-info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 2rem;

      .group-profile-image {
        width: 54px;
        height: 54px;
      }

      .group-name {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #1E1E1E;
      }
    }
  }

  .powered-by-groupapp-a{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;


    .powered-by-groupapp {
      padding: 0;
      margin: 0;
      margin-right: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #1e1e1e;
  
      &:hover {
        color: $primary-color
      }
    }
  }

}