@media (max-width: 550px) {
  #site-content {
    min-height: calc(100vh - 100px) !important;

    .main-wrap {
      .content-wrap {
        min-height: calc(100vh - 100px) !important;
      }
    }
  }

  .authentication-container {
    .card--auth {
      box-shadow: none;
      border: 1px solid #D5D8DF;
    }
  }
}