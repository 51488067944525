
.react-tooltip-lite {
    border-radius: 4px;

    &-arrow {
        display: none;
    }

    &--wrapper {
        &:not(:first-child) {
            margin-left: 15px;
        }
    }
}