.collections-delete-confirmation {
    &-container {
        width: 100%;
        height: 100%;
        padding: 25px;
        position: relative;
    }

    &-x {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
    }

    &-trash {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FDE6E6;
        border-radius: 100%;
    }

    &-title {
        margin: 0;
        margin-top: 20px;
        padding: 0;
         
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 25px;
        color: #333333;
    }

    &-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }

    &-text {
        margin: 0;
        padding: 0;
         
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        text-align: center;
        margin-top: 20px;

        span {
            font-weight: bold;
        }

        // &:first-of-type {
        //     margin-top: 20px;
        // }
    }

    &-actions {
        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 30px;
            column-gap: 2rem;
        }

        &-cancel {
            width: 108px;
            height: 34px;
            border: 1px solid #666666;
            background-color: #ffffff;
            transition: .5s;
            border-radius: 4px;

             
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #666666;

            &:hover {
                color: #ffffff;
                background-color: #666666;
            }
        }

        &-delete {
            width: 108px;
            height: 34px;
            border: 1px solid #EB5757;
            background-color: #EB5757;
            border-radius: 4px;
            transition: .5s;
            
             
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #FFFFFF;

            &:hover {
                color: #EB5757;
                background-color: #ffffff;
            }
        }
    }
}