.profileFieldsSection {
    display: flex;
    flex-direction: column;
    width: 620px;

    @media (max-width: 550px) {
        width: calc(100vw - 32px);
        margin: 0 auto;
    }

    label {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #333;
    }

    input {
        margin-top: 12px;
        margin-bottom: 24px;
        border: 1px solid #d5d8df;
        padding: 13px 16px;
        border-radius: 4px;
        width: 100%;
        background-color: #f8f8f8;
        @media (max-width: 550px) {
            width: calc(100vw - 32px);
            margin: 0 auto;
            margin-top: 12px;
            margin-bottom: 24px;
        }
    }

    .customSelect {
        margin-top: 12px;
        margin-bottom: 24px;
        position: relative;
    }

    select {
        appearance: none;
        /*  safari  */
        -webkit-appearance: none;
        /*  other styles for aesthetics */
        width: 100%;
        font-size: 14px;
        color: #666666;
        padding: 0.675em 6em 0.675em 1em;
        border: 1px solid #caced1;
        border-radius: 0.25rem;
        cursor: pointer;
        background-color: #f8f8f8;
    }

    .customSelect::before,
    .customSelect::after {
        --size: 0.3rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }

    .customSelect::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    }

    .customSelect::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }

    textarea {
        resize: none;
        display: block;
        height: 134px;
        color: #666666;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background: #f8f8f8;
        border: 1px solid #d5d8df;
        border-radius: 4px;
        margin-top: 12px;
        padding: 13px 16px;
    }
}