.stop-scrolling {
    height: 100%;
    overflow: hidden !important;
}

.ReactModal__Body--open {
    overflow: hidden !important;
}
.main-wrap {
    max-width: 1312px;
    margin: 0 auto;
    
    &.main-wrap-fluid {
        max-width: unset;
    }

    
    // &#entire-width {
    //     max-width: unset;
    // }

    .content-wrap {
        // padding-top: 50px;
        transition: none !important;
        &--channel-view {
            padding-right: 0;
            justify-content: space-between;
        }

        &--search-view {
            padding-top: 40px !important;
        }

        &--single-post {
            padding-left: 0 !important;
        }

        &--landing-page {
            padding-top: 0 !important;
        }
    }
}