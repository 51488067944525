@import "../../assets/styles/abstracts/_variables";
@import "../../assets/styles/components/dropdown";

button{
  // fixed a height issue of the 3 icons
  border: 2px solid transparent;
}

header.g-header {
  height: 50px;
  width: 100% !important;
  position: fixed;
  top: 0 !important;
  z-index: 100;
  background-color: $white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-bottom: 1px solid #ccc;

  &.js-modal-active {
    z-index: 1;
  }

  .header-action {
    width: 70px;
    margin-left: -20px;
    display: none;

    button {
      outline: none;
      margin: auto;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      span {
        width: 15px;
        height: 1px;
        background-color: #666;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .header-wrap {
    height: 100%;
    padding: 0 20px;
    width: 100%;
    max-width: 1312px;
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 10px;

    @media (max-width: 1400px) {
      padding-right: 30px;
    }

    &.onboarding {
      max-width: 1040px;
    }

    &.no-auth {
      justify-content: space-between;

      .header-logo {
        margin: 0;
        margin-top: 8px;
      }
      .header-logo-icon {
        height: 40px;
        width: 70px;
      }
    }

    .header-logo {
      cursor: pointer;
      margin-top: 8px;
      height: 100%;

      &.disabled {
        cursor: none;
        pointer-events: none;
      }

      h1 {
        height: 45px;
        width: 29px;
        color: $white;

        font-size: 38px;
        font-weight: bold;
        line-height: 45px;
        text-align: center;
        margin-bottom: 0;
      }
      .header-logo-icon {
        height: 40px;
        width: 70px;
      }
    }

    .header-search {
      margin-left: 50px;

      .close-groupapp-search {
        display: none;
      }

      @media (max-width: 550px) {
        margin-left: auto;
        margin-right: 120px;

        .icon-groupapp-search {
          svg {
            transform: unset;
          }
        }

        &.mobile-open {
          position: fixed;
          width: 100vw;
          height: 50px;
          margin-left: -15px;
          z-index: 999;

          input {
            display: block;
            width: 100%;
            height: 100%;
            margin-left: 0;
            padding-left: 30px;
          }

          svg {
            transform: unset;
          }

          .close-groupapp-search {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            left: 5px;
            transform: scale(0.8);
          }

          .icon-groupapp-search {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 15px;
            right: 5px;
          }
        }
      }

      input {
        margin-left: -20px;
        height: 34px;
        width: 400px;
        color: #969696;

        font-size: 12px;
        line-height: 19px;
        padding: 8px 30px 8px 15px;
        border: 1px solid #eff0f2;
        border-radius: 4px;
        background-color: #eff0f2;
        outline: none;

        &:focus {
          color: black;
        }

        @media (max-width: 550px) {
          display: none;
        }
      }

      svg {
        color: #767a8a;
        font-size: 21px;
        transform: translateX(-30px) translateY(6px);
      }

      .search-results {
        position: fixed;
        top: 44px;
        margin-left: -20px;
        height: auto;
        max-height: 343px;
        width: 400px;
        max-width: calc(100vw);
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        @media (max-width: 550px) {
          width: calc(100vw);
          margin-left: 0;
          top: 55px;
        }

        .search-result {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 58px;
          border-bottom: 1px solid #eff0f2;
          padding-right: 15px;
          padding-left: 15px;

          .profile-image {
            margin-right: 12px;
            height: 33px;
            width: 33px;
            border: 1px solid #ffffff;
            border-radius: 4px;
            background-color: #365de6;
            color: white;
            text-align: center;
            line-height: 30px;
            font-weight: 300;
            object-fit: cover;
          }

          .group-name {
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          .group-name,
          .group-status {
            color: #33353b;
            font-size: 12px;
            line-height: 16px;
          }

          .group-status {
            margin-left: 16px;
            text-transform: capitalize;
            width: 90px;
            text-align: right;
          }
        }

        .search-more-results {
          height: 44px;

          a {
            color: $primary-color;
            font-size: 13px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .header-notifications {
      cursor: pointer;
      position: absolute;
      right: 40px;
      color: #ffffff;
      display: flex;

      .res-notifications-link {
        &--span{
          display: flex;
        }
      }
    }

    .header-create-group {
      cursor: pointer;
      position: absolute;
      right: 174px;
      height: 34px;
      width: 119px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      background-color: $white;
      color: #666;
      border: 1px solid #666;

      font-size: 13px;
      line-height: 19px;
      text-align: center;
      transition: 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus {
        background-color: #666;
        color: $white;
        border: 1px solid #666;
        outline: none;
      }

      &.admin-panel-button-disabled {
        pointer-events: none;
      }
    }

    .hover-color{
      &:hover,
      &:focus {
        color: #666;
      }
    }

    .header-settings {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      right: -10px;
      font-size: 17px;
      line-height: 17px;
      color: #ffffff;

      &-lecture {
        right: 40px !important;
      }

      &.dropdown-menu {
        div {
          outline: none;
          display: flex;
          align-items:center;
          justify-content: center;
          padding: 0;
        }
        .dropdown {
          width: 160px;
          padding: 10px 0;
          right: -26px;

          &:before {
            border-width: 11px;
          }

          &:after {
            border-width: 10px;
          }

          li {
            display: flex;
            flex-direction: column;

            text-align: left;
            padding: 0;
            align-items: flex-start;
            justify-content: flex-start;
            color: #33353b;

            .btn-link {
              padding: 5px 15px;
              color: #33353b;
              text-decoration: none;
              width: 100%;
              justify-content: flex-start;
              &:hover {
                background-color: #eff0f2;
              }
            }
          }
        }
      }

      svg:not(:first-child) {
        margin-left: 8px;
        font-size: 10px;
        line-height: 10px;
      }
    }

    .header-menu {
      height: 100%;

      ul {
        li {
          color: $white;
          height: 15px;
          width: 27px;

          font-size: 13px;
          line-height: 15px;
          text-align: center;
        }
      }
    }

    .public-group-menu {
      display: flex;

      button {
        color: #fff;

        &.btn-header-signup {
          border: 1px solid #666;
          background-color: $white;
          height: 34px;
          width: 90px;
          border-radius: 4px;
          padding: 0;
          margin: 0;
          color: #666;
          margin-left: 15px;

          &:hover {
            background-color: #666;
            color: $white;
          }
        }
        &.btn-header-login {
          border: 1px solid #666;
          background-color: transparent;
          height: 34px;
          width: 90px;
          border-radius: 4px;
          padding: 0;
          color: #666;
          &:hover {
            background-color: #666;
            color: $white;
          }
        }
      }
    }
  }

  .no-padding{
    padding: 0;
  }
}
.header-communities {
  .groupapp-logo {
    &.disabled {
      pointer-events: none;
      cursor: none;
    }
  }
  &.dropdown-menu {
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 13px;
      outline: none;
      img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
      }
      svg {
        margin: 0 0 0 10px;
      }
    }
    .dropdown {
      right: inherit;
      top: 40px;
      left: calc(100% - 25px);
      width: 290px;
      padding-bottom: 10px;
      display: none;
      &:before {
        right: inherit;
        left: 18px;
        border-width: 11px;
      }
      &:after {
        right: inherit;
        left: 19px;
        border-width: 9px;
      }
      li {
        justify-content: flex-start;
        align-items: flex-start;
        &:first-child {
          padding: 10px 15px !important;
          border-bottom: 1px solid #d5d5d5;
        }
        &:not(:first-child) {
          padding: 5px 15px !important;
          &:hover {
            background-color: #eff0f2;
          }
        }
        b {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 17px;
          color: #000;
        }
        a {
          display: flex;
          align-items: center;
          img {
            width: 43px;
            height: 43px;
            margin: 0 15px 0 0;
          }
          span {
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0;
            color: #000;
          }
          p {
            font-size: 13px;
            letter-spacing: 0;
            margin: 0;
            color: #717171;
          }
        }
      }
    }
    .profile-image {
      border-radius: 100%;
      .group-name {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
      }
    }
  }
}
.header-action-left {
  width: fit-content;
  width: 100%;
  position: absolute;

  #hamburger-toggle-button {
    z-index: 999999;
    position: relative;
  }
}

.header-action-right {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0;
  margin-left: auto;
}

.library-lecture-action {
  width: 70px;
  margin-left: -20px;

  button {
    outline: none;
    margin: auto;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      width: 15px;
      height: 1px;
      background-color: #666;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.notifications-page-hamburger {
  width: 30px;
  margin-left: -2px;

  button {
    outline: none;
    margin: auto;
    width: 20px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      width: 15px;
      height: 1px;
      background-color: #666;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.topbar-lecture-menu-toggle-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupapp-join-community-button {
  height: 34px;
  padding: 0 15px;
  border-radius: 4px !important;
  position: absolute;
  right: 180px;

  @media (max-width: 990px) {
    right: 120px;
  }

    @media (max-width: 550px) {
   font-size: 12px;
  }
}

.btn-border{
  border: 1px solid #333 !important;
}

.gp-community-search {
  &-container {
    position: relative;
    margin-left: 15px;
    transition: 0.1s;
    width: 400px;
    height: 34px;

    @media (max-width: 768px) {
      width: 320px;

      &.course-lecture-page {
        width: 240px;
      }
    }

    @media (max-width: 550px) {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      margin-left: calc(100vw - 260px);

      &.course-lecture-page {
        z-index: 2147483647 !important;
        margin-left: calc(100vw - 500px) !important;

        &.mobile-open {
          margin-left: 0 !important;
        }
      }

      &.library-content-lecture-page {
        z-index: 2147483647 !important;
        margin-left: calc(100vw - 280px) !important;

        &.mobile-open {
          margin-left: 0 !important;
        }
      }

      &.join-community {
        margin-left: calc(100vw - 215px) !important;

        &.mobile-open {
          margin-left: 0 !important;
        }
      }

      &.chat-icon-hidden {
        margin-left: calc(100vw - 215px) !important;

        &.mobile-open {
          margin-left: 0 !important;
        }
      }

      &.unauthed {
        margin-left: calc(100vw - 340px) !important;
        z-index: 2147483647 !important;

        &.mobile-open {
          margin-left: 0 !important;
        }
      }

      &.mobile-open {
        width: 100%;
        height: 50px;
        border-radius: 0;
        position: fixed;
        z-index: 2147483647 !important;
        margin-left: 0;
        left: 0;
        display: block;
      }
    }
  }

  &-input {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 30px;
    background-color: #f3f4f7;

    @media (max-width: 550px) {
      display: none;

      &.mobile-open {
        display: block;
        padding-left: 35px;
        padding-right: 35px;
        border-radius: 0;
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 5px;
    cursor: pointer;

    @media (max-width: 550px) {
      // position: unset;
      // right: 30px;
      // top: 6px;

      &.logged-out {
        // position: absolute;
        // left: -120px;
        width: fit-content;
      }

      svg {
        transform: scale(0.8);
        path {
          // fill: #ffffff;
        }
      }

      &.mobile-open {
        position: absolute;
        top: 15px;
        right: 10px;

        svg {
          path {
            fill: unset;
          }
        }
      }
    }
  }

  &-close {
    display: none;

    @media (max-width: 550px) {
      &.mobile-open {
        display: block;
        position: absolute;
        top: 16px;
        left: 10px;
      }
    }
  }
}

.search-results--community {
  position: fixed;
  top: 44px;
  height: auto;
  max-height: 343px;
  width: 400px;
  max-width: calc(100vw);
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 550px) {
    width: calc(100vw);
    margin-left: 0;
    top: 55px;
  }

  .search-result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 58px;
    border-bottom: 1px solid #eff0f2;
    padding-right: 15px;
    padding-left: 15px;

    .profile-image {
      margin-right: 12px;
      height: 33px;
      width: 33px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      background-color: #365de6;
      color: white;
      text-align: center;
      line-height: 30px;
      font-weight: 300;
      object-fit: cover;
    }

    .group-name {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    .group-name,
    .group-status {
      color: #33353b;
      font-size: 12px;
      line-height: 16px;
    }

    .group-status {
      margin-left: 16px;
      text-transform: capitalize;
      width: 90px;
      text-align: right;
    }
  }

  .search-more-results {
    height: 44px;
    color: $primary-color;
    font-size: 13px;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
