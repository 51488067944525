.user-component {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  cursor: auto;

  &.sliderble {
    cursor: pointer;
  }

  .no-cursor{
    cursor: default !important;
  }

  .profile-image-container {

    .no-cursor{
      cursor: default !important;
    }

    .profile-image {
      display: flex;
      align-self: baseline;
      justify-content: center;
      align-items: center;
      margin-right: 13px !important;
      height: 35px;
      width: 35px;
      min-height: 35px;
      min-width: 35px;
      border-radius: 50%;
      overflow: hidden;
      color: white;
      margin-left: 0px;
      position: relative;
      top: 0px;
      object-fit: cover;

      border-radius: 100%;
      padding: 1px;

      text-align: center;
      font-size: 16px;
      font-weight: bold;

      &--profile-slideout {
        font-size: 12px;
      }
    }
    .bg {
      background-color: #767a8a;
    }
    .mini-profile {
      position: absolute;
      width: 236px;
      height: auto;
      left: 40px;
      top: 16px;
      z-index: 2147483647 !important;
      background: #ffffff;
      border: 1px solid #d5d8df;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px 0px 4px 4px;
      padding: 16px;
      cursor: auto;

      @media (max-width: 550px) {
        left: 50px;
        top: 16px;
      }

      .mini-profile-upper {
        display: flex;
        margin-bottom: 20px;

        .profile-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          background-color: #767a8a;
        }

        .no-profile-image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          color: #ffffff;
          margin-right: 11px;
        }
        .mini-profile-names {
          display: flex;
          align-items: flex-start;
          flex-direction: column;


          .member-name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
      
          }
        }
      }

      .mini-profile-lower {
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        .view-profile-btn {
          background: #ffffff;
          border: 2px solid #1e1e1e;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #1e1e1e;
          padding: 10px 0;
          width: 100%;
          // margin-right: 12px;
        }

        .message-btn {
          background: #1e1e1e;
          border: 2px solid #1e1e1e;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          padding: 10px 0;
          width: 100%;
          margin-left: 12px;
        }
      }
    }
  }

  .status {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    padding: 0;
    margin-right: -25px;
    z-index: 999;
    margin-bottom: -20px;
    &.active {
      background-color: #17b879;
    }
    &.inactive {
      background-color: #838383;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    .info-name {
      display: flex;
      align-items: center;

      &-badged {
        align-items: center;
      }

      .period{
        background-color:#868b9a;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        margin-left: 4px;
      }
    }

    .name {
      width: max-content;
      color: #33353b;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      margin: 0;
      padding: 0;

      &:hover {
        color: #365de6 !important;
      }
    }

    .no-hover{
      &:hover{
        color:#33353b !important;
      }
    }

    .postview-user-name {
      @media (max-width: 550px) {
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .minimizable-user-name {
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .highlightable-user-name {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.1s;
      &:hover {
        color: #365de6 !important;
        text-decoration: underline;
      }
    }

    .timestamp {
      width: 263px;
      color: #868b9a;

      font-size: 12px;
      line-height: 16px;
      margin-left: 4px;

      &--postview {
        width: fit-content;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        margin-left: 4px;
      }
    }

    .non-clickable-timeStamp{
      cursor: default !important;
    }

    .job-title {
      color: #767a8a;

      font-size: 13px;
      line-height: 24px;
    }

    .about {
      height: auto;
      color: #3c3e44;

      font-size: 12px;
      line-height: 15px;
    }

    .headline {
      max-width: 400px;
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #838383;

      @media (max-width: 550px) {
        max-width: 300px;
        width: 300px;
      }

      &-text{
        white-space: nowrap; 
        text-overflow: ellipsis; 
        overflow: hidden; 
        font-size: 12px;
      }
    }

    .subtext {
      &-text {
        margin: 0;
        padding: 0;

        color: #3c3e44;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

@media (max-width: 550px) {
  .user-component {
    // width: calc(100% - 23px);
    .info {
      .name {
        width: max-content;
      }
    }
  }
}
