.btn {
  border: 1px solid #365de6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  position: relative;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;

    &-grey {
      background-color: #D5D8DF !important;
      border: none;
    }
  }

  &.link {
    color: #0000FF;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  &-round {
    &-4 {
      border-radius: 4px;
    }

    &-6 {
      border-radius: 6px;
    }

    &-8 {
      border-radius: 8px;
    }
  }

  &.btn-spec-lr {
    // height: 48px;
    max-width: 295px;
    width: 100%;
    font-size: 17px;
    padding: 0.5rem 2rem;
    font-weight: bold;
    transition: 0.5s;
    background-color: #365de6;
    color: #fff;
  }

  &.btn-spec-lr:hover {
    background-color: #fff;
    color: #365de6;
  }

  &.btn-primary {
    padding: 0 10px;
    color: #365de6;
    // height: 28px;
    padding: 0.5rem 2rem;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 70px;
    cursor: pointer;

    &.btn-admin-blue {
      background-color: #365de6;
      color: #fff;
    }

    &:hover {
      background-color: #365de6;
      color: #ffffff;
    }
  }

  &.btn-primary a {
    padding: 1rem;
    margin: -1rem;
    cursor: pointer;
  }

  &.btn-secondary {
    height: 28px;
    width: 84px;
    border: 1px solid #365de6;
    border-radius: 4px;
    background-color: #ffffff;
    color: #365de6;
    font-size: 13px;
    font-weight: bold;
    line-height: 15px;

    transition: all ease-in 0.2s;

    &:hover {
      background-color: #365de6;
      color: #ffffff;
    }

    &.red {
      border-color: #e84859;
      color: #e84859;

      &:hover {
        background-color: #e84859;
        color: #ffffff;
      }
    }
  }

  &.btn-cancel {
    color: #FFFFFF;
    padding: 0.5rem 2rem;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 70px;
    cursor: pointer;
    background-color: #91a0ba;
    border: 1px solid #91a0ba;

    &:hover {
      background-color: #FFFFFF;
      color: #91a0ba;
    }
  }

  &.dropdown-menu-action {
    svg {
      margin-left: 10px;
    }
  }

  &.btn-link {
    border: 0;
    color: #365de6;

    &:hover {
      text-decoration: underline;
    }
  }

  &.btn-no-style {
    border: 0;
    border-radius: 0;
    display: inherit;
    outline: 0;

    &:hover {
      color: #365de6;
    }
  }

  &.btn-delete {
    color: #8c8e94;
    border: 0;
    border-radius: 0;
    display: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.red {
      color: red;
      margin-right: 8px;
    }
  }

  &.btn-dots {
    border: 0;
    border-radius: 0;
    outline: 0;
    display: flex;
    flex-direction: column;

    span {
      height: 4px;
      width: 4px;
      background-color: #33353b;
      border-radius: 50%;
      margin-bottom: 1px;
    }
  }

  &.btn-top-bar {
    border: 0;
    border-radius: 0;
    outline: 0;
    padding: 5px 15px;
    width: 100%;
    text-align: right;
    justify-content: left;

    &:hover {
      background-color: #eff0f2;
    }
  }

  &.btn-m-size {
    padding: 4px 14px;
    height: 34px;
  }
}

img {
  outline: none;
}