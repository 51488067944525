.horizontal-slide-scroll {
    &-container {
        width: 100%;
        position: relative;

        &.right-fade {
            
        }

        &.left-fade {
            
        }
    }

    &-left-fade {
        width: 10%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg,  #F2F4F7 0%,rgba(247,244,247,0)  100%);
    }

    &-right-fade {
        width: 10%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(242, 244, 247, 0) 0%, #F2F4F7 100%);
    }

    // &-action {
    //     position: absolute;
    //     right: 0;
    //     top: 40%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;
    //     z-index: 10;

    &-action {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10;

        &.action-left {
            left: 0;
            right: unset;
            transform: translateY(-50%) rotate(180deg) ;
        }

        &.home-slider {
            @media(max-width: 550px) {
                // z-index: 0;
            }
        }
    }
}