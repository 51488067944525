@media (max-width: 550px) {
    .messages {
        &-grouped {
        }
        &-content {
            &-body {
                padding: 0 !important;
                height: 72% !important;
        
                &-messages {
                    overflow-y: auto !important;
                    padding-bottom: 50px !important;

                    &-single {
                        &-wrap {
                            
                        }
                    }
                }

                &--direct {
                    height: 76.6% !important;
                }
            }
        }
        &-header {
            
        }
    }

    .members-new-profile {
        &-body {
            overflow-y: auto !important;
        }
    }

    .no-chat-history {
        position: relative;
        top: 50px;
    }
}