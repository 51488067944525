.dEeXGa {
    overflow: hidden !important;
}
.messages {
    &-wrapper {
        padding: 30px 20px;
        width: 100%;
        height: calc(100% - 55px);
        scrollbar-gutter: stable;
        ul {
            list-style: none !important;
            padding: 0 !important;
        }
        h3 {
            color: #000000;

            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            margin: 0 0 15px;
        }
        .members-new-profile {
            background-color: #fff;
            border-left: 1px solid #d5d8df;
            width: 483px !important;
            margin-right: -485px;
            overflow: hidden;
            position: relative;

            .header-member-detail{
                box-shadow: none;
                border-top: 1px solid #d5d8df;
                border-radius: 1px;
                padding: 18px;
                margin-top: 1px;

                .footer{
                    display: none;
                }
            }
            .member-sub-container {
                box-shadow: none;
                border-top: 1px solid #d5d8df;
                border-radius: 1px;
                padding: 0 18px;
                // margin: 13px 0;
                margin: 0;
                padding-top: 18px;

                .header{
                    padding: 0;
                }

                @media (max-width: 550px) {
                    margin: 0;
                }
            }

            .bio-container {
                box-shadow: none;
                border-top: 1px solid #d5d8df;
                border-radius: 1px;
                padding: 0 18px;
                margin: 0;
                padding-top: 18px;

                .header{
                    padding: 0;
                }
            }

            .custom-container  {
                box-shadow: none;
                border-top: 1px solid #d5d8df;
                border-radius: 1px;
                padding: 0 18px;
                margin: 0;
                padding-top: 18px;
                .header{
                    padding: 0;
                    padding-top: 1px;
                }
            }
        
            @media (max-width: 550px) {
                overflow-y: auto;
                .profile-slideout-content-info-header-details-email {
                    width: fit-content !important;
                }
            }

            &:hover {
                overflow-y: auto;
                position: static;
            }

            &::-webkit-scrollbar {
                width: 8px; /* Set the width of the scrollbar */
                transition: width 0.3s;
            }

            &:hover::-webkit-scrollbar {
                width: 10px; /* Increase the width on hover */
            }

            &::-webkit-scrollbar-thumb {
                background-color: #aaa; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Rounded corners of the scrollbar thumb */
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Background color of the scrollbar track */
                border-radius: 10px;
            }
            .header {
                display: flex;
                padding: 20px 14px;
                align-items: center;
                @media (max-width: 550px) {
                    padding: 10px 14px;
                    margin-top: 20px;

                    .back {
                        font-size: 12px !important;
                        margin: 12px 0 !important;
                        cursor: pointer !important;
                        margin-top: -10px !important;

                        svg {
                            width: 12px;
                            margin-top: 3px;
                        }
                    }
                }

                .back {
                    font-size: 12px;
                    color: #33353b;
                    // margin: 12px 0;
                    // margin-bottom: 16px;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        margin-top: 3px;
                    }
                }
            }
            span.image {
                margin: 0 !important;
                font-size: 50px !important;
                color: #ffffff !important;
                padding-top: 20px;
            }
            .user-component {
                height: 100%;
                width: 100%;
                font-size: 50px;
                .profile-image {
                    height: 100% !important;
                    width: 100% !important;
                    min-height: 100% !important;
                    min-width: 100% !important;
                    font-size: 50px;
                }
            }
        }
    }
    &-container {
        border: 1px solid #d5d8df;
        background: #fff;
        border-radius: 3px;
        display: flex;
        height: 100%;
        overflow: hidden;

        .message-btn {
            border-radius: 4px;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            color: #ffffff;
            padding: 8px 10px;
            margin-top: 10px;
            display: none;
        }

        &-grouped {
            &.active-profile {
                .messages-content {
                    width: 100% !important;
                    min-width: 100%;
                }
                .members-new-profile {
                    margin-right: 0;
                }
            }
        }
        &.active-members {
            .messages-grouped {
                .messages-chat-members {
                    margin-right: 0px;

                    .member-container {
                        margin: 0 !important;
                        padding: 0 !important;
                        position: relative;
                        &:hover {
                            border-radius: 3px;
                            background-color: rgba(54, 92, 230, 0.06);
                        }

                        .icon {
                            width: 25px;
                            height: 25px;
                            position: absolute;
                            right: 14px;
                            top: 12px;
                            
                            svg {
                                // color: #717171;
                                width: 20px;
                                height: 20px;
                                stroke-width: 2;
                            }
                        }
                    }

                    .member-details {
                        width: 100%;
                        min-width: 100%;
                        align-items: center;
                        display: flex;
                        margin-left: -10px;

                        &.admin {
                            width: 91%;
                            min-width: 91%;
                        }
                        .user-component {
                            cursor: pointer;
                            padding: 5px 18px;
                            .info .name {
                                color: #33353b;
                                font-size: 13px;
                                letter-spacing: 0;
                                line-height: 18px;
                                font-weight: normal;
                                max-width: 200px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .profile-image {
                                width: 35px !important;
                                height: 35px !important;
                                min-height: 35px !important;
                                min-width: 35px !important;
                                font-size: 13px;
                            }
                        }
                    }

                    .actions-dropdown-menu {
                        width: 20%;
                        text-align: right;
                        right: 4px;
                        top: 15px;

                        .dropdown {
                            right: -30px;
                            top: 25px;
                        }
                    }
                }
            }
        }
    }
    &-grouped {
        width: calc(100% - 241px);
        height: 100%;
        overflow: hidden;
        overflow-x: hidden;

        .messages-chat-members {
            margin-right: 0;
            .member-details {
                align-items: center;
                display: flex;
                margin-left: -10px;
            }
            .actions-dropdown-menu {
                text-align: right;
                right: -12px;
            }
            .plus-icon {
                margin-right: 4px;
            }

            &-body{
                .user-component{
                    .info{
                        .headline{
                            max-width: 200px;
                            width: 200px;
                        }

                    }
                }
            }
        }
        .messages-content {
            width: 100%;
            height: 100%;
            overflow: hidden !important;
            position: relative;

            &-header {
                padding: 12px 25px;
                border-bottom: 1px solid #d5d8df;
                position: absolute;
                width: 100%;
                z-index: 100;
                background-color: #ffffff;
                font-size: 14px;

                .arrow-previous {
                    display: none;
                    @media (max-width: 550px) {
                        color: #666;
                        display: block;
                        cursor: pointer;
                        margin-right: 10px;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                .user-component {
                    width: auto;
                    font-size: 14px;
                    .profile-image {
                        width: 35px !important;
                        height: 35px !important;
                        min-height: 35px !important;
                        min-width: 35px !important;
                        border: 3px solid #ffffff;
                    }

                    &.groupchat {
                        width: 100%;
                    }
                }

                .header-title {
                    text-align: center;
                    font-size: 22px;
                    line-height: 26px;
                    margin-top: 50px;
                }
                .header-subtitle {
                    color: #33353b;
                    text-align: center;
                    letter-spacing: 0;
                    line-height: 15px;
                }
                input {
                    border-radius: 17px;
                    background-color: #f5f6fa;
                    width: 100%;
                    border-radius: 100px;
                    height: 34px;
                    color: #84878e;

                    font-size: 14px;
                    letter-spacing: 0;
                    border: 0;
                    padding: 0 15px;
                    outline: none;
                }
                &-message {
                    display: flex;
                    align-items: center;

                    img {
                        margin: 0 10px 0 0;
                    }
                }
                &-profiles {
                    height: 35px;
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    padding-right: 8px;
                    cursor: pointer !important;

                    &:hover {
                        border-radius: 3px;
                        background-color: rgba(54, 92, 230, 0.06);
                    }

                    .group-profile-pic {
                        height: 35px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        margin-right: 8px;

                        .user-component {
                            cursor: pointer;
                        }

                        li {
                            width: 30px !important;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &:not(:first-child) {
                                margin-left: -12px;
                            }
                        }

                        .message-plus-members {
                            position: relative;
                            display: flex;

                            .member-count {
                                background-color: rgba(0, 0, 0, 0.7);
                                height: 33px !important;
                                color: #ffffff;
                                width: 33px !important;
                                border-radius: 100%;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 14px;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: 99;
                                margin-left: 3px;
                                font-weight: 700;
                            }
                        }
                    }
                    &-groupchat-dropdown {
                        position: absolute;
                        box-sizing: border-box;
                        height: 75px;
                        width: 152px;
                        border: 1px solid #d5d8df;
                        border-radius: 3px;
                        background-color: #ffffff;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                        top: 65px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 10px;
                        &:before {
                            content: "";
                            border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ccc rgba(255, 255, 255, 0);
                            right: 70px;
                            margin-left: -11px;
                            border-width: 5px;
                            border-style: solid;
                            display: block;
                            height: 0;
                            position: absolute;
                            pointer-events: none;
                            width: 0;
                            bottom: 100%;
                        }
                        &:after {
                            border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) white rgba(255, 255, 255, 0);
                            right: 71px;
                            margin-left: -10px;
                            border-width: 4px;
                            border-style: solid;
                            content: " ";
                            display: block;
                            height: 0;
                            position: absolute;
                            pointer-events: none;
                            width: 0;
                            bottom: 100%;
                        }
                        .view-all-members {
                            color: #33353b;

                            font-size: 11px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 14px;
                            text-align: center;
                        }
                        .members-include {
                            color: #7d8088;

                            font-size: 11px;
                            letter-spacing: 0;
                            line-height: 14px;
                            text-align: center;
                            margin-top: 5px;
                        }
                    }
                }
                &-wrap {
                    b {
                        color: #33353b;
                        font-size: 14px;

                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 14px;
                        margin: 0;
                    }
                    small {
                        color: #33353b;

                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: 0;
                        line-height: 14px;
                        margin-right: 15px;
                    }
                    span {
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 14px;
                        margin: 0;
                    }
                    .view-profile {
                        cursor: pointer;
                        color: #365de6;
                    }
                    a {
                        color: #365de6;

                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 14px;
                        margin: 0;
                    }
                }

                .ind-action-separator {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    justify-content: space-between;
                    .chatroom-actions-dropdown {
                        padding-top: 9px;

                        .dropdown {
                            right: -8px !important;
                            top: 16px !important;
                        }
                    }
                }
            }
            &-body {
                padding: 0;

                background-color: #365de6;

                &--group {
                    margin-top: 28px;
                    padding-bottom: 1.7rem;
                }

                &--direct {
                    // margin-top: 19px;
                }

                &-messages {
                    height: 100%;
                    width: 99.7% !important;

                    &:hover {
                        overflow-y: auto;
                        position: static;
                    }

                    &::-webkit-scrollbar {
                        width: 8px; /* Set the width of the scrollbar */
                        transition: width 0.3s;
                    }

                    &:hover::-webkit-scrollbar {
                        width: 10px; /* Increase the width on hover */
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #aaa; /* Color of the scrollbar thumb */
                        border-radius: 10px; /* Rounded corners of the scrollbar thumb */
                    }

                    &::-webkit-scrollbar-track {
                        background-color: #f1f1f1; /* Background color of the scrollbar track */
                        border-radius: 10px;
                    }

                    .big-loading {
                        @media (max-width: 550px) {
                            display: none;
                        }
                    }

                    .loading {
                        display: none;
                        @media (max-width: 550px) {
                            margin-left: -20px;
                            width: 1200px;
                            height: 100%;
                            display: flex;
                            overflow: hidden;
                        }
                    }
                }

                &-messages-single {
                    .user-component {
                        width: auto;
                        align-self: flex-start;
                    }
                    .message-likes {
                        box-sizing: border-box;
                        height: 20px;
                        width: 37px;
                        border: 1px solid #d5d8df;
                        border-radius: 8.5px;
                        background-color: #eff0f2;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        img {
                            margin: 0 4px 0 0;
                        }
                        .message-liked {
                            font-weight: bold;
                        }
                    }
                    &.no-chat-history {
                        align-items: center;
                        font-size: 16px;
                        padding: 30px 10px;
                        .chat-with {
                            color: #365de6;
                        }
                        p {
                            font-size: 16px;
                        }
                        .user-component {
                            .profile-image {
                                width: 60px !important;
                                height: 60px !important;
                            }
                        }
                        .group-chat {
                            width: 60px;
                        }
                    }
                }
            }
        }
        .messages-chat-members {
            width: 483px !important;
            border-left: 1px solid #d5d8df;
            margin-right: -483px;
            overflow: hidden;
            position: relative;
            height: 100% !important;
            background: none !important;

            &:hover {
                overflow: overlay;
                @supports (-moz-appearance: none) {
                    overflow: auto;
                }
            }
            &-header {
                padding: 25px 20px;
                padding-left: 34px;
                font-size: 14px;

                .close {
                    color: #666;
                    width: 20px;
                    height: 20px;
                    display: block;
                    cursor: pointer;
                    padding: 0 !important;
                    position: absolute;
                    right: 30px;
                    top: 24px;
                }
                b {
                    color: #33353b;
                    letter-spacing: 0;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    span {
                        font-weight: 400;
                    }
                    img {
                        margin-left: auto;
                        cursor: pointer;
                    }
                }
            }
            &-body {
                padding: 0 25px 25px;

                .user-icon-container {
                    margin-left: 16px;
                    margin-top: 6px;

                    .user-icon {
                        color: #365de6;
                        width: 20px;
                        height: 20px;
                        display: block;
                        cursor: pointer;
                        padding: 0 !important;
                        margin-right: 8px;
                    }
                }

                ul {
                    padding: 0;
                    margin: 0;
                    li {
                        color: #33353b;

                        font-size: 13px;
                        letter-spacing: 0;
                        line-height: 24px;
                        display: flex;
                        &:not(:last-child) {
                            margin: 0 0 5px 20px;
                        }

                        img {
                            margin: 0 10px 0 0;
                            &.dots {
                                margin-left: auto;
                                cursor: pointer;
                                margin-right: 0;
                            }
                        }
                        button {
                            color: #365de6;

                            font-size: 13px;
                            letter-spacing: 0;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            img {
                                margin: 0 20px 0 0;
                            }
                        }
                        button.leave-group {
                            color: rgb(223, 39, 39);
                        }
                    }
                }
            }
        }
        .messages-input {
            padding: 10px;
            border-top: 1px solid #d5d8df;
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: #ffffff;
            z-index: 100;
            font-size: 14px;

            textarea {
                border-radius: 6px;
                background-color: #f5f6fa;
                color: #717171;
                letter-spacing: 0;
                line-height: 16px;
                border: 0;
                resize: none;
                width: 100%;
                height: 120px;
                padding: 10px 50px 10px 10px;
                outline: none;
                color: #33353b;
            }
            textarea::placeholder {
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                padding-top: 10px;
                text-align: center;

                @media (max-width: 550px) {
                    font-weight: 400;
                    font-size: 16px;
                    text-align: center;
                }
            }
            .message-textarea{
                &::placeholder{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: start;
                    padding: 0;
                }
            }
            .send-message-btn {
                background-color: #365de6;
                letter-spacing: 0;
                position: absolute;
                right: 20px;
                top: 86px;
                color: #fff;
                color: #ffffff;
                border-radius: 4px;
                padding: 8px 10px;
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                text-align: center;
            }
            .disabled {
                color: #888 !important;
                cursor: not-allowed !important;
            }
        }
        .messages-add-members-modal {
            height: 242px;
            width: 654px;
            border-radius: 3px;
            background-color: #ffffff;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            &-content {
                padding: 8px;

                .loading-mes {
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    margin-top: 160px;
                    text-align: center;
                    justify-content: center;
                }
                .loading-results {
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    margin-top: 160px;
                    text-align: center;
                    justify-content: center;
                }
                .confirmation-msg {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                }
                .close {
                    color: #666;
                    width: 28px;
                    height: 28px;
                    display: block;
                    cursor: pointer;
                }
                .title {
                    color: #000000;

                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                    margin: 0;
                }
                .subtitle {
                    margin: 24px 40px;
                    color: #767a8a;

                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-align: center;
                    margin-top: 10px;
                }
                .confirmation-btns {
                    display: flex;
                    margin-top: 6px;
                    .del-btn {
                        box-sizing: border-box;
                        height: 37px;
                        width: 125px;
                        border: 1px solid #dd213d;
                        border-radius: 4px;
                        color: #dd213d;

                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 15px;
                        transition: 0.5s;
                        margin-right: 12px;
                        &:hover {
                            color: #ffffff;
                            background-color: #dd213d;
                        }
                    }
                    .cancel-btn {
                        box-sizing: border-box;
                        height: 37px;
                        width: 125px;
                        border: 1px solid #365de6;
                        color: #365de6;
                        border-radius: 4px;

                        font-size: 13px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 15px;
                        transition: 0.5s;
                        margin-left: 12px;
                        &:hover {
                            color: #ffffff;
                            background-color: #365de6;
                        }
                    }
                }
            }
        }
    }
    &-add-members-modal {
        width: 539px !important;
        height: 620px !important;
        // max-width: 95% !important;
        margin: 0 auto;
        padding: 0px 10px;
        overflow: hidden !important;
        &-content {
            top: 20px;
            position: absolute;
            height: 100%;
            .add-people {
                height: 48px;
                width: 100%;
                color: #000000;

                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
            }
            &-header {
                position: relative;
                .modal__close {
                    position: absolute;
                    right: -50px;
                }
            }
            .modal-form--wrap {
                width: 100%;
                margin: 0 auto;

                .form-group {
                    width: 100%;
                    margin: 0 auto;
                    position: relative;

                    .user-component {
                        .info .name {
                            color: #33353b;
                            font-size: 13px;
                            letter-spacing: 0;
                            line-height: 18px;
                            font-weight: normal;
                            max-width: 280px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    .search-input {
                        box-sizing: border-box;
                        height: 42px;
                        width: 401px;
                        border: 1px solid #d5d8df;
                        border-radius: 4px;
                        background-color: #ffffff;
                        padding-left: 5px;
                        max-width: 100%;
                    }
                    .search-icon {
                        width: 19px;
                        height: 19px;
                        right: 8px;
                        position: absolute;
                        margin-top: 10px;
                    }
                    table.modal-member {
                        tbody {
                            height: 310px;
                            tr {
                                grid-template-columns: 2fr minmax(12px, 0.25fr) 0fr;
                                td {
                                    border-bottom: none;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    input[type="checkbox"] {
                                        // &:focus {
                                        //     border: none;
                                        // }
                                        &:checked {
                                            border: none;
                                        }
                                        // &:active {
                                        //     border: none;
                                        // }
                                    }
                                }
                            }
                        }
                        thead tr {
                            grid-template-columns: 1fr 1fr 1fr minmax(12px, 0.05fr);
                            th {
                                border-bottom: none;
                                color: #33353b;

                                font-size: 12px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 14px;
                            }
                        }
                    }
                    .table-header {
                        border-bottom: none;
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
                .form-group__action {
                    position: absolute;
                    bottom: 40px;
                    width: 100%;
                    justify-content: center;
                    .btn.btn-primary {
                        box-sizing: border-box;
                        height: 48px;
                        width: 338px;
                        border: 1px solid #365de6;
                        border-radius: 4px;
                        background-color: #365de6;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 15px;
                        font-weight: bold;
                        &:hover {
                            padding: 0 10px;
                            color: #365de6;
                            display: flex;
                            align-items: center;
                            position: relative;
                            cursor: pointer;
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
