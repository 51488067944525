.comm-welcome {
    max-width: 620px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    font-size: 16px;

    &-title {
        margin: 0;
        padding: 0;
        font-size: 22.5px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        margin-bottom: 16px;
        color: #191b1f;
    }

    &-desc {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        .draft-content-wrapper .public-DraftStyleDefault-block {
            line-height: normal !important;
        }

        .public-DraftStyleDefault-ul {
            margin-left: 0px !important;
        }

        .public-DraftStyleDefault-ol {
            margin-left: 6px !important;

            div {
                padding-top: 2px;
            }
        }

        &-video {
            display: flex;
            width: 99%;
            align-items: center;
            position: relative;

            @media (max-width: 768px) {
                width: 100%;
                margin: 0 auto;
            }

            .gp-bunny-video-player-container {
                position: unset;
            }

            .gp-bunny-video-player-iframe {
                top: 0;
                border-radius: 4px;
            }

            .yt-player-container {
                margin-top: 12px;

                .yt-player {
                    border-radius: 4px;
                    width: calc(620px - 5%);

                    @media (max-width: 768px) {
                        width: calc(100vw - 25px);
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
