.comm-answerable-question {
    &-container {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &-title {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #333333;

        span {
            color: #d85050;
            margin-left: 2px;
        }
    }

    &-options {
        &-container {
            margin-top: 10px;
        }

        &-textarea {
            width: 850.02px !important;
            height: 100px !important;
            border: none;
            border: 1px solid#d5d8df;
            resize: none;
            display: block;
            color: #666666;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            border-radius: 4px;
            padding: 8px;

            @media (max-width: 550px) {
                width: 300px !important;
            }

            &:focus {
                outline: none;
            }
        }

        &-option {
            &-wrapper {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                input[type="radio"] {
                    /* Add if not using autoprefixer */
                    -webkit-appearance: none;
                    /* Remove most all native input styles */
                    appearance: none;
                    /* For iOS < 15 */
                    background-color: #fff;
                    /* Not removed via appearance */
                    margin: 0;

                    color: #666;
                    border: 0.1em solid #666;
                    border-radius: 50%;
                    transform: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-top: 2px;
                    width: 16px !important;
                    height: 16px !important;
                    margin-right: 8px;
                    cursor: pointer;
                }

                input[type="radio"]::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%; /* Make it a circle */
                    transform: scale(0);

                    /* Windows High Contrast Mode */
                    background-color: CanvasText;
                }

                input[type="radio"]:checked::before {
                    transform: scale(1);
                    background-color: #365de6;
                }

                input[type="checkbox"] {
                    /* Add if not using autoprefixer */
                    -webkit-appearance: none;
                    /* Remove most all native input styles */
                    appearance: none;
                    /* For iOS < 15 */
                    background-color: #fff;
                    /* Not removed via appearance */
                    margin: 0;

                    color: #666;
                    width: 1.15em;
                    height: 1.15em;
                    border: 0.1em solid #666;
                    border-radius: 0.15em;
                    display: grid;
                    place-content: center;
                    // margin-top: 2px;
                    width: 16px !important;
                    height: 16px !important;
                    margin-right: 8px;
                    cursor: pointer;
                }

                input[type="checkbox"]::before {
                    content: "";
                    width: 0.65em;
                    height: 0.65em;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                    transform: scale(0);

                    /* Windows High Contrast Mode */
                    background-color: CanvasText;
                }

                input[type="checkbox"]:checked::before {
                    transform: scale(1);
                    background-color: #365de6;
                }
            }

            &-checkbox,
            &-radio {
                width: 16px !important;
                height: 16px !important;
                margin-right: 8px;
            }

            &-label {
                margin: 0;
                padding: 0;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #333333;
                max-width: calc(100% - 10px);
                word-wrap: break-word;

                @media (max-width: 550px) {
                    width: 280px;
                }

                &--checked {
                    font-weight: bold;
                }
            }
        }
    }
}
