@import "../../assets/styles/abstracts/_variables";

.content-page {
  width: 100%;
  height: 100%;
  .content-page-wrap {
    width: 100%;
    max-width: 695px;
    .card {
      width: 100%;
      max-width: 695px;
      border: 1px solid #d5d8df;
      border-radius: 3px;
      background-color: #ffffff;
      margin-bottom: 6px;

      &.card-welcome {
        height: 216px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        width: 99%;
        margin: 0 auto;
        border: 1px solid #d5d8df !important;
        box-shadow: none !important;

        &-loading {
          margin-bottom: 20px;
        }

        h3 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 5px;
          color: #000000;
        }
        span {
          font-size: 14px;
           
          color: #767a8a;
          margin-bottom: 35px;
        }
        .btn-create-group {
          border: 1px solid #365de6;
          background-color: #fff;
          display: flex;
          align-items: center;
          position: relative;
          border-radius: 4px;
          height: 43px;
          transition: background-color ease-in 200ms;
          padding: 9px;

          span {
            color: $primary-color;
            font-weight: 600;
            margin: 0 0 0 10px;
          }

          &:hover {
            color: $white;
            background-color: $primary-color;

            path {
              fill: $white;
            }
            span {
              color: $white;
            }
          }
        }
      }
      &.card-recommendation {
        padding: 20px;
        .card-recommendation--content {
          position: relative;
          .card-recommendation--content-media {
            padding-right: 15px;
            img,
            .group-name {
              width: 53px;
              height: 53px;
              border-radius: 4px;
              display: block;
              object-fit: cover;
              text-align: center;
              color: white;
              font-size: 20px;
              font-weight: 600;
              line-height: 53px;
            }
          }
          .card-recommendation--content-page-info {
            h3 {
              margin: 0;
              color: $black;
              font-size: 18px;
              font-weight: 600;
               
              line-height: 15px;
            }
            .page--group-type {
              font-size: 13px;
              color: #797979;
              display: block;
              margin-top: 8px;
            }
            .page--group-content {
              font-size: 14px;
              padding: 0;
              margin: 5px 0 15px;

              button {
                color: $primary-color;
                margin-left: 5px;
              }
            }
            .page--member-count {
              color: $black;
              font-size: 14px;
            }
          }
        }
        .card-recommendation--action {
          margin-left: 15px;
          button {
            border-radius: 4px;
            border: 1px solid #365de6;
            width: 88px;
            height: 28px;
            min-height: 1px;
            background: #365de6;
            color: #fff;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            img {
              margin-left: 10px;
            }

            &:hover {
              background-color: $primary-color;
              color: $white;
            }
          }
        }
      }
    }
  }
}
