.top-bar-mes-container {
    position: absolute;
    right: 75px;
    z-index: 9999 !important;

    .mes-icon {
        display: flex;
        cursor: pointer;

        svg{
            margin-bottom: 1px;
        }
    }

    @media (max-width: 550px) {
        right: 90px !important;

        &--lecture {
            right: 115px !important;
        }
    }

    ul {
        display: block;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .dropdown {
        right: -12px !important;
        width: auto !important;
        top: 22px !important;
    }

    .chats-count {
        background-color: #ffbb51;
        color: #3a3834;
        border-radius: 50%;
        position: absolute;
        left: 13px;
        top: -13px;
        font-size: 11px;
        font-weight: 700;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .mes-dialog {
        height: 550px;
        width: 450px;
        margin-top: 20px;
        padding: 10px 0;
        margin-left: -430px;
        overflow: hidden;

        border: 1px solid #d5d8df;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        @media (max-width: 550px) {
            width: 100vw;
            margin-left: 0;
            position: fixed;
        }

        &-header {
            padding: 0 14px;
            border-bottom: 1px solid #d5d8df;
            width: 100%;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;

            &-bottom {
                display: flex;
                margin-top: 10px;
                color: #484848;

                &-inbox {
                    margin-right: 16px;
                    cursor: pointer;
                }

                &-unread {
                    cursor: pointer;
                }

                .active {
                    text-decoration: underline;
                    text-underline-offset: 14px;
                    text-decoration-color: #365de6;
                    text-decoration-thickness: 2px;
                    color: #19283a;
                    font-weight: 700;
                }
            }
        }

        &-chats {
            height: 420px;
            width: 448px;
            overflow: hidden;
            padding-bottom: 120px;

            .infinite-scroll-component__outerdiv {
                height: 550px;
                width: 454px !important;

                .ScrollSpinner {
                    overflow: hidden !important;
                    height: 100% !important;
                    p {
                        overflow: hidden !important;
                    }
                }
            }

            @media (max-width: 550px) {
                overflow-y: auto;
                position: static;
            }

            &:hover {
                overflow-y: auto;
                position: static;
            }

            &::-webkit-scrollbar {
                width: 8px; /* Set the width of the scrollbar */
            }

            &::-webkit-scrollbar-thumb {
                background-color: #aaa; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Rounded corners of the scrollbar thumb */
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Background color of the scrollbar track */
                border-radius: 10px;
            }

            .mes-open {
                cursor: pointer;
            }

            .see-all {
                position: fixed;
                background-color: #ffffff;
                border-top: 1px solid #d5d8df;
                padding: 15px 0;
                z-index: 99;
                top: calc(550px - 2px);
                width: 448px;
                margin: 0 auto;
                border-radius: 0 0 3px 3px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 550px) {
                    width: 100vw;
                    height: 40px;
                    top: 559px;
                    padding-bottom: 15px;
                }

                &-text {
                    justify-content: center;
                    font-size: 15px;
                    cursor: pointer;
                    color: #365de6;
                }
            }

            &-body {
                display: flex;
                margin-bottom: 6px;
                padding: 6px 14px;
                box-sizing: border-box;
                width: 448px;
                height: 100%;

                @media (max-width: 550px) {
                    width: 100%;
                    max-width: calc(100vw - 20px);
                }

                &:hover {
                    background-color: rgba(54, 92, 230, 0.06);
                    border-radius: 4px;
                }

                &-image {
                    margin-right: 10px;
                    background-color: #767a8a;
                    height: 35px;
                    width: 35px;
                    border-radius: 100%;
                    padding: 1px;

                    .profile-image {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }

                .profile-no-image {
                    margin-right: 10px;
                    height: 35px;
                    width: 35px;
                    color: #ffffff;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 20px;
                }

                .group-profile-pic {
                    height: 35px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    margin-right: 8px;

                    .loader {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background-color: #ccc;
                        position: relative;
                    }

                    .loading-bar {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        background-color: #c3c3c3; /* You can change the color to your desired loader color */
                        position: absolute;
                        animation: fadeInOut 500ms linear infinite;
                    }

                    @keyframes fadeInOut {
                        0%,
                        100% {
                            opacity: 0.2;
                        }
                        50% {
                            opacity: 1;
                        }
                    }

                    li {
                        width: 30px !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:not(:first-child) {
                            margin-left: -12px;
                        }
                    }

                    .message-plus-members {
                        position: relative;
                        display: flex;

                        .member-count {
                            background-color: rgba(0, 0, 0, 0.7);
                            height: 35px !important;
                            color: #ffffff;
                            width: 35px !important;
                            border-radius: 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 14px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 99;
                            margin-left: 3px;
                            font-weight: 700;
                        }
                    }
                }

                &-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .message-timestamp {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 4px;

                        .recipient-name {
                            color: #19283a;
                            font-weight: 700;
                            font-size: 14px;
                            max-width: 200px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            @media (max-width: 550px) {
                                max-width: 180px;
                            }
                        }

                        .recipient-timestamp {
                            font-size: 13px;
                            color: #767a8a;
                            font-weight: 700;
                        }
                    }

                    .message-content-count {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .message-content {
                            font-size: 14px;
                            color: #767a8a;
                            font-weight: 400;
                            line-height: 16px;
                            max-width: 320px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            @media (max-width: 550px) {
                                max-width: 220px;
                            }
                        }
                        .unread-message-content {
                            font-size: 13px;
                            font-weight: 700;
                            color: #19283a;
                            line-height: 16px;
                            max-width: 320px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .unread-messages-container {
                            background-color: #365de6;
                            height: 12px;
                            width: 12px;
                            border-radius: 50%;
                        }
                    }
                }
            }

            &-no-messages {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 80%;
                font-size: 15px;
                color: #767a8a;
                font-weight: 700;
                pointer-events: none !important;

                .icon {
                    color: #666;
                    width: 52px;
                    height: 52px;
                    display: block;
                    margin-bottom: 3px;
                }

                .heading {
                    margin-bottom: 3px;
                    color: #484848;
                }
                .sub-heading {
                    font-weight: 400;
                }
            }
        }

        .no-messages {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 400px;

            &-text {
                font-size: 15px;
                color: #767a8a;
                font-weight: 700;
            }
        }
    }
}
