.logout-popup {
    position: absolute;
    border: 1px solid #d5d8df;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    top: 40px;
    width: max-content;
    display: flex;
    justify-content: center;
    right: 0;
    z-index: 9;
    flex-direction: column;
    padding-top: 6px;
    color: #333;
    min-width: 230px;
    font-size: 14px;
    max-width: 250px;

    span {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        padding: 14px 16px;
        cursor: auto;
    }

    &-acc {
        display: flex;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        padding: 14px 16px !important;
        padding-top: 0 !important;
        border-bottom: 1px solid #d5d8df;
        cursor: auto;

        &-first {
            padding-top: 4px !important;

            &-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 35px;
                min-height: 35px;
                min-width: 35px;
                border-radius: 50%;
                overflow: hidden;
                color: white;
                object-fit: cover;
                padding: 1px;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                background-color: #767a8a;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-second {
            display: flex;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            flex-direction: column;

            h1 {
                padding: 0;
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 4px;

                max-width: 150px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            small {
                font-size: 11px;
                font-weight: 700;
                font-weight: normal;
                color: #767a8a;

                max-width: 150px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    li {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding: 12px 16px;

        &:hover {
            background-color: #d5d8df;
        }
    }
}

.logout-profile-image {
    display: flex;
    align-self: baseline;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    border-radius: 50%;
    overflow: hidden;
    color: white;
    object-fit: cover;
    padding: 1px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    background-color: #767a8a;
    font-size: 12px;

    img {
        width: 100%;
        height: 100%;
    }
}
