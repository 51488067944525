.title-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1e1e1e;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;
  width: calc(100% - 10px);
  
  &-past {
    cursor: pointer;
    padding-bottom: 3px;
  }
  
  &-upcoming {
    cursor: pointer;
    padding-bottom: 3px;
    margin-right: 30px;
  }
  .selected-events {
    border-bottom: 2px solid #365de6;
  }

  @media (max-width: 550px) {
    margin-left: 16px;
  }
}

.empty-payload-events{
  color: #484848;
  font-weight: 600;
  font-size: 16.38px;
  text-align: center;
  margin: 50px 0;
}


.past-events-month{
  width: calc(100% - 10px);
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1e1e1e;
  margin-bottom: 6px;
}


.events-loader {
  width: 100%;
  @media (max-width: 550px) {
    width: 100vw;
    margin: 0;
  }
}

.user-profile-events-loader-container {
  width: calc(100% - 6px) !important;
  margin: 0 auto;
}

.user-event-container {
  display: flex;
  padding: 30px 28px;
  box-sizing: border-box;
  height: 133px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #d5d8df;
  border-radius: 4px;
  width: calc(100% - 10px);
  margin: 0 auto;
  position: relative;
  margin-bottom: 24px;

  @media (max-width: 550px) {
    margin-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 16px;
  }

  &-date {
    flex: 20%;

    @media (max-width: 550px) {
      margin-bottom: 16px;
    }

    .event-date {
      display: flex;
      flex-direction: column;
      width: 110px;

      @media (max-width: 550px) {
        align-items: flex-start;
        width: 100%;
      }

      .date-days {
        line-height: 44px;
        font-size: 36px;
        text-transform: capitalize;
        color: #333333;
        font-weight: 700;
      }
      .date-month {
        color: #8b8c91;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
      }
    }

    .event-date-dual-month {
      display: flex;
      align-items: center;
      border-radius: 5px;
      width: 160px;
      position: relative;

      .to-hyphen {
        line-height: 44px;
        font-size: 36px;
        text-transform: capitalize;
        color: #333333;
        font-weight: 700;
        margin-bottom: 20px;
        position: absolute;
        right: 90px;
      }
    }
  }

  &-details {
    flex: 70%;
    display: flex;

    .event-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .event-name {
        color: #33353b;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        width: 80%;
        text-align: left;

        @media (max-width: 550px) {
          width: 100%;
          margin-bottom: 16px;
        }

        &:hover {
          color: #365de6;
        }
      }

      .event-time {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #767a8a;
        margin-top: 12px;

        @media (max-width: 550px) {
          margin-top: 0;
        }
      }
    }
  }
  .fresh-event {
    background: rgba(54, 93, 230, 0.15);
    border: 1px solid #d5d8df;
    border-radius: 4px;
    padding: 4px 8px;
    gap: 10px;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    color: #365de6;
    line-height: 16px;
    width: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 24px;

    @media (max-width: 550px) {
      position: absolute;
      top: 20px;
      right: 16px;
    }
  }
}
