@media (min-width: 1440px) {
    .messages-content {
        &-body.newgroup {
            .messages-content-body-wrapper {
                margin: 0 80px 15px 80px;
                .new-message-menu {
                    .search-members-dropdown { 
                        right: 50px !important;
                        &:before {
                            right: 18px;
                        }
                        &:after {
                            right: 18px;
                        }
                    }
                }
            }
        }
    }    
}
@media (min-width: 1600px) {
    .messages-content {
        &-body.newgroup {
            .messages-content-body-wrapper {
                margin: 0 150px 15px 150px;
                .new-message-menu {
                    .search-members-dropdown { 
                        right: 80px !important;
                        &:before {
                            right: 18px;
                        }
                        &:after {
                            right: 18px;
                        }
                    }
                }
            }
        }
    }    
}
@media (max-width: 1370px) {
    .messages-content {
        &-body.newgroup {
            .messages-content-body-wrapper {
                margin: 0 70px 15px 70px;
                .group-members { 
                    grid-template-columns: 1fr 1fr 1fr;
                }
                .new-message-menu {
                    .search-members-dropdown { 
                        right: 50px !important;
                    }
                }
            }
        }
    }    
}
@media (max-width: 1024px) {
    .messages-content {
        &-body.newgroup {
            padding: 25px 20px;
            .messages-content-body-wrapper {
                margin: 0 90px 15px 90px;
                .group-members { 
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
    }    
}
@media (max-width: 900px) {
    .messages-content {
        &-body.newgroup {
            padding: 25px 20px;
            .messages-content-body-wrapper {
                margin: 0 70px 15px 70px;
                .group-members { 
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }    
}

@media (max-width: 765px) {
    .newgroup-form {
        &-group {
            width: 90%;
            margin: 0 auto !important;
            margin-bottom: 10px !important;
        }
    }
}

@media (max-width: 420px) {
    .messages-content {
        &-body.newgroup {
            .messages-content-body-wrapper {
                .group-members { 
                    grid-template-columns: 1fr;
                    .search-members-dropdown {
                        &:before {
                            right: 148px;
                        }
                        &:after {
                            right: 149px
                        }
                    }
                }
            }
        }
    }    
}





