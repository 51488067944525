.comment-wrap-full-box {
  max-width: 695px;
}
.post-comment-input {
  display: flex;
  flex-direction: row;
  margin-top: -8px;
  padding: 10px 35px 20px 35px;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  background-color: #ffffff;
  border: none;
  box-shadow: none !important;

  &--reply {
    padding: 10px 0 !important;
  }

  &--channel-editmode {
    padding-top: 0 !important;
  }

  &--editmode {
    padding: 10px 0;
    box-shadow: none !important;
    position: relative;

    .cancel-comment-edit {
      cursor: pointer;
      margin-left: 5px;
    }

    .DraftEditor-root {
      @media(max-width: 550px) {
        margin-left: 0 !important;
        margin-right: 5px !important;
        padding-top: 5px;
      }
    }
  }

  &--courses-section{
    padding: 10px 15px 20px 15px;

    
  }

  .user-component {
    width: 35px;
    margin-right: 11px;
    margin-top: 4px;
    align-items: flex-start;
  }

  .comment-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    background-color: #F2F4F7;;
    min-width: 422px;
    min-height: 53px;
    cursor: text;
    width: calc(100% - 80px);
    padding: 10px 0;

    &-lecture-view {
      min-width: unset;
    }
  
    &.level {
      &-1 {
        padding-left: 5px;

        @media(max-width: 768px) {
          padding-left: 0px;
        }
        
      }

      &-2 {
        padding-left: 10px;
      }

    }
    .post-draft-image-button-container{
      @media(max-width: 550px) {
        padding-left: 12px;
      }
    }

    .post-draft-gif-button-container {
      @media(max-width: 550px) {
        padding-left: 14px;
      }
    }
    .file-button-container{
      @media(max-width: 550px) {
        padding-left: 18px;
      }
    }
    .inline-gif-icon{
      width: 30px;
      color: #888;
    }
    .inline-image-icon{
      width: 30px;
      color: #888;
    }

    .comment-action-input {
      cursor: pointer !important;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      flex-direction: row;
      margin-right: 10px;
      margin-left: auto;
      height: 23px;
      line-height: 23px;
      background-color: #365de6;
      color: #ffffff;
      height: 30px;
      // width: 50px;
      margin-top: 20px;
      border-radius: 4px;
      padding: 10px 14px;

      &.focused-editor {
        // margin-top: 25px;
      }
      
    }
    .empty-comment{
      background-color: #888;
      cursor: not-allowed !important;
    }

    .not-empty-comment{
      cursor: pointer !important;
    }

  }

  .DraftEditor-root {
    flex: 1 1 auto;
    cursor: text;
    margin-left: 15px;
    margin-right: 11px;
    overflow: hidden;
    color: #1c1e21;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    line-height: normal;
    
    @media(max-width: 550px) {
      z-index: 0;
    }

    &:focus {
      border-color: #365de6;
      color: black;
    }

    .public-DraftStyleDefault-block {
      margin: 0 !important;
    }
  }
}

.selected-mention {
  background-color: transparent !important;
  color: #365de6 !important;
}

.avatar-image {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 6px;
}

.mention-no-avatar {
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 100%;
  margin-right: 6px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #ffffff;
  font-weight: bold;
  position: relative; 
}

.profile-image-avatar {
  align-items: center;
  margin-right: 6px;
  margin-bottom: -4.5px;
  height: 20px !important;
  width: 20px !important;
  min-width: 23px;
  min-height: 23px;
  border-radius: 50%;
  overflow: hidden;
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  padding: 4px 5px;
}

.PostEditor__container {
  background-color: transparent;
  transition: all 0.1s ease-out;
  width: calc(100% - 20px);
  margin-right: 10px;
  margin-left: auto;

  @media(max-width: 550px) {
    width: calc(100% - 10px) !important;
  }
}
.PostEditor {
  border: 0;
  box-sizing: border-box;
  font-size: 15px;
  outline: none;
  resize: none;
  width: 100%;

  &.level {
    &-1, &-2 {
      // padding-top: 10px;
    }
  }
}

.PostEditor__input {
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 15px;
  outline: none;
  resize: none;
  width: 100%;


  .DraftEditor-root {
    margin-left: -3px !important;
    
    span[data-text="true"] {
      font-size: 15px !important;
    }
    @media(max-width: 550px) {
      margin-left: 8px !important;
    }

    .public-DraftEditor-content {
      border: 1px solid #F1F4F7;
    }
  }

  .public-DraftEditorPlaceholder-root {
    font-size: 15px !important;
    margin-left: 1px;
  }

  &__editmode {
    .DraftEditor-root {
      padding-bottom: 10px;
    }
  }
}

.course-section-level{

  &-1{
   width: 85% !important;
   min-width: unset !important;
   
   @media (max-width: 1024px) {
     
     width: 100% !important;
    }
  }
  
  &-2{
    width: 82% !important;
    min-width: unset !important;
    @media (max-width: 1024px) {
   
      width: 100% !important;
     }
   }

  &-undefined{
    // width: 83.6% !important;
    @media (max-width: 1024px) {
   
      width: 100% !important;
     }
  }

}