.editor {
    position: relative;

    figure {
        // border-radius: 8px;
        margin: 0;
        display: flex;
        // margin-top: 6px;
        margin-block-start: unset;
        margin-block-end: unset;
        margin-inline-start: unset;
        margin-inline-end: unset;
        margin-bottom: 2px;
        margin-top: 2px;

        img {
            width: 140px;
            height: 140px;
            object-fit: cover;
            border-radius: 8px;
        }
    }
}

.comment-gif-wrapper {
    position: absolute;
    z-index: 999;
    bottom: 9px;
    margin-left: -20px;
    @media (max-width: 550px) {
        margin-left: -56px;
    }
}

.comment-file-attachment {
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    height: auto;
    margin-top: 10px !important;

    &::-webkit-scrollbar {
        display: none;
    }

    &-wrapper {
        display: flex;
        width: 180px;
        border-radius: 8px;
        padding: 4px;
        justify-content: space-between;
        border: 2px solid #dfdfdf;
        margin-right: 10px;


        .comment-file-attachment-details {
            display: flex;
            align-items: center;

            .comment-file-attachment-details-icon {
                width: 20px;
                color: #888;
                height: 20px;
                margin-right: 12px;
            }

            &-body {
                display: flex;
                flex-direction: column;
                margin-right: 12px;
                justify-content: space-between;
                font-size: 13px;
                font-weight: 200;

                &-title {
                    max-width: 100px;
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: relative;

                    .title-text {
                        white-space: nowrap; /* prevent the title from wrapping */
                        text-overflow: ellipsis; /* add an ellipsis at the end of the text if it overflows */
                        overflow: hidden; /* hide any overflow */
                    }
                }
            }

            .comment-file-attachment-delete-icon {
                cursor: pointer;
                svg {
                    width: 22px;
                    color: #888;
                    height: 22px;
                }
            }
        }
    }

    .add-comment-file-attachment {
        display: flex;
        border-radius: 14px;
        border: 2px dotted #dfdfdf;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        padding: 6px 80px;

        .add-file-icon {
            color: #bab9b9;
            width: 34px;
            height: 34px;
            cursor: pointer;

            @media (max-width: 550px) {
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }

            &:hover {
                background-color: #bab9b9;
                color: #ffffff;
                border-radius: 100%;
            }
        }
    }
}

.postable-draft {
    &-mention {
        &-suggestions {
            &-container {
                border: 1px solid #d5d8df;
                background-color: #ffffff;
                border-radius: 4px;
                min-width: 250px;
                max-height: 200px;
                overflow-y: auto;
                z-index: 2147483638;
            }
        }

        &-text {
            background-color:#D6E7FF !important;
            color: #365de6;
            padding: 1px;
            border-radius: 1px;
        }
    }
}