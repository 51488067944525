.admin-label {
  width: fit-content;
  background-color: #eff0f2;
  padding: 0 6px;
  border-radius: 4px;
  font-size: 13px;
  margin-top: 4px;
  font-weight: lighter;
  color: #66676c;
  cursor: pointer;
  margin: 0;
  margin-left: 4px;
  display: flex;
  align-items: center;

  svg {
    width: 11px;
    margin-right: 4px;
  }
  
  .icon {
    width: 16px;
    height: 16px;
    svg {
      margin-bottom: -3px;
      width: 16px;
      height: 16px;
    }
  }
}
