.reacters {
    &-container {
        width: 100%;
        height: 100%;
    }

    &-header {
        &-container {
            position: relative;
            border-bottom: 1px solid #F2F4F7;
            height: 60px;
            display: flex;
            align-items: center;
            padding-left: 10px;
        }

        &-title {
            margin: 0;
            padding: 0;
             
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: #333333;
        }

        &-x-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }

    &-content {
        &-container {
            height: calc(100% - 60px);
            border-top: none;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-empty {
            &-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-text {
                margin: 0;
                padding: 0;

                 
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 15px;
                color: #333333;
            }
        }
    }
}