.postable-editor-footer {
    &-container {
        width: 96%;
        margin: 0 auto;
        min-height: 60px;
        height: fit-content;
        box-shadow:  inset 0 2px 2px -2px rgba(0, 0, 0, 0.08);
        display: flex;
        justify-content: space-between;
        padding: 5px;
        align-items: center;
        // @media(max-width: 550px) {
        //     flex-direction: column;
        //     height: fit-content;
        //     padding-bottom: 10px;
        //     align-items: flex-start;
        // }

        &.hidden {
            display: none;
        }
    }

    &-actions {
        &-container {
            display: flex;

            span {
                cursor: pointer;
                &:not(:first-child) {
                    margin-left: 15px;
                }
            }
        }
    }

    &-submit {
        height: 34px;
        background-color: #365DE6;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 20px;

         
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        // @media(max-width: 550px) {
        //     margin-top: 10px;
        // }


        &--icon {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
        }

        &-disabled {
            background-color: #D5D8DF !important;
        }

        &-hidden {
            display: none;
        }
    }
}