@media (max-width: 767px) {
  .new-signup-logo {
    img {
      margin: 15px auto 10px;
    }
  }
}
@media (max-width: 630px) {
  .new-verification-content {
    width: calc(100% - 10px);
    margin: 5px auto 20px;
  }
}
@media (max-width: 550px) {
  .new-verification-content {
    padding: 15px;
  }
}
@media (max-width: 350px) {
  .new-signup-accordion {
    span {
      font-size: 11px;
    }
    svg {
      margin: 0 5px!important;
    }
  }
  .new-verification-content {
    .verification-code {
      max-width: 278px;
      .verification-code-input {
        input {
          width: 41px;
          height: 52px;
        }
      }
    }
  }
}
