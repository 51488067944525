.user-profile-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  width: 688px;
  max-width: 100vw;
  margin-top: 32px;

  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
    // margin: 0 auto;
    // margin: 0 2px;
    margin-left: -3px;
  }

  @media (max-width: 450px) {
    width: 100vw;
    max-width: 100vw;
    // margin: 0 auto;
    // margin: 0 2px;
    margin-left: -2px;
  }

}