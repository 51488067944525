.member-sub-container {
    margin: 20px 0;
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d5d8df;
    z-index: 2;

    @media (max-width: 550px) {
        width: calc(100vw - 16px);
        margin: 20px auto;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 23px;

        @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                flex-direction: row !important;
        }

        h1 {
            padding: 0;
            margin: 0;
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 12px;
        }
    }

    .body-section {
        span {
            margin-bottom: 8px;
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 175% */
        }

        h4 {
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
            padding: 0;
            margin: 0;
            margin-bottom: 12px;
            
            &::first-letter{
                text-transform: capitalize;
            }
        }
    }
}

// .community-styles {
//     width: 391px;

//     // @media (max-width: 550px) {
//     //     width: calc(100vw - 16px);
//     // }
// }