.sidebar-channel-only__wrap {
  height: 100%;
  width: 300px;
  left: -300px;
  position: fixed;
  padding-top: 60px;
  z-index: 9999;
  // border-right: solid 1px #ccc;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;

  // z-index: 1000;
  // position: fixed;
  // height: 100%;
  // padding-top: 50px;
  // transition: width ease-in-out 3s;

  .sidebar-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 26px;
    border-bottom: 1px solid #d5d5d5;
    // margin-left: 16px;
    padding-bottom: 0.7rem;
    text-align: left;
    padding-left: 22px;
    padding-right: 0 !important;

    .invite-members-action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 5px;
      margin-top: 8px;
      // height: 34px;
      // border: 1px solid #767A8A;
      // border-radius: 4px;

      cursor: pointer;

       
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #767A8A;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        margin-right: 5px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .action {
      width: 100%;
      padding-left: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 44px;
       
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #767A8A;

      img {
        margin: 0 5px 0 0;
        width: 14px;
      }
      svg {
        margin: 0 5px 0 0;
        width: 14px;
      }
    }

    .active-courses {
      border-radius: .3rem;
      background-color: #eff0f2;
      color: #333333 !important;
      font-weight: bold;
      font-size: 15px;
      svg {
        fill: #ffffff;
      }
    }
    .items-count-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-left: auto;
      border-radius: 4px;
      color: #ffffff;
       
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      background-color: #767A8A;
      min-width: 24px;
      height: 24px;

      p {
        margin: 0;
        padding: 0;
        color: #ffffff;
        font-size: 11px;
      }
    }
  }

  .invite--group__header-card {
    position: relative;
    padding: 50px;
    .close-modal {
      width: 14px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .rc-collapse-content-box {
    margin: 0;
  }
  .rc-collapse-header {
    outline: none;
    color: #91939e !important;
    letter-spacing: 0.5px;
    padding-left: 5px !important;
    color: #333333 !important;
  }
  .header-parent {
    border: none !important;
  }

  .header-item {
    // background-color: #ffffff;
    border: none !important;
     
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #767A8A;
    background-color: #eff0f2 !important;
    background-color: #F3F4F7 !important;
    
    .rc-collapse-content {
      padding: 0;
      background-color: #eff0f2 !important;
      background-color: #F3F4F7 !important;
    }

    svg {
      margin-right: 10px;
    }
  }
  .sidebar__content-channels-list {
    display: block !important;
    height: 100%;
    // padding: 30px 22px;
    position: relative;
    // background: #fff;
    background-color: #eff0f2 !important;
    background-color: #F3F4F7 !important;
    .group-name-closed {
      width: 100%;
      margin-bottom: 15px;
      text-align: left;
      margin-left: 7px;

       
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }
    .group-name {
      width: 100%;
      margin-bottom: 15px;
      text-align: left;

       
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }
    .sidebar-channel__search-wrap {
      display: none;
      position: relative;
      margin-top: 15px;
      height: 40px;
      .channel-search {
        width: 250px;
        height: 34px;
        position: absolute;
        padding: 8px 30px 8px 15px;
        border: 1px solid #EFF0F2;
        border-radius: 30px;
        background-color: #EFF0F2;
      }
      span {
        z-index: 99;
        position: absolute;
        right: 15px;
        padding-top: 6px;
      }
    }
    .joined-channels {
      height: fit-content;
      margin-top: 10px;
      display: block;
      .public-joined-channels {
        padding: 0 22px;
        // height: calc(100% - 150px);
        // overflow: hidden;
        margin-top: 0.5rem;
        // overflow-y: hidden;
        // padding-bottom: 4rem;
        // &:hover {
        //   overflow-y: auto;
        // }
      
        ul {
          margin-block-end: 0 !important;
        }
      }

      ul {
        height: 100%;
        width: 100%;
        padding: 0;
        overflow-y: hidden;
        margin-top: 0;

        &:hover {
          overflow-y: auto;
        }

        li {
          cursor: pointer;
          width: 100%;
          text-align: left;
          padding: 8px 10px;
          overflow: hidden;
           
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #666666;
          height: 40px;
          display: flex;
          align-items: center;

          &.channel-link {
            // padding-left: 10px;
          }
      
          &.active {
            border-radius: .3rem !important;
            background-color: #eff0f2;
            font-weight: bold;
          }
        }
      }

      .sidebar-actions {
        padding: 0 22px 1rem;
      }

      .title {
        text-align: left;
        height: 16px;
        width: 100%;
        color: #767a8a;
        font-size: 14px;
        line-height: 16px;
        margin: 20px 0 5px;
      }
      ul {
        height: 100%;
        width: 100%;
        padding: 0;
        margin-top: 0;
        li {
          cursor: pointer;
          width: 100%;
          height: 32px;
          text-align: left;
          display: flex;
          align-items: center;
          // padding-left: 10px;
          &.active {
            border-radius: .3rem;
            background-color: #eff0f2;
          }
        }
      }
    }
  }
}
.sidebar-channel-only-wrap-is-open .sidebar-channel-only__wrap {
  left: 0;
}

.sidebar-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-logout {
  &-scroll-area {
    width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  background-color: #eff0f2 !important;
  background-color: #F3F4F7 !important;
  // background-color: #ffffff !important;

  @media(max-width: 550px) {
    padding-right: 22px !important;
  }
  }

  &-header {
    padding: 32px 22px 0;
  }
}

.gp-sidebar-channel-container {
  display: flex; 
  align-items: center;

  svg {
    margin-right: 0 !important;
  }
}

.gp-sidebar-channel-name {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  margin-left: 3px !important;

  &--badge {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;

    @media(max-width: 550px) {
      max-width: 150px;
    }
  }
}

.items-count-container--channel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
  border-radius: 4px;
  color: #ffffff;
   
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  background-color: #767A8A;
  width: 20px;
  min-width: 20px;
  height: 20px;

  p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 10px;
  }
}

.gp-channel-hashtag {
  font-size: 16px;
}

.gp-channel-emoji-container {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}