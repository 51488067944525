.user-profile-body-middle-posts {
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;

  @media (max-width: 550px) {
    margin-left: 19px;
    margin-right: 19px;
  }

  .post-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 12px;
    color: #1e1e1e;
  }
}

.dropdown-wrapper {
  padding: 0;
  margin: 0;
  z-index: 2;
  
  @media (max-width: 550px) {
    width: calc(100vw - 10px);
    margin: 0 4px;
  }


  @media (max-width: 350px) {
    // width: calc(100vw - 12px);
    margin: 0 3px;
  }

  .empty-payload-post{
    color: #484848;
    font-weight: 600;
    font-size: 16.38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropdown-menu {
    &-items {
      position: relative;
      display: flex;
      flex-direction: column;

      .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid #365de6;
        padding: 4px 8px;
        color: #365de6;
        position: absolute;
        bottom: 18px;
        left: 120px;
        z-index: 66;
        width: 112px;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        @media (max-width: 550px) {
          left: 140px;
        }
      }

      .dropdown-menu-items-name {
        position: absolute;
        z-index: 66;
        top: -18px;
        left: 120px;
        width: 350px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #d5d8df;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0989948);
        border-radius: 3px;
        overflow-y: auto;
        list-style: none;

        @media (max-width: 550px) {
          width: fit-content;
          left: 140px;
        }

        .dropdown-item {
          padding-inline: 12px;
          padding-block: 3px;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          color: #33353b;
          text-align: left;
          cursor: pointer;

          &:hover {
            background-color: rgb(216, 216, 216);
            width: 100%;
          }
        }
      }
    }
  }

  .events-loader {
    width: calc(100% - 6px);
    margin: 0 auto;
  }
}

.user-posts-container {
  list-style: none;
  width: 100% !important;
  margin: 0 auto;
  margin-bottom: 24px;
  padding: 0;

  @media (max-width: 550px) {
    margin: 0;
    margin-bottom: 24px;
  }
}
