@import "../../assets/styles/components/button";

.link {
    color: #0000FF;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
     
    font-style: normal;
}
