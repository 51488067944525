.postable-draft {
    &-container {
        min-height: 350px;

        .public-DraftEditor-content{
            h1, h2, h3, h4, h5, h6 {
                margin-block-start: 0;
                margin-block-end: 0;
            }
        }

        .post-GIF-container {
            position: absolute;
            z-index: 99;
            top: 90px;
            right: 0;
        }
        .invite-member-page-post-GIF-container {
            position: absolute;
            right: 0;
            top: 43%;
            left: 50%;
            right: auto;
            bottom: auto;
            transform: translate(-50%, -50%);
            z-index: 90;

            @media (max-width: 550px) {
                position: absolute;
                right: 0;
                top: 43%;
                left: 60%;
                right: auto;
                bottom: auto;
                transform: translate(-50%, -50%);
                z-index: 90;
            }
        }

        &--fullscreen {
            min-height: calc(100vh - 200px);

            @media (max-width: 550px) {
                min-height: calc(100vh - 270px);
            }
        }

        &--readonly {
            border-top: 1px solid #d5d8df;
        }

        //editor box
        .DraftEditor-root {
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;

            div[data-block=true] {
                font-size: 16px !important;
            }

            .public-DraftEditorPlaceholder-root {
                width: fit-content !important;
                font-size: 20px !important;
                margin-top: -4px !important;
            }

            .public-DraftStyleDefault-block {
                margin: 0 !important;
                line-height: 27px !important;
            }

            .public-DraftStyleDefault-ol,
            .public-DraftStyleDefault-ul {
                padding-left: 20px !important;
                font-size: 16px !important;
            }

            .public-DraftStyleDefault-ol {
                li::before {
                    top: 2.3px !important;
                }
            }

            figure {
                margin-block-start: 0.5em;
                margin-block-end: 0.5em;
                margin-inline-start: 0;
                margin-inline-end: 0;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        //toolbar container
        .t16lpgj {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            background-color: #fff !important;
            box-shadow: none !important;
            border: none !important;
            border-bottom: 1px solid #eff0f2 !important;
            border-top: 1px solid #eff0f2 !important;
            padding: 8px 0 !important;
        }

        //toolbar button
        .bc4rxid {
            background: none;

            &:hover {
                background: #fbfbfb;
            }
        }

    }

    &-linkify-text {
        color: #365de6;
    }

    &-link {
        &-input {
            width: 87%;
            height: 50px;
            border: none;
            padding-left: 5px;
            margin-left: 5%;
        }

        &-text {
            color: #365de6;
            &:hover {
                color: #365de6;
                cursor: pointer;
            }
        }
    }

    &-editor {
        &-cover {
            &-container {
                margin-top: 10px;
                margin-left: 15px;
                display: flex;
                align-items: center;
                width: fit-content;
                cursor: pointer;
                color: #9fa1a1;
                transition: 0.5s;
                opacity: 1;

                &.hidden {
                    opacity: 0;

                    @media (max-width: 550px) {
                        opacity: 1;
                    }
                }
            }

            &-portal {
                &-container {
                    .cropper-container {
                        z-index: 2147483647 !important;
                    }
                }
            }

            &-image {
                &-container {
                    width: calc(100% - 30px);
                    height: 200px;
                    margin: 0 auto;
                    border-radius: 4px;
                    margin-top: 20px;
                    position: relative;
                }

                &-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 4px;
                }

                &-action {
                    &-container {
                        position: absolute;
                        top: 40%;
                        left: 35%;
                        opacity: 1;
                        transition: 0.5s;

                        &.hidden {
                            opacity: 0;

                            @media (max-width: 550px) {
                                opacity: 1;
                                left: 20%;
                            }
                        }
                    }

                    &-button {
                        border: 1px solid #ffffff;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
                        background-color: #ffffff;
                        color: #333333;
                        border-radius: 4px;
                        height: 35px;
                        padding: 0 25px;

                        &:hover {
                            border: 1px solid #365de6;
                            color: #365de6;
                        }

                        &.spaced {
                            margin-left: 10px;
                        }
                    }
                }
            }

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    path {
                        fill: #9fa1a1;
                    }
                }
            }

            &-label {
                margin: 0;
                padding: 0;
                margin-left: 5px;
                color: #9ea1a1;
            }
        }

        &-title-input {
            border: none;
            outline: none;
            width: 100%;
            padding: 10px 0;
            padding-left: 10px;

            font-size: 2em;
            // font-size: 16px;
            line-height: 30px;
            font-weight: bold;
            color: #333333;
            padding-right: 10px;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: #9fa1a1;
            }
        }

        &-container {
            max-height: calc(100vh - 650px);
            overflow: auto;
            width: 100%;
            overflow-x: hidden;
            min-height: 350px;

            @media (max-width: 991px) {
                span[data-text="true"] {
                    font-size: unset !important;
                }
            }

            &--fullscreen {
                max-height: calc(100vh - 250px);

                @media (max-width: 550px) {
                    max-height: calc(100vh - 320px);
                }
            }
        }
    }

    &-mention {
        &-suggestions {
            &-container {
                border: 1px solid #d5d8df;
                background-color: #ffffff;
                border-radius: 4px;
                min-width: 250px;
                max-height: 200px;
                overflow-y: auto;
                z-index: 2147483638;
            }
        }

        &-text {
            background-color:#D6E7FF !important;
            color: #365de6;
            padding: 1px;
            border-radius: 1px;
        }
    }
}
