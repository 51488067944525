[data-color="1"] {
  background-color: #e96666; }

[data-color="2"] {
  background-color: #138396; }

[data-color="3"] {
  background-color: #58b8c9; }

[data-color="4"] {
  background-color: #388e3c; }

[data-color="5"] {
  background-color: #253125; }

[data-color="6"] {
  background-color: #757575; }

[data-color="7"] {
  background-color: #4dd0e1; }

[data-color="8"] {
  background-color: #00acc1; }

[data-color="9"] {
  background-color: #006064; }

[data-color="0"] {
  background-color: #00b8d4; }

[data-color="A"] {
  background-color: #388e3c; }

[data-color="S"] {
  background-color: #2e7d32; }

[data-color="D"] {
  background-color: #1b5e20; }

[data-color="F"] {
  background-color: #e64a19; }

[data-color="G"] {
  background-color: #bf360c; }

[data-color="H"] {
  background-color: #d32f2f; }

[data-color="J"] {
  background-color: #b71c1c; }

[data-color="K"] {
  background-color: #7b1fa2; }

[data-color="L"] {
  background-color: #4a148c; }

[data-color="Z"] {
  background-color: #303f9f; }

[data-color="X"] {
  background-color: #1a237e; }

[data-color="C"] {
  background-color: #0288d1; }

[data-color="V"] {
  background-color: #01579b; }

[data-color="B"] {
  background-color: #00796b; }

[data-color="N"] {
  background-color: #004d40; }

[data-color="M"] {
  background-color: #616161; }

[data-color="Q"] {
  background-color: #212121; }

[data-color="W"] {
  background-color: #5d4037; }

[data-color="E"] {
  background-color: #3e2723; }

[data-color="R"] {
  background-color: #ffa000; }

[data-color="T"] {
  background-color: #ff6f00; }

[data-color="Y"] {
  background-color: #afb42b; }

[data-color="U"] {
  background-color: #827717; }

[data-color="I"] {
  background-color: #c2185b; }

[data-color="O"] {
  background-color: #880e4f; }

[data-color="P"] {
  background-color: #78909c; }

[data-color="@"] {
  background-color: #9c9c54; }
