.post-draft-image-button {
    &-container {
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 0;
        margin-left: 10px;
        position: relative;

        @media(max-width: 550px) {
            margin-left: 0px !important;
        }

        .inline-image-icon{

            svg{

                position: absolute;
                top: -22px;
                color: #888;
                width: 20px;
                height: 20px;
            }
        }

        // svg {
        //     position: absolute;
        //     top: -18px;
        //     transform: scale(1.5);
        //     path {
        //         fill: #888;
        //     }
        // }
    }
}