.pagination {
    display: flex;

    .center {
        margin: 0 18px;
        position: relative;
        display: inline-block;
        cursor: pointer;

        .page-button {
            height: 32px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #f1f1f1;
            background: #fff;
            margin-right: 10px;
            width: 60px;
            padding: 0 7px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 93%;
            background-position-y: 4px;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .previous {
        color: #333;
        font-size: 13px;
        font-weight: 600;
        line-height: normal;
        font-style: normal;
        cursor: pointer;
    }

    .next {
        color: #333;
        font-size: 13px;
        font-weight: 600;
        line-height: normal;
        font-style: normal;
        cursor: pointer;
    }

    .disable {
        color: #ccc;
        cursor: auto;
    }
}