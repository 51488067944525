body.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.profile-slideout {
    &-container {
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        border-left: 1px solid #d5d8df;
        padding: 10px 0;
        overflow: hidden;

        &.mt {
            &-50 {
                margin-top: 50px;
            }
        }

        &.pt {
            &-50 {
                padding-top: 50px;
            }
        }
    }

    &-menu {
        &-container {
            width: fit-content;
            height: fit-content;
            position: absolute;
            right: 10px;
            cursor: pointer;
        }

        &-icon {
            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                position: relative;
            }

            &-trigger {
                position: absolute;
                right: 0;
            }
        }
    }

    &-header {
        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            padding: 0 10px;
            border-bottom: 1px solid #d5d8df;
        }

        &-title {
            margin: 0;
            padding: 0;

            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #767a8a;
        }

        &-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-content {
        &-container {
            height: calc(100% - 50px);
        }

        &-scrollarea {
            width: 100% !important;
            height: 100% !important;
            border-radius: 0 !important;
            background-color: #ffffff !important;
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 13px;
            padding-bottom: 100px;
        }

        &-card {
            &-container {
                border-radius: 0.5;
                padding: 10px;
                position: relative;
                margin-bottom: 15px;
                overflow: hidden;
            }

            &-title {
                margin: 0;
                padding: 0;

                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 11px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #767a8a;
            }

            &-keyvalue {
                &-container {
                    display: flex;
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }

                &-key {
                    margin: 0;
                    padding: 0;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #767a8a;
                }

                &-value {
                    margin: 0;
                    padding: 0;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #333333;
                    margin-left: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &.capitalize-first-word::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
        }

        &-info {
            &-header {
                &-container {
                    display: flex;
                    margin-bottom: 10px;
                    align-items: flex-start;
                }

                &-wrapper {
                    background-color: #767a8a;
                    width: 81px;
                    height: 81px;
                    border-radius: 100%;
                    padding: 1px;
                }
                &-image {
                    object-fit: contain;
                    // width: 81px;
                    // height: 81px;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }

                &-noimage {
                    width: 81px;
                    height: 81px;
                    color: #ffffff;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 20px;
                    color: #ffffff;
                }

                &-details {
                    &-container {
                        margin-left: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    &-name {
                        margin: 0;
                        padding: 0;

                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: #1e1e1e;
                        width: 200px;
                    }

                    &-title {
                        margin: 0;
                        padding: 0;

                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #767a8a;
                        word-wrap: break-word;
                        margin-top: 8px;
                    }

                    &-title:first-letter {
                        text-transform: uppercase;
                    }

                    &-email {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #767a8a;
                        word-wrap: break-word;
                        margin-top: 8px;
                        width: 200px;
                    }
                }
            }

            &-social {
                &-container {
                    display: flex;
                    margin-top: 10px;
                }

                &-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #365de6;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            &-message{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px ;
                .profile-message-btn {
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 15px;
                    color: #ffffff;
                    padding: 12px;
                    width: 100%;
                    background-color: #365de6;
                    // margin: -20px 0;
                }
                .top-space{
                    // margin-top: 10px;
                }
            }
        }

        &-about {
            &-text {
                margin: 0;
                padding: 0;

                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #666666;
            }
        }

        &-iconscon{
            margin-block: 18px;
            &-icons {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 11px;
                }
    
                &-location {
                    width: 20px;
                    height: 20px;
                    stroke: #767A8A;
                    color: #767A8A;
                    margin-right: 11px;
                }
    
                &-value {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;                   
                    color: #666666;
                }
            }
        }

        &-screening {
            &-container {
                margin-top: 15px;
            }

            &-question {
                &-container {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }

                &-title {
                    margin: 0;
                    padding: 0;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #666666;
                }

                &-answer {
                    &-list {
                        list-style-type: disc;
                        padding-left: 20px;
                        margin-top: 8px;

                        li {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 16px;
                            color: #333333;
                        }
                    }

                    &-text {
                        margin: 0;
                        padding: 0;
                        margin-top: 8px;

                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 21px;
                        color: #333333;
                    }
                }
            }
        }

        &-subscription {
            background-color: #f7f8f9;
            &-title {
                margin: 0;
                padding: 0;
                margin-top: 15px;

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
            }

            &-details {
                &-container {
                    margin-top: 15px;
                }
            }
        }

        &-purchase {
            &-container {
                margin-top: 15px;
            }

            &-title {
                margin: 0;
                padding: 0;
                margin-top: 15px;
                margin-bottom: 10px;

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
            }

            &-see-more {
                margin: 0;
                padding: 0;
                margin-top: 15px;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: #365de6;
                cursor: pointer;
            }
        }
    }
}
