.tippy-actions-wrapper {
  background-color: #ffffff;
  //   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 10px 0;
  min-width: 50px;

  &.hide-tippy-arrow {
    .tippy-arrow {
      display: none;
    }
  }

  .tippy-action-button {
    width: 100%;
    height: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &:hover {
      background-color: #eff0f2;
    }

    &.no-hover-effect {
      &:hover {
        background-color: #ffffff;
      }
    }

    .action-label {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
      color: #333333;
       
    }
  }
}

.tippy-box[data-theme~="light-border"] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-width: 50px;
  position: relative;
  border: 1px solid #e3e3e3;
  border-radius: 3px;

  .tippy-content {
    padding: 0
  }
}

.hide-tippy-arrow {
  .tippy-arrow {
    display: none;
  }
}

