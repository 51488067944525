.post-draft-gif-button {

    &-container {
        cursor: pointer;
        display: inline-block;
        margin: 0;
        padding: 0;
        margin-left: 32px;
        position: relative;

        @media(max-width: 550px) {
            margin-left: 28px !important;
        }

        .gif-icon {
            position: absolute;
            top: -22px;
            color: #888;
            width: 20px;
            height: 20px;
        }
    }
}