.read-more {
  line-height: 18px;
  font-size: 15px;
  letter-spacing: 0.3px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;

  a {
    color: #365de6;
  }
}
.read-more__button {
  display: inline;
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 13px;
  cursor: pointer;
  color: #365de6;
}

.read-more__button:hover {
  text-decoration: underline;
}

.read-more__text--hide {
  max-height: 0;
  font-size: 0;
  opacity: 0;
}

.read-more__text--show {
  max-height: 10em;
  opacity: 1;
  font-size: inherit;
  display: flex;
}

.read-more__text--remaining {
  transition: opacity 440ms ease;
  display: flex;
}
