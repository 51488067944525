@import "../../assets/styles/abstracts/_variables";
@import "../../assets/styles/abstracts/colors";

.sidebar {
  z-index: 90;
  position: fixed;
  height: 100%;
  transition: width ease-in-out 3s;
  padding-top: 50px;

  @media(max-width: 550px) {
    z-index: 99999;
  }

  .sidebar__group-item-link {
    cursor: pointer;
  }

  .close-modal {
    width: 14px;
    position: absolute;
    right: 20px;
    top: 20px;

    svg{
      width: 24px;
      height: 24px;
      color: #666666;
    }
  }

  .sidebar-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin-top: 10px;
    border-top: 1px solid #D5D8DF;
    border-bottom: 1px solid #D5D8DF;
    // margin-left: 16px;
    // padding-bottom: 0.7rem;
    text-align: left;
    // padding-left: 22px;
    // padding-right: 0 !important;

    &-menu-text{
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #1E1E1E;
      padding: 0;
      margin: 0;
      padding: 16px;
      text-transform: uppercase;
    }

    .invite-members-action {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
     padding: 10px 16px;
     padding-bottom: 16px;
      cursor: pointer;
      font-size: 16px;
      // font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: #666;

      svg {
        margin-right: 12px;
      }
    }

    .action {
      width: 100%;
      padding: 10px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      // height: 44px;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #666;

      a {
        display: flex;
        align-items: center;
      }

      img {
        margin: 0 5px 0 0;
        width: 14px;
      }

      svg {
        margin-right: 12px;
        // width: 14px;
      }
    }

    .active-courses {
      border-radius: .3rem;
      background-color: #eff0f2;
      color: #333333 !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.36px;
      text-align: left;
    }

    .items-count-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      margin-left: auto;
      border-radius: 4px;
      color: #ffffff;

      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      background-color: #767A8A;
      min-width: 24px;
      height: 24px;

      p {
        margin: 0;
        padding: 0;
        color: #ffffff;
        font-size: 11px;
      }
    }
  }

  .rc-collapse-content-box {
    margin: 0 !important;
  }

  .rc-collapse-header {
    outline: none;
    // letter-spacing: 0.5px;
    // padding-left: 5px !important;
    color: #1E1E1E !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    // padding-top: 0px !important;

  }

  .header-parent {
    border: none !important;
  }

  .header-item {
    // background-color: #ffffff;
    border: none !important;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.06em;
    color: #767A8A;
    // background-color: #eff0f2 !important;
    background-color: #F3F4F7 !important;
    // background-color: red !important;

    .rc-collapse-content {
      padding: 0;
    }

    svg {
      margin-right: 10px;
    }
  }

  &.new-user {
    .sidebar__wrap {
      padding: 20px 10px 10px;
    }
  }

  .sidebar__wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    height: 100%;

    .sidebar__header {
      height: 50px;
      margin-bottom: 22px;
      border-bottom: 1px solid $light-gray;
      padding: 0 8px;

      .sidebar__header-title {
        font-size: 14px;
        font-weight: bold;
      }

      button {
        opacity: 0;
        color: $primary-color;
      }
    }

    .sidebar__content {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: center;
      justify-content: center;

      .sidebar-content-loader {
        display: flex;
        justify-content: center;

        svg {
          width: 100%;
          max-width: 280px;
        }
      }

      .sidebar-content-loader-closed {
        display: flex;
        justify-content: center;

        svg {
          width: 100%;
          max-width: 70px;
        }
      }

      .sidebar__content-group-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        .sidebar__content-group-list--container {
          height: 100%;
          border-right: 1px solid #d5d5d5;
          width: auto;
          transition: width ease-in-out 3s;
          // overflow: hidden;
          max-width: 300px;
          display: none;

          .sidebar__group-item--container {
            height: 100%;
            // overflow: hidden;

            .sidebar__scrollarea {
              height: calc(100% - 90px);
              // overflow: hidden;

              .infinite-scroll-component {
                overflow-x: hidden !important;

                .btn-create-group-responsive {
                  display: none;
                }
              }

              &:hover {
                overflow-y: auto;
              }
            }
          }

          .sidebar__group-item {
            margin-bottom: 15px;
            padding-left: 12px;
            padding-right: 12px;

            .sidebar__group-item--picture {
              position: relative;

              .group__new-posts {
                position: absolute;
                right: -5px;
                top: -5px;
                background-color: #17b879;
                color: #ffffff;
                width: 17px;
                height: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                border-radius: 50%;
              }

              .sidebar__group-item--picture-box {
                height: 42px;
                width: 42px;
                border-radius: 4px;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: -2px;
                overflow: hidden;

                &.sidebar__group-item--picture-active {
                  border: 2px solid #365de6;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .sidebar__group-item--info {
              padding-left: 15px;
              text-align: left;

              h5 {
                margin: 0;
                font-size: 13px;
                color: #000000;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              .group-link {
                color: #717171;
                font-size: 13px;
                white-space: nowrap;
                width: 220px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }

      .sidebar__content-channels-list {
        height: 100%;
        width: 280px;
        // padding: 32px 0px;
        // background-color: #ffffff;
        // border-right: 1px solid #ccc;

        &--header {
          // padding: 0 22px;
          padding: 40px 22px 0;
          padding-top: 52px;

          @media (max-width: 768px) {
            padding: 40px 22px 0;
           }
        }

        .sidebar-channel__search-wrap {
          display: none;
          position: relative;

          svg {
            position: absolute;
            right: 10px;
            top: 6px;
          }
        }

        .group-name {
          font-size: 18px;
          font-weight: 700;
          line-height: 21.78px;
          text-align: left;
          color: #333333;
          padding-bottom: 8px;

        }

        .group-name-closed {
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
          // margin-left: 20px;


          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          color: #333333;
        }

        .admin-link-closed {
          display: block;
          width: 100%;
          text-align: left;
          height: 15px;
          color: #365de6;
          font-size: 13px;
          line-height: 15px;
          margin-bottom: 23px;
          margin-left: 20px;
        }

        .channel-search {
          width: 100%;
          height: 34px;
          padding: 8px 30px 8px 15px;
          border: 1px solid #D5D8DF;
          border-radius: 30px;
          background-color: #ffffff;
        }

        .joined-channels {
          height: fit-content;
          // border-top: 1px solid #D5D8DF;
          // overflow: hidden;
          // padding: 0 22px;
          // padding-bottom: 2rem;

          // &:hover {
          //   overflow: auto;
          // }

          .title {
            text-align: left;
            height: 16px;
            width: 100%;
            color: #767a8a;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0 5px;
          }

          .sidebar-actions {
            padding: 0 16px;
            padding-left: 0;
            margin-left: 16px;
          }

          .joined-channels__scroll-area {
            padding: 16px 0;
            padding-left: 16px;
            padding-top: 10px;
            // margin-top: 1rem;

            ul {
              margin-block-end: 0 !important;
            }
          }

          ul {
            height: 100%;
            width: 100%;
            padding: 0;
            overflow-y: hidden;
            margin-top: 0;
            background-color: #F3F4F7 !important;

            &:hover {
              overflow-y: auto;
            }

            li {
              cursor: pointer;
              width: 100%;
              text-align: left;
              padding: 10px 16px;
              overflow: hidden;

              // font-style: normal;
              // font-weight: normal;
              // font-size: 14px;
              // line-height: 16px;
              color: #666666;
              // height: 40px;
              display: flex;
              align-items: center;
              border: none !important;

              &.channel-link {
                // padding-left: 10px;
              }

              &.active {
                border-radius: .3rem !important;
                background-color: #eff0f2;
                font-weight: bold;
              }
            }
          }
        }
      }

      .btn-create-group {
        border: 1px solid #365de6;
        background-color: #fff;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 4px;
        height: 43px;
        width: 43px;
        transition: background-color ease-in 200ms;
        padding: 9px;

        span {
          opacity: 0;
          height: 0;
          width: 0;
          font-weight: 600;
        }

        &:hover {
          color: $white;
          background-color: $primary-color;

          path {
            fill: $white;
          }

          span {
            color: $white;
          }
        }
      }
    }
  }
}

.sidebar-is-open {
  .sidebar {
    .sidebar__wrap {
      .sidebar__header {
        transition: visibility 3s ease-in;

        button {
          -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
            width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 1;
          width: auto;
        }
      }

      .sidebar__content {
        .sidebar__content-group-list {
          .sidebar__content-group-list--container {
            .sidebar__group-item {
              .sidebar__group-item--info {
                -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  width 1s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  width 1s cubic-bezier(0.645, 0.045, 0.355, 1);
                opacity: 1;
                width: 239px;
              }
            }
          }
        }

        .btn-create-group {
          display: flex;
          width: auto;
          padding: 0 15px;

          span {
            -webkit-transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
              width 1s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
              width 1s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 1;
            width: auto;
            height: auto;
            color: $primary-color;
            margin-left: 10px;
          }

          &:hover {
            color: $white;
            background-color: $primary-color;

            path {
              fill: $white;
            }

            span {
              color: $white;
            }
          }
        }
      }
    }

    &.new-user {
      width: 297px;
      display: none;
      -webkit-transition: all 0ms ease-in-out;
      -moz-transition: all 0ms ease-in-out;
      -o-transition: all 0ms ease-in-out;
      transition: all 0ms ease-in-out;
    }
  }
}

.res-admin-page {
  .plus {
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .sidebar {
    .sidebar__wrap {
      .sidebar__content {
        .sidebar__content-group-list {
          .sidebar__content-group-list--container {
            .sidebar__group-item {
              .sidebar__group-item--info {
                display: none;
              }
            }
          }
        }
      }

      .sidebar__header {
        .sidebar__header-title {
          display: none;
        }
      }
    }
  }
}

.plus {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.sidebar-top {
  flex-wrap: wrap;
}

.sidebar-emoji {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-scrollable-content {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  background-color: #F3F4F7 !important;
  padding-bottom: 90px;

  @media(max-width: 550px) {
    padding-right: 22px !important;
  }
}

.items-count-container--channel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
  border-radius: 4px;
  color: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  background-color: #767A8A;
  width: 20px;
  min-width: 20px;
  height: 20px;

  p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 10px;
  }
}

.gp-sidebar-channel-container {
  display: flex; 
  align-items: center;
  // border: 1px solid green;

  svg {
    margin-right: 0 !important;
  }
}

.gp-sidebar-channel-name {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  margin-left: 3px !important;

  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 19.36px !important;
  text-align: left !important;
  color: #666 !important;


  &--badge {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
    font-weight: bold;
    color: #333;

    @media(max-width: 550px) {
      max-width: 150px;
    }
  }
}

.gp-channel-hashtag {
  font-size: 16px;
}

.gp-channel-emoji-container {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite-member-details-popup {
    margin: 0;
    border-radius: 4px;
    border: 1px solid #d5d8df !important;
    background: #ffffff;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    .invite--group__header-card {
      position: relative;
      padding: 50px 20px;
      
      .invite-link {
        align-items: center;
        justify-content: center;

        .copy-link-btn {
          margin: 0 !important;
        }

        .invite-input{
          width: 100% !important;
        }
      }

      .close-modal {
        position: absolute;
        right: 20px;
        top: 10px;
  
        svg{
          width: 24px;
          height: 24px;
          color: #666666;
        }
      }
    }

}

.sidebar-actions-padding-bottom{
  padding-bottom: 10px !important;
}

.no-borderBottom{
  border-bottom: none !important;
}