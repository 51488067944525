.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    width: 100%;

    svg {
        margin-bottom: 12px;
    }

    h1 {
        margin: 0;
        padding: 0;
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 8px;
    }

    span {
        color: #333;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 337px;
    }
}
