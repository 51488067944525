.mention-entry {
    &-container {
       padding: 5px;
       border-radius: 4px;
       cursor: pointer;
       background-color: #ffffff;

       &:hover {
        border-radius: 0;
        background-color:#EEF5FF;
       }
    }

    &-name {
        color: #333333;
    }

    &-avatar-image {
        width: 35px !important;
        height: 35px !important;
    }

    &-no-avatar {
        width: 35px !important;
        height: 35px !important;
        font-size: 13px !important;
    }
}