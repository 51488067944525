.gp-post-crd {
    &-container {
      width: 337px;
      min-width: 340px;
      max-width: calc(100vw - 10px);
      height: 320px;
      max-height: 320px;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #D5D8DF;
      position: relative;

      &.hidden-cover {
        height: 140px;
        max-height: 140px;
      }

      @media(max-width: 550px) {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
      }
    }

    &-pinned {
        &-container {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
        }
    }

    &-cover {
        &-container {
            width: 100%;
            height: 169px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #D5D8DE;
            border-radius: 4px 4px 0 0;
            cursor: pointer;
            transition: .5s;

            &:hover {
                opacity: .9;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px 4px 0 0;
        }

        &-no-image {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-content {
        &-container {
            padding: 10px 15px;
            width: 100%;
            height: calc(100% - 170px);
            position: relative;

            &.hidden-cover {
                height: 100%;
            }
        }

        &-menu {
            &-container {
                width: 100%;
            }

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: 0;
                width: fit-content;
            }
        }

        &-title {
            &-container {
                 
                line-height: 30px;
                width: fit-content;
                max-width: 300px;
                cursor: pointer;
                display: block;

                .read-more {
                    span {
                        transition: .5s;
                        font-size: 16px !important;
                        font-weight: bold !important;
                        color: #333333 !important;

                        &:hover {
                            color: #365DE6 !important;
                        }
                    }
                }
            }
        }

        &-author {
            &-container {
                display: flex;
                align-items: center;
                width: fit-content;

                // @media(max-width: 550px) {
                //     flex-direction: column;
                //     align-items: flex-start;
                // }
            }

            &-name {
                margin: 0;
                padding: 0;
                width: max-content;
                color: #868b9a;
                 
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
            }

            &-timestamp {
                margin: 0;
                padding: 0;
                margin-left: 5px;
                color: #868b9a;
                 
                font-size: 12px;
                line-height: 16px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                // @media(max-width: 550px) {
                //     margin-left: 0;
                // }

                span {
                    margin-left: 5px;
                }
            }
        }

        &-reaction {
            &-container {
                display: flex;
                align-items: center;
                width: fit-content;
                position: absolute;
                bottom: 15px;
                left: 15px;
            }

            &-unit {
                &-container {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    cursor: pointer;

                    &.no-margin {
                        margin-left: 0;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            // transform: scale(.8);
                        }
                    }
                }

                &-label {
                    margin: 0;
                    padding: 0;
                    margin-left: 6px;
                }
            }
        }
    }
}

.btn-post-dots {
    span {
      background-color: #65676B !important;
    }
}

.post-dropdown-listview {
    z-index: 0 !important;
    width: fit-content !important;
    margin: 0 !important;
    padding: 0 !important;
}