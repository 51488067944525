@import "../../../assets/styles/components/dropdown";
@import "../../../assets/styles/components/card";

.x-icon-container {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.post-item {
  width: 99%;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #d5d8df !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  background-color: #fff;

  .loading-comments-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin-top: -6px;
    margin-bottom: 6px;
  }

  &-lecture-view {
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
  }

  &-moderation-view {
    margin-right: auto;
    margin-left: 10px;
    max-width: 695px !important;
  }

  &-messaging-view {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin: 0 auto;
    box-shadow: none !important;
  }

  .mentioned-member {
    color: #365de6;
  }

  .pin-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;

    @media (max-width: 550px) {
      width: 98%;

    }
  }
}
.card-post {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 4px !important;

  &-moderation {
    overflow: hidden;
  }

  &.post-card--announcement {
    padding-bottom: 0 !important;
  }

  a {
    color: #365de6;
  }

  .react_tinylink_card {
    display: block;
    height: 200px;
  }

  .react_tinylink_card_media {
    display: block;
    width: 100%;
    height: auto;
  }

  .react_tinylink_card_content_wrapper {
    display: none !important;
  }
}

.card-header {
  padding: 0px 15px;
  // align-items: flex-start !important;

  &.relative {
    position: relative;

    .postable {
      &-container {
        position: absolute;
        // top: 7px;
        right: 40px;
        height: 20px;
        background-color: rgba(213, 216, 223, 0.5);
        border-radius: 4px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;

        @media (max-width: 550px) {
          display: none;
        }
      }

      &-responsive {
        &-container {
          display: none;

          @media (max-width: 550px) {
            display: block;
            margin-top: 2px;
          }
        }

        &-text {
          margin: 0;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;

          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #767a8a;
        }
      }

      &-text {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;

        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        text-align: right;
        color: #767a8a;
      }
    }
  }
}

.card-content-action {
  padding: 0px 15px;

  @media(max-width: 768px) {
    padding: 0px 6px;
  }

  &--no-margin {
    margin-top: 0 !important;
  }

  &--no-border-bottom {
    border-bottom: none !important;
  }

  &--logged-out-view {
    margin-bottom: 14px !important;
    border-bottom: 1px solid #e7e7e7 !important;
  }
}

.card-content-body {
  &.post-content-area {
    margin-top: 5px !important;

    .content--announcement {
      border-radius: 0 0 4px 4px;
      overflow: hidden;
    }
  }

  .post-content-description {
    padding: 0px 15px;
    color: #19283a !important;
    margin-bottom: 0.5rem;
    font-size: 16px !important;

    .public-DraftStyleDefault-ol {
      li::before {
          top: 2px !important;
      }
    }
  }
}

.liked {
  #Desktop-Home-screen-channels {
    fill: red;
  }
}

.post-comments {
  padding: 15px 35px;
  padding-top: 4px;
  padding-bottom: 1px;
  background: white;
  // margin-top: -8px;
  border: none;
  border-radius: 0 0 0.3rem 0.3rem;
  box-shadow: none !important;

  @media (max-width: 768px) {
   margin-top: -8px;
   padding: 15px;
   padding-bottom: 0px;
  }


  div:nth-child(1) {
    margin-top: 0;
  }

  &--commenting-closed {
    margin-top: 0;
  }

  &--courses-section {
    padding: 0 15px;

    .headline {
      max-width: 300px !important;
      width: 300px !important;

      @media (max-width: 1024px) {
        max-width: 180px !important;
        width: 180px !important;
       }
    }

    .first-level-comment-content{
      width: 94% !important;
      max-width: 94% !important;

      @media (max-width: 1024px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .second-level-comment-content {
      width: 93% !important;
      max-width: 93% !important;

      @media (max-width: 1024px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .third-level-comment-content {
      width: 92% !important;
      max-width: 92% !important;

      @media (max-width: 1024px) {
        width: 100% !important;
        max-width: 100% !important;

        .headline {
    
            max-width: 120px !important;
            width: 120px !important;
        }
      }
    }
  }
}

.show-more {
  // padding-left: 48px;
  display: flex;
  // flex-direction: row;
  color: #47484e;

  font-size: 12px;
  line-height: 24px;
  // margin-top: -5px;
  width: fit-content;
  justify-content: center;
  text-align: center;
  
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #838383 !important;
  border: 1px solid #767a8a;
  border-radius: 4px;
  padding: 8px 14px;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 15px;

  &-comments-number{
    margin-left: 8px;

  }

  .btn-link {
    cursor: pointer;
    text-align: left;
  }
}

.microlink_card__content_url {
  span {
    display: none;
  }
}

.join-group-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .close-modal {
    width: 14px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .join-group-container {
    display: flex;
    justify-content: space-between;

    .join-group-left-side img {
      width: 150px;
      padding: 10px;
      margin-top: 35px;
    }

    .join-group-right-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    .join-group-right-side p {
      font-size: 12px;
      text-align: justify;
    }
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.group-post-dropdown-btn {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 4px 0 4px 0.5rem;
  margin: 0;
  // width: 189px;
  width: 100%;

  &--edit-post {
    // display: none;
  }

  &:hover {
    background-color: #eff0f2;
  }

  .sub-text {
    color: #767a8a;

    font-size: 13px;
    line-height: 24px;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .main-text {
    color: #33353b;

    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    margin: 0;
    padding: 4px 0 4px 0;
  }
}

.group-dropdown-post {
  width: 12rem !important;
  z-index: 99999 !important;
}

.dropdown-menu {
  .dropdown {
    li {
      padding: 0px 0px !important;
    }
  }
}

.post-image {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  cursor: pointer;
  height: 100%;

  &--announcement {
    display: block;
    border-radius: 0 0 4px 4px;
  }
}

.post-title {
  color: #33353b;

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.wistia-wrapper {
  margin-top: 1rem;
}

.vimeo-player--group-posts {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 0.5rem;
  background-color: #000;

  &.announcement {
    margin-bottom: 0;
  }

  iframe,
  object,
  frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.yt-player-container--group-posts {
  height: 382px;
  width: 100%;
  outline: none;
  margin-bottom: 0.5rem;

  &--announcement {
    height: 382px;
    width: 100%;
    outline: none;
    margin-bottom: 0.5rem;
    margin-bottom: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
  }

  &--article-video {
    height: 480px;
  }

  .yt-player {
    width: 100%;
    height: 100%;
  }
}

.group-posts-top-margin {
  height: 0.5rem;
  width: 100%;
}

.edit-post-input {
  border: none;
  background-color: transparent;
  &:active &:focus {
    outline: none;
  }
}

.edit-comment-input {
  border: none;
  background-color: transparent;
  display: block;
  margin-bottom: 0.5rem;
  width: 100%;
}

.edit-inputs {
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
}

textarea:focus {
  outline: none !important;
}

.content {
  line-height: 24px;
}

.read-more {
  line-height: 24px;
}

.btn-post-dots {
  span {
    background-color: #65676b !important;
  }
}

.post-content {
  &-attachment {
    &-container {
      display: flex;
      align-items: center;
      height: 72px;
      border: 1px solid #d5d8df;
      border-radius: 4px;
    }

    &-download-link {
      margin: 0 15px;
      color: #888;
    }

    &-details {
      &-container {
      }

      &-name {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 5px;
      }

      &-size {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #767a8a;
      }
    }
  }
}

.post-card {
  &--wrap {
    box-shadow: none !important;
  }

  &--public {
    margin-bottom: 0 !important;
  }

  &--cover {
    padding-top: 0 !important;
  }

  &--hide-bottom-padding {
    padding-bottom: 0 !important;
  }

  &-stats {
    &-container {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      // padding: 10px 15px;
      // padding-top: 0;
      width: 100%;
      justify-content: flex-end;
      color: #65676b;
    font-weight: 600;

    }

    &-like-count {
      margin: 0;
      padding: 0;

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #65676b;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: #365de6;
      }
    }

    &-comment-count {
      margin: 0;
      padding: 0;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
     color: #65676b;
      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 13px;
       }

      &:hover {
        text-decoration: underline;
        color: #365de6;

        @media (max-width: 768px) {
          text-decoration: unset;
          color: unset;
         }
      }
    }
  }

  &-action {
    &-button {
      margin-left: 0 !important;
      margin-right: 16px !important;

      svg{
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      span {
        // color: #65676b;
        color: #65676b;
        font-weight: 600;
        margin-left: 0 !important;
        display: flex;
        align-items: center;
      }
      .post-liked-text{
        color: #365de6;
      }
      &--wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 0 36px;
        padding-left: 0px;
        flex-direction: column;

        &-upper {
          display: flex;
          width: 100%;
        }
        
        &-first{
          // justify-content: space-between;
          display: flex;
          width: 100%;

          .post-card-action-button{

            &:first-child{
              width: 67px;
            }
          }
        }
      }
    }
  }

  &-post {
    &-actions {
      margin-left: 0 !important;
    }
  }
}

.comment-stat {
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
}

.group-post-card {
  &-editor {
    &-container {
      width: 100%;
      height: 100%;

      &--fullscreen {
        width: 60%;
        margin: 0 auto;

        @media (max-width: 550px) {
          width: 100%;
        }
      }
    }
  }
}

.post-user {
  cursor: pointer;
  width: fit-content;
  position: relative;

  .profile-image-container {
    .profile-image {
      height: 40px !important;
      width: 40px !important;
      min-height: 40px !important;
      min-width: 40px !important;
    }
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}

.gp-post {
  &-title {
    font-size: 1.5em;
    line-height: 30px;
    margin-right: auto;
    margin-left: 15px;
    max-width: calc(100% - 30px);
    color: #191b1f;
  }

  &-cover {
    &-container {
      overflow: hidden;
      width: 100%;
      height: 250px;
      margin-bottom: 10px;
    }

    &-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-postable {
    &-preview {
      &-container {
        padding: 0 20px;
        margin: 10px 0;
      }

      &-wrapper {
        background-color: #F2F4F7;
        border-radius: 4px;
        padding: 12px;
      }

      &-title {
        margin: 0;
        padding: 0;
        color: #333333;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.gp-post-commenting-closed-notification {
  &-container {
    width: 100%;
    margin: 0;
    background-color: #fff;
    padding: 10px 0;
    margin-top: -8px;
  }

  &-wrapper {
    width: 90%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f7;
    margin: 0 auto;
    padding: 20px 0;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-label {
    margin: 0;
    padding: 0;
    margin-left: 5px;
  }
}

.gp-post {
  &-embed-attachment-wrapper {
    overflow-x: auto;
    display: flex;
    justify-content: center;
    height: fit-content !important;
    overflow-y: hidden;
    margin-bottom: 10px;


    div:first-child {
      position: relative;
    }

    *:first-child {
      width: 100% !important;
      min-height: 355px !important;

      iframe{
        min-height: 480px !important;
      }
    }
  }
}

// Basic styles for the carousel container
.carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
  .carousel-header {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .carousel-image-container {
    max-width: 80%;
    width: 80%;
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;

    @media (max-width: 550px) {
      max-width: 90%;
      width: 90%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }
  .carousel-buttons-container-right {
    color: #ffffff;
    border-radius: 4px;
    background-color: #dedede;
    margin: 0 18px;
    cursor: pointer;
    right: 0;
    top: 50%;
    position: absolute;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 4px;

    @media (max-width: 550px) {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }
  .carousel-buttons-container-left {
    color: #ffffff;
    border-radius: 4px;
    background-color: #dedede;
    margin: 0 18px;
    cursor: pointer;
    left: 0;
    top: 50%;
    position: absolute;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 4px;

    @media (max-width: 550px) {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  .carousel-buttons-container button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.load-more-comments{
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  color: #65676b;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #D5D8DF;
  border-radius: 4px;
  padding: 8px 16px;

  // margin-top: 10px !important;
  margin-bottom: 12px;

  svg{
    width: 24px;
    height: 24px;
    color: #666;
    margin-right: 8px;
  }
}

.top-like-stat{
  justify-content: flex-start;
 padding: 10px 15px;
}

@media (max-width: 768px) {
  .post-card-action-button {
      font-size: 13px;
}

}