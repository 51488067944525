.user-custom-fields-wrapper {
    // max-width: 688px !important;
    margin-top: 0px;
    height: auto;
    width: 100%;

    @media (max-width: 550px) {
        margin: 0 auto;
        width: calc(100vw - 12px);
    }

    &-question-section {
        border: 1px solid #d5d8df;
        border-radius: 4px;
        padding: 24px 25px;
        background-color: #ffffff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: auto;

        span {
            font-weight: 600;
            font-size: 14px;
            color: #1e1e1e;
            margin-bottom: 12px;
        }

        h4 {
            margin: 0;
            padding: 0;
            margin-bottom: 24px;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            text-align: justify;
        }
    }

    .community-questions-header {
        display: none;
    }

    .community-questions-content-container {
        width: 688px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: none;
        border-radius: 4px;
        margin: 0;
        border: 1px solid #d5d8df;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 7px 40px;

        @media (max-width: 550px) {
            max-width: calc(100vw - 12px) !important;
        }
    }

    .comm-answerable-question-options-textarea {
        width: 568px !important;
        height: 100px !important;

        @media (max-width: 550px) {
            width: 300px !important;
        }
    }

    .community-questions-footer-complete {
        display: flex;
        margin-top: 22px;
        align-items: center;
        justify-self: center;
        border-radius: 4px;
        padding: 10px;
        background: #1e1e1e;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 40px;

        @media (max-width: 550px) {
            margin-bottom: 40px;
            justify-content: flex-end;
            width: fit-content;
        }
    }

    .comm-answerable-question-options-option-label {
        width: 550px;
        @media (max-width: 550px) {
            max-width: 300px;
        }
    }

    .user-custom-field-discard-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 450px;
        margin-bottom: 48px;
        bottom: 0;

        button {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #1e1e1e;
        }

        @media (max-width: 990px) {
            right: 240px;
        }

        @media (max-width: 550px) {
            right: 140px;
        }
    }
}
