.quiz-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-top: 36px;

    &-header {
        display: flex;
        border-radius: 4px;
        border: 1px solid #d5d8df;
        background: #fff;
        display: flex;
        width: 699px;
        padding: 12px 8px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        @media (max-width: 550px) {
            max-width: calc(100vw - 10px);
            margin: 0 auto;
            margin-bottom: 14px;
        }

        .left {
            color: #00f;
            display: flex;
            align-items: center;

            h4 {
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 8px;
            }
        }

        .right {
            h1 {
                margin: 0;
                padding: 0;
                color: #333;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    .head-loader{
        display: flex;
        border-radius: 4px;
        border: 1px solid #d5d8df;
        background: #fff;
        display: flex;
        flex-direction: column;
        width: 699px;
        padding: 12px 8px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        @media (max-width: 550px) {
            max-width: calc(100vw - 10px);
            margin: 0 auto;
        }

        &-results{
            display: flex;
            width: 100%;
            background: #d5d8df;
            margin: 8px 0;
            border-radius: 4px;
        }

        &-body{
            width: 100%;
            border: 1px solid #d5d8df;
            margin-bottom: 8px;
            border-radius: 4px;
        }
    }

    .quiz-body {
        display: flex;
        width: 699px;
        max-width: 100vw;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 4px;
        border: 1px solid #d5d8df;
        background: #fff;


        @media (max-width: 550px) {
            max-width: calc(100vw - 10px);
            margin: 0 auto;
        }

        .top {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 550px) {
                flex-direction: column-reverse;
                align-items: flex-start;
            }

            .custom-user {
                @media(max-width: 550px) {
                    margin-top: 10px;
                }
                .header{
                    .member-profile-img{
                        width: 35px;
                        height: 35px;
                        min-height: 35px;
                        min-width: 35px
                    }

                }
                .right {
                    margin-left: 8px;
                }

                .mini-profile{
                    left: 38px;
                }
            }

            .submitted {
                font-weight: 400;
                font-size: 12px;
                line-height: 14.25px;
                color: #666666;
            }
        }
    }
}

.quiz-results {

    &-admin {
        &-container {
            width: 100%;
            margin-top: 25px;
        }
    }

    &-summary {
        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 16px;

            &.pass {
                background-color: #F1FEF4;
            }

            &.fail {
                background-color: #FFEEEE;
            }

            @media(max-width: 550px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &-left {
            &-container {
                display: flex;
                align-items: center;

                @media(max-width: 550px) {
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                }
            }

            &-status {
                &-container {
                    display: flex;
                    align-items: center;
                }

                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    margin: 0;
                    padding: 0;
                    margin-left: 5px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    color: #333333;
                }
            }

            &-target {
                &-container {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;

                    @media(max-width: 550px) {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }

                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-score {
                    margin: 0;
                    padding: 0;
                    margin-left: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16.41px;
                    color: #333333;

                    &.highlight {
                        font-weight: 700;
                        margin-left: 5px;
                    }

                    &.subtext {
                        font-size: 12px;
                        line-height: 14.06px;
                        color: #666666;
                        margin-left: 5px;
                    }
                }
            }
        }

        &-right {
            &-container {
                display: flex;
                align-items: center;

                @media(max-width: 550px) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 15px;
                    width: 100%;
                }
            }

            &-retakes {
                &-container {
                    display: flex;
                    align-items: center;
                }

                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    margin: 0;
                    padding: 0;
                    margin-left: 5px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 17.58px;
                    color: #333333;

                    &.highlight {
                        font-weight: 700;
                        margin-left: 5px;
                    }

                    &.subtext {
                        font-size: 12px;
                        line-height: 14.06px;
                        color: #666666;
                        margin-left: 5px;
                    }
                }
            }

            &-retake {
                &-action {
                    margin-left: 15px;
                    width: 149px;
                    height: 38px;
                    border-radius: 4px;
                    border: 1px solid #1E1E1E;

                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18.75px;

                    @media(max-width: 550px) {
                        margin-left: 0;
                        margin-top: 15px;
                        width: 100%;
                    }
                }
            }
        }
    }

    &-answers {
        &-container {
            border: 1px solid #D5D8DF;
            border-radius: 4px;
            padding: 24px;
            margin-top: 25px;
        }

        &-question {
            &-container {
                width: 100%;

                &.margin-top {
                    margin-top: 20px;
                }
            }

            &-title {
                &-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &-text, &-count {
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16.41px;
                    color: #333333;
                }

                &-count {
                    display: none;
                }
            }

            &-description {
                margin: 0;
                padding: 0;
                margin-top: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 16.41px;
                color: #666666;
            }

            &-options {

                &-option {
                    &-container {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #D5D8DF;
                        border-radius: 4px; 
                        padding: 20px;
    
                        &.highlighted {
                            border: 1px solid #365DE6;
                        }

                        @media(max-width: 550px) {
                            padding: 10px;
                        }
                    }

                    &-left {
                        &-container {
                            display: flex;
                            align-items: center;
                            
                            &.max-width {
                                max-width: 60%;
                            }
                        }
    
                        &-text {
                            margin: 0;
                            padding: 0;
                            margin-left: 10px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19.6px;
                            color: #333333;
                        }
                    }

                    &-right {
                        &-container {
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            padding: 4px;

                            &.correct {
                                background-color: #EEFFF0;
                            }

                            &.wrong {
                                background-color: #FFEEEE;
                            }
                        }

                        &-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        &-text {
                            margin: 0;
                            padding: 0;
                            margin-left: 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #333333;
                        }
                    }
                }
            }
        }
    }

    &-question {
        &-warning {
            &-container {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #FFF7F0;
                padding: 4px 8px;
                width: fit-content;

                @media(max-width: 550px) {
                    width: 100%;
                }
            }

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-text {
                margin: 0;
                padding: 0;
                margin-left: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #333333;
            }
        }
    }
}
