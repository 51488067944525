.post-comment {
  display: flex;
  width: auto;
  height: auto;
  max-width: 695px;
  word-break: break-word;
  margin: 1rem 0;
  margin-bottom: 0;
  align-items: center;
  position: relative;
  align-items: flex-start;

  &:hover {
    .comment-options-container {
      opacity: 1 !important;
    }
  }

  .dropdown {
    right: -18px !important;
    top: 28px !important;
  }

  .info-component {
    display: flex;
    align-items: center;

    .name {
      color: #33353b;
      font-size: 13px;
      letter-spacing: 0;
      font-weight: bold;
    }

    .timestamp {
      color: #9d9d9d;

      // font-size: 10px;
      letter-spacing: 0;
      margin-left: 5px;

      svg {
        width: 5px;
        font-size: 5px;
        margin-right: 5px;
        margin-left: 1px;
        vertical-align: middle;
      }
    }

    .period {
      background-color: #868b9a;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      margin-left: 4px;
    }
  }

  a {
    color: #365de6;
  }

  .comment-header {
    width: 35px;
    cursor: pointer;

    .mini-profile {
      top: 4px;

      @media (max-width: 768px) {
        left: 40px;
      }
    }

    .dropdown-menu {
      .dropdown {
        width: 138px;
        padding: 5px 0;
        right: -13px;
        &:before {
          border-width: 6px;
          right: 9px;
        }
        &:after {
          border-width: 5px;
          right: 10px;
        }
        li {
          text-align: left;
          padding: 0;
          justify-content: left;
          color: #33353b;

          .btn-link {
            padding: 5px 15px;
          }
        }
      }
    }
  }

  .comment-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
    color: #19283a;
    white-space: pre-line;
    font-size: 14px;
    line-height: 18px;
    -webkit-font-smoothing: antialiased !important;
    position: relative;

    ul {
      list-style: unset !important;
      list-style-type: disc !important;
      margin: 1em 0 !important;
      padding-left: 2.5em !important;
    }

    .options {
      position: absolute;
      top: 6px;
      right: 16px;
    }

    .comment-options-container {
      opacity: 0;
      @media (max-width: 550px) {
        opacity: 1;
      }
    }

    .comment-content-background {
      border-radius: 4px;
      width: 95%;
      max-width: 95%;
      padding: 10px;
      // padding-top: 5px;
      // margin-left: 30px;
      background-color: #d5d8df40;
      margin-bottom: 5px;

      .headline {
        max-width: 400px;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #838383;
        margin-bottom: 6px;

        @media (max-width: 768px) {
          max-width: 180px;
          width: 180px;
        }

        &-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 12px;
        }
      }
    }
  }
}

.main-post-comment {
  position: relative;
}

.mentioned-member {
  background-color: #d6e7ff !important;
  color: #365de6 !important;
  padding: 1px !important;
  border-radius: 1px !important;
}

// .read-more {
//   user-select: text;
//   white-space: normal;
//   overflow-wrap: break-word;
//   font-size: 15px !important;
//   line-height: 18px;
// }

// .comment-markup-container {
//   -webkit-font-smoothing: antialiased !important;
//   user-select: text;
//   white-space: normal;
//   overflow-wrap: break-word;
//   font-size: 15px !important;
//   line-height: 18px;
//   outline: none !important;
//   user-select: text !important;
//   white-space: pre-wrap !important;
//   overflow-wrap: break-word !important;

//   figure {
//     margin: 0;
//     padding: 0;
//     margin-block-start: unset;
//     margin-block-end: unset;
//     margin-inline-start: unset;
//     margin-inline-end: unset;
//     display: flex;
//     // margin-bottom: 6px;
//     margin-bottom: 2px;
//     margin-top: 2px;

//     img {
//       width: 140px;
//       height: 140px;

//       border-radius: 8px;
//       object-fit: cover;
//       // margin-bottom: 6px;
//     }
//   }
// }

.comment-file-content {
  padding: 0px 15px;
  color: #19283a !important;
  margin: 10px 0;
  margin-left: -14px;

  &-container {
    display: flex;
    align-items: center;
    height: 62px;
    border: 1px solid #d5d8df;
    border-radius: 4px;

    @media (max-width: 768px) {
      padding: 5px 0px;
      height: auto;
    }

    &-download-link {
      margin: 0 15px;
    }

    &-details {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 18px;

      &-name {
        font-weight: bold;
        color: #333333;
        margin-bottom: 5px;
      }

      &-size {
        font-size: 14px;
        color: #767a8a;
      }
    }
  }
}

.comment-actions {
  &-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    padding: 0 45px;
    padding-right: 40px;
    flex-direction: column;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 600;
    color: #65676b !important;

    @media (max-width: 768px) {
      padding-right: 10px;
    }

    &-upper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      justify-content: space-between;
    }

    &-lower {
      display: flex;
      align-items: center;
      // margin-bottom: 10px;
      width: fit-content;
      justify-content: flex-start;
      margin-right: auto;
      margin-top: 5px;

      span {
        padding: 0;
        margin: 0;
        // font-size: 14px;
        // font-weight: 600;
        // color: #666;
        cursor: pointer;
      }
    }

    &-first {
      display: flex;
    }
  }

  &-action {
    &-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .liked-comment-p {
        color: #365de6;
        // color: #d5d8df;
        // font-weight: 600;
      }

      &.like {
        margin-right: 6px;
        width: 70px;
        display: flex;
        justify-content: flex-start;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-count {
      margin: 0;
      padding: 0;
      // margin-left: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #65676b;
      cursor: pointer;

      &.like-count {
        &:hover {
          text-decoration: underline;
          color: #365de6;
        }
      }
    }
  }

  .comment-liked {
    font-weight: 700;
  }
}

.comment-replies {
  &-container {
    &.first-level {
      padding-left: 45px;
    }

    &.second-level {
      padding-left: 50px;
    }
  }
}

.single-comment-reply {
  &-container {
    position: relative;
  }

  &-guide {
    position: absolute;
    width: 25px;
    height: 70px;
    border-left: 1px solid #d5d8df;
    border-bottom: 1px solid #d5d8df;
    border-radius: 0 0 0 4px;
    top: -45px;
    left: -30px;
  }
}
.third-level-guide {
  left: -35px;
  width: 35px;
}
.single-comment-reply-main-guide {
  position: absolute;
  // width: 14px;
  // background-color: #33353b;
  // height: 100%;
  border-right: 1px solid #d5d8df;
  // border-bottom: 1px solid #d5d8df;
  border-radius: 0 0 0 4px;
  top: 10px;
  left: 15px;
}

.single-comment-reply-main-guide-2 {
  position: absolute;
  // width: 14px;
  // background-color: #33353b;
  // height: 600px !important;
  border-right: 1px solid #d5d8df;
  // border-bottom: 1px solid #d5d8df;
  border-radius: 0 0 0 4px;
  top: 10px;
  left: 15px;
}

.single-comment-reply-main-guide::after {
  content: "";
  clear: both;
  display: none;
  background-color: #333333;
  width: 40px;
}

.load-more-comment-replies {
  margin: 0;
  padding: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #365de6;
  cursor: pointer;
  width: fit-content;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #65676b;
  margin-top: 4px;
  margin-bottom: 12px;
}

.comment-popup-actions-menu-icon {
  span {
    background-color: #65676b !important;
  }
}

.comment-options-container {
  position: relative;

  .comment-popup-actions-menu-trigger {
    position: absolute;
    bottom: 0;
    right: -10px;
  }
}

.second-level-comment-content {
  width: 94% !important;
  max-width: 94% !important;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.third-level-comment-content {
  width: 93% !important;
  max-width: 93% !important;

  @media (max-width: 768px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.tippy-arrow {
  display: none;
}

.gp-comment-wrapper {
  position: "relative";
  border: 1px solid red;

  &-main-guide {
    position: absolute;
    width: 1px;
    height: 70px;
    border-left: 1px solid #d5d8df;
    left: 55px;
    z-index: 100;
  }
}

.comment-draft-content {
  .DraftEditor-root {
    .public-DraftStyleDefault-block {
      margin: 0 !important;
      line-height: 18px !important;
    }

    figure {
      margin-block-start: unset !important;
      margin-block-end: unset !important;
      margin-inline-start: unset !important;
      margin-inline-end: unset !important;
      width: 140px;
      height: 140px;
      margin-bottom: 2px !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}

.maxWidthSearchPage {
  max-width: 100% !important;
  width: 100% !important;
}
