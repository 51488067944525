@import "../../../assets/styles/components/card";
@import "../../../assets/styles/components/button";

.dashboard-card {
  width: 99% !important;
  margin: 0 auto !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border: 1px solid #d5d8df !important;
  box-shadow: none !important;
}

.card {
  h3 {
    cursor: pointer;
  }
  .card-recommendation {
    .card-recommendation--action button {
      color: #365de6;
    }
  }
}

.auto-height {
  height: auto;
  min-height: 34px;
  padding: 10px;
}

.group-description {
  @media(max-width: 550px) {
    margin-right: -100px !important;
  }
}

.card-recommendation--content-image {
  background-color: #ffffff !important;
  outline: none !important;
  border: none !important;
}
