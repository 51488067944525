@media (max-width: 1150px) {
  #master {
    &.res-no-sub-nav {
      main#site-content .main-wrap.empty-user-groups .content-wrap {
        width: 100% !important;
        left: 0 !important;
      }
    }

    // main#site-content .main-wrap.empty-user-groups .content-wrap {
    //  width: calc(100% - 70px) !important;
    //  left: 70px !important;
    // }
  }

  #master main#site-content .main-wrap.sidebar-is-open.empty-user-groups .content-wrap {
    padding-left: 0;
  }

  #master {
    &.res-no-sub-nav {
      main#site-content {
        .main-wrap.empty-user-groups {
          .content-wrap {
            .kolona {
              .plan-selection-head {
                margin-left: 70px;
              }

              .plan-selection-wrapper {
                width: calc(100% - 70px);
                margin-left: 70px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #master {

    // main#site-content .main-wrap .content-wrap .content-page-wrap {
    //   padding: 5px;
    // }
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
      font-size: 16px !important;
    }

    .card.card--group-header {
      &.is--not__invited {
        // margin-top: 0;
      }
    }
  }

  #master {
    &.res-no-sub-nav {
      main#site-content {
        .main-wrap.empty-user-groups {
          .content-wrap {
            .kolona {
              .plan-selection-head {
                margin-left: 0;
              }

              .plan-selection-wrapper {
                width: 100%;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #master {
    .card {
      &.card-post {
        padding: 15px;
      }

      &.card-recommendation {
        padding: 15px;
      }
    }

    .post-comment-input {
      padding: 10px 15px 20px 15px;
    }

    // .post-comments {
    //   padding: 15px;
    // }
  }
}

// @media (max-width: 550px) {
//   #master main#site-content .main-wrap .content-wrap {
//     padding-top: 5px !important;
//   }
// }