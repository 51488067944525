.not-found-container-logged-out {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  @media(max-width: 550px) {
    margin-top: 40px;
  }

  .not-found-content {
    height: 345px;
    width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding-top: 5rem;
    margin-top: 30px;

    &.box-shadow-none {
      box-shadow: none;
    }

    @media(max-width: 550px) {
      width: calc(100vw - 10px);
      margin-top: unset;
    }

    img {
      height: 101px;
      width: 101px;
    }

    .heading {
      color: #000000;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .sub-heading {
      width: 100%;
      color: #767a8a;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      margin: 0.5rem;
    }

    .not-found-link {
      color: #365de6;
      font-size: 15px;
      line-height: 24px;
      font-weight: bold;
    }
  }
}

.not-found-container-logged-in {
  display: flex;
  justify-content: center;
  align-items: center;
   margin-top: 0;

  @media(max-width: 550px) {
    margin-top: 40px;
  }

  .not-found-content {
    height: 345px;
    width: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding-top: 5rem;
    margin-top: 30px;

    &.box-shadow-none {
      box-shadow: none;
    }


  @media(max-width: 550px) {
    width: calc(100vw - 10px);
    margin-top: unset;
  }

    img {
      height: 101px;
      width: 101px;
    }

    .heading {
      color: #000000;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .sub-heading {
      width: 100%;
      color: #767a8a;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      margin: 0.5rem;
    }

    .not-found-link {
      color: #365de6;
      font-size: 15px;
      line-height: 24px;
      font-weight: bold;
    }
  }
}

.not-found-wrapper{
  height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
}