.gif-grid-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 40px;
  width: 350px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 99;
  padding-bottom: 6px;

  @media(max-width: 550px) {
    width: 300px;
  }

  .gif-grid {
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
  }

  .powered-by-gif{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding-top: 10px;

    .powered-by-gif-icon{
      width: 110px;
      object-fit: cover;
      color: #9FA1A1;
    }
  }

  .giphy-search-input {
    border: 1px solid #9FA1A1;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.close-icon{
  color: #ffffff;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  cursor: pointer;
  background-color: #9FA1A1;
  border-radius: 100%;
  margin-bottom: 10px;
}

