.community-questions {
    &-container {
        width: 100%;
        border-radius: 4px;

        @media (max-width: 768px) {
            max-width: calc(100vw - 30px);
        }
    }

    &-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        h1 {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            color: #333;
            margin: 0;
            padding: 0;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #666;
        }
    }

    &-content {
        &-container {
            width: 986.02px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1px solid #d5d8df;
            padding: 40px;
            border-radius: 4px;

            @media (max-width: 768px) {
                padding: 25px 20px;
                max-width: calc(100vw - 30px);
                padding: 0;
                padding-left: 34px;
            }

            li {
                padding: 16px;

                @media (max-width: 768px) {
                   padding-left: 4px;
                }
            }
        }
    }

    &-footer {
        &-container {
            width: 100%;
            display: flex;
            align-items: center;
        }


        &-complete {
            display: none;
            margin-left: auto;
            margin-right: 0;
            height: 34px;
            padding: 0 20px;
            background-color: #365de6;
            transition: 0.5s;
            border-radius: 4px;

            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            color: #ffffff;

            @media (max-width: 768px) {
                padding: 0;
                width: 100%;
            }
        }
    }
}
