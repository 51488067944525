.new-post-editor {
    &-container {
        width: 100%;
        margin: 0 auto;
        height: fit-content;
        background: #FFFFFF;
        overflow: hidden;
        position: relative;
    }

    &-fullscreen-toggle {
        position: absolute;
        right: 15px;
        top: 65px;
        cursor: pointer;

        @media(max-width: 550px) {
            display: none;
        }
    }

    &-header {
        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 10px 0;

            h2 {
                margin: 0;
                padding: 0;
                color: #000000 !important;
            }

            span {
                position: absolute;
                right: 10px;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }

    &-broadcast {
        &-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 0;
            padding-right: 20px;
        }

        &-label {
            margin: 0;
            padding: 0;
             
            font-size: 12px;
            font-weight: bold;
            color: #717070;
        }

        &-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

#postable-editor-file-upload-input {
    display: none;
}

.postable-editor {
    &-modal {
    }
}