$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../assets/styles/abstracts/_variables";
@import "../../assets/styles/components/dropdown";

.header-settings {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 17px;
  line-height: 17px;
  color: #ffffff;

  &.dropdown-menu {
    .menu-dots-container {
      padding: 0px 10px 0px 10px;
      height: 10px;
    }

    .comments-view {
      span {
        background-color: #65676B;
      }
    }
    .dropdown {
      width: 149px;
      padding: 10px 0;
      right: -17px;
      top: 26px;
      &:before {
        border-width: 11px;
      }
      &:after {
        border-width: 10px;
      }
      li {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 0;
        justify-content: left;
        color: #33353b;

        .btn-link {
          padding: 5px 15px;
        }
      }
      .hide-link {
        display: none;
      }
    }
  }

  svg:not(:first-child) {
    margin-left: 8px;
    font-size: 10px;
    line-height: 10px;
  }
}

.upgrade {
  margin-left: 0.5rem;
  background-color: #365de6;
  color: #ffffff;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
}

.width {
  width: 200px !important;
}
