.signup-closed-container {
    box-sizing: border-box;
    width: 695px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px;
    height: 243px;
    margin-top: 40px;
    justify-content: center;
    margin-left: -300px !important;

    @media (max-width: 550px) {
        margin-left: 0 !important;
    }

    &-header {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #1e1e1e;
    }

    &-subtext {
        margin-top: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #767a8a;
    }
}
