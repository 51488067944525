.bio-container {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d5d8df;
    z-index: 2;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        justify-content: flex-start;

        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            flex-direction: row !important;
        }

        h1 {
            padding: 0;
            margin: 0;
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 12px;
        }
    }

    .body-section {
        span {
            margin-bottom: 8px;
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 175% */
        }

        h4 {
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            margin-top: 4px;
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
        }
        .copy {
            display: flex;
            align-items: center;

            .copy-icon-wrapper {
                display: flex;
                margin-left: 4px;
                cursor: pointer;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .copy-bio {
            display: flex;
            align-items: flex-start;

            svg {
                width: 18px;
                height: 18px;
            }
            .bio-details {
                max-width: 99%;
                .read-more {
                    span {
                        display: block;
                        color: #333 !important;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 150% */
                    }
                }
            }

            .settings-max-width {
                max-width: 330px;
            }
        }
    }
}

.admin-mgtop {
    margin: 20px 0;
    // width: 391px;

    @media (max-width: 550px) {
        width: calc(100vw - 16px);
        margin: 0 auto;
    }
}

.unavailble{
    display: none;
}
