.reacter {
    &-container {
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding: 5px 0;
        padding-left: 10px;
    }

    &-avater {
        &-container {
            width: 32px;
            height: 32px;
            min-width: 32px;
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
        }

        &-no-image {
            width: 100%;
            height: 100%;
            color: #ffffff;
             
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
    }

    &-name {
        margin: 0;
        padding: 0;
        margin-left: 10px;

         
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        color: #333333;
    }
}