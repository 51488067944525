@import "../../assets/styles/abstracts/variables";
@import "../../assets/styles/components/modal";
@import "../../assets/styles/components/button";
@import "../../assets/styles/layout/forms";

.modal {
  &.home-acc__login {
    max-width: 450px;
    padding: 0;
    padding-top: 20px;
    overflow: hidden;
    margin-top: 100px;
    border: none;
    border-radius: .3rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

    .home--acc__login-media {
      width: 450px;
      position: relative;
      display: none;

      .quote {
        position: absolute;
        top: 12%;
        color: #ffffff;
        letter-spacing: 0.2px;
        line-height: 24px;
        padding: 0 2.5rem;
        text-align: left;
         
        font-size: 19px;
        line-height: 28px;

        .author {
          font-weight: bold;
          letter-spacing: 0;
          font-size: 16px;
        }
      }

      img {
        width: 100%;
        height: 500px;
        // object-fit: cover;
        display: block;
      }
    }
    .home--acc__login-component {
      max-width: 450px;
      width: 100%;
      height: 100%;
    }

    h1 {
      color: $black;
      font-size: 24px;
      font-weight: 900;
      line-height: 24px;
      text-align: center;
    }

    p {
      color: $grey;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      margin: 0;
    }

    .error-message {
      height: 30px;
      width: 100%;
      max-width: 340px;
      border: 1px solid #f53f5e;
      border-radius: 5px;
      background-color: #fce1e5;
      margin-top: 20px;
      margin-top: -1px;
      margin-bottom: -29px;
      p {
        color: #f53254;
        font-size: 13px;
      }
    }

    form {
      margin-top: 30px;
      max-width: 340px;
      width: 100%;

      button {
        margin-top: 20px;
      }

      .link-group {
        a {
          font-size: 13px;
        }
      }
    }

    .modal-footer {
      text-align: center;
      font-size: 14px;
      margin-top: 10px;
      max-width: 300px;
      color: #767a8a;

      a {
        margin-left: 3px;
        color: #365de6;
      }
    }
  }
}

.gp-login-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .authentication-container {
    height: fit-content;
  }

  .gp-login-logo {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 180px;

    img{
      width: 100%;
      object-fit: cover;
    }
  }
}
