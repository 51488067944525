.user-profile-body-wrapper {
  position: relative;
  border: 1px solid #d5d8df;
  border-radius: 4px;
  padding-top: 40px;
  padding-left: 40px;
  // padding-right: 40px;
  padding-bottom: 0;
  background-color: #ffffff;
  width: calc(100% - 10px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;

  @media (max-width: 550px) {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    // margin-top: 32px;
  }

  .user-profile-body {
    display: flex;
    margin-bottom: 68px;

    @media (max-width: 550px) {
      margin: 0;
    }

    .user-profile-image-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 25px;

      .user-profile-image-wrapper {
        width: 106px;
        height: 106px;
        border-radius: 50%;
        background-color: #929292;
        padding: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        .no-image-text {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #ffffff;
        }

        .user-profile-image {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          box-shadow: 0 0 1px transparent;
        }
      }

      .edit-photo-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #365de6;
        cursor: pointer;
      }
    }

    .user-profile-desc-container {
      display: flex;
      flex-direction: column;

      .user-profile-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .user-profile-header-name-title {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          
          .user-profile-full-name {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #1e1e1e;
            margin-bottom: 12px;
            width: 230px;
          }

          .user-profile-job-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #929292;
            text-transform: capitalize;
          }
        }

        .user-profile-header-links {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 14px;
          padding-left: 4px;
          padding-top: 3px;
          width: 30%;
          margin-right: 40px;
       
          .user-profile-header-links-icon {
            color: #6384fa;
            width: 30px;
            height: 30px;
            scale: 1.3;
          }

          @media (max-width: 550px) {
            margin-top: 20px;
          }
        }
      }

      .user-profile-body-bio {
        margin-top: 16px;

        .user-profile-bio {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #666666;
        }

        @media (max-width: 550px) {
          display: none;
        }
      }
    }
  }

  .user-profile-body-bio-small-device {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;

    .user-profile-bio-small-device{
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #666666;
    }

    @media (min-width: 550px) {
      display: none;
    }
  }

  .user-profile-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    @media (max-width: 550px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 0px 4px;
      gap: 16px;
    }

    .active-tab {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #828282;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
    }

    .active-tab-selected {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      background: #edf1ff;
      border-bottom: 2px solid #365de6;
      border-radius: 4px 4px 0px 0px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1e1e1e;
    }

    .active-icon {
      color: #365de6;
      line-height: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }

    .icon {
      color: #828282;
      display: flex;
      width: 24px;
      height: 24px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }

    @media (max-width: 550px) {
      .user-profile-footer-text-tabs {
        display: none;
      }
    }

    @media (max-width: 550px) {
      .user-profile-footer-text-tabs-selected {
        display: flex;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;
      }
    }
  }
  .content-loader-profile-header {
    border-radius: 4px;
    padding-right: 120px;
    width: 588px;
    height: 140px;
  }
}
@media (max-width: 550px) {
  .content-loader-profile-header {
    display: none;
  }
}

.content-loader-profile-header-small {
  border-radius: 4px;
  width: 288px;
  height: 140px;
  @media (min-width: 650px) {
    display: none;
  }
}
