.access-restricted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .heading {
      color: #000000;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-top: 3rem;
      margin-bottom: 0rem;
    }

    .sub-heading {
      width: 100%;
      color: #767a8a;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      margin: 0.5rem;
    }
  
    .not-found-link {
      color: #365de6;
      font-size: 15px;
      line-height: 24px;
      font-weight: bold;
    }
}