// body.stop-scrolling {
//     height: 100%;
//     overflow: hidden;
// }
.messages {
    &-wrapper {
        position: relative;
        height: 100% !important;
        padding-bottom: 60px !important;

        @media (max-width: 550px) {
            width: calc(100% - 4px);
            padding-bottom: 90px !important;
        }

        .link__to-group {
            color: #365de6;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 26px;
            cursor: pointer;
            right: 20px;
            top: 7px;
            position: absolute;

            .icon {
                color: #666;
                width: 28px;
                height: 28px;
                display: block;
            }

            @media (max-width: 550px) {
                right: 10px;
            }
        }

        .members-new-profile {
            background-color: #fff;
            border-left: 1px solid #d5d8df;
        }
    }
    &-container {
        border: none;
        background: #fff;
        border-radius: 0.3rem;
        display: flex;
        height: 100%;
        overflow: hidden;
        margin-top: 10px;

        @media (max-width: 550px) {
            margin-top: 30px;
        }

        &.active-profile {
            .messages-content {
                width: 100%;
            }
            .members-new-profile {
                margin-right: 0;
            }
        }
        &-nochat-view {
            width: 100%;
            align-items: center;
            .chats-icon {
                width: 100px;
            }
            .noactive-chats {
                text-align: center;
                margin-top: 20px;
                h3 {
                    margin: 0;
                    color: #33353b;

                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                }
                p {
                    margin: 0;
                    padding: 0;
                    color: #33353b;

                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }

    &-content {
        width: calc(100% - 241px);
        height: 100%;
        overflow: hidden;
        &:hover {
            overflow: overlay;
        }
        &-header {
            padding: 12px 25px;
            border-bottom: 1px solid #d5d8df;
            b {
                color: #33353b;

                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                display: block;
                margin: 0 0 7px;
            }

            .header-title {
                text-align: center;
                font-size: 22px;
                line-height: 26px;
                margin-top: 50px;
            }
            .header-subtitle {
                color: #33353b;
                font-size: 13px;
                text-align: center;
                letter-spacing: 0;
                line-height: 15px;
            }
            input {
                border-radius: 17px;
                background-color: #f5f6fa;
                width: 100%;
                border-radius: 100px;
                height: 34px;
                // color: #84878E;

                font-size: 12px;
                letter-spacing: 0;
                border: 0;
                padding: 0 15px;
                outline: none;
            }
            &-message {
                display: flex;
                align-items: center;
                img {
                    margin: 0 10px 0 0;
                }
            }
            &-wrap {
                b {
                    color: #33353b;

                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 14px;
                    margin: 0;
                }
                a {
                    color: #365de6;

                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 14px;
                    margin: 0;
                }
            }
        }
        &-body {
            padding: 25px 20px;
            height: calc(100% - 145px) !important;
            width: 100% !important;
            background: none !important;
            &-wrap {
                &:not(:last-child) {
                    margin: 0 0 22px;
                }
                b {
                    color: #33353b;

                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0;
                    display: block;
                    margin: 0 0 7px;
                }
            }
            &-wrapper {
                margin: 0 100px 15px 100px;
                label {
                    font-size: 13px;
                    line-height: 24px;
                    letter-spacing: 0;
                    color: #33353b;
                    display: block;
                    .added {
                        float: right;
                    }
                }
                input {
                    box-sizing: border-box;
                    border: 1px solid #d5d8df;
                    border-radius: 4px;
                    background-color: #ffffff;
                    width: 100%;
                    color: #84878e;

                    font-size: 12px;
                }
            }
            &-single {
                border: 1px solid #d5d8df;
                border-radius: 5px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
                padding: 10px;
                display: flex;
                align-items: center;
                img {
                    margin: 0 18px 0 0;
                }
                b {
                    color: #33353b;

                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    margin: 0;
                }
            }
            &-messages {
                position: relative;
                height: 100% !important;
                overflow: hidden;
                // margin-right: 8px;
                padding: 0 8px 10px 8px;
                background: none !important;
                width: 100% !important;
                &:hover {
                    overflow-y: auto;
                    &.scrollbar-visible {
                        padding-right: 0px;
                        @supports (-moz-appearance: none) {
                            padding-right: 8px;
                        }
                    }
                }
                &-header {
                    position: relative;
                    padding: 0 0 10px;
                    &:before {
                        content: "";
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        top: 12px;
                        background: #e5e5e5;
                        z-index: 1;
                    }
                    span {
                        display: block;
                        border: 1px solid #e5e5e5;
                        padding: 4px 20px;
                        border-radius: 30px;

                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 14px;
                        display: table;
                        margin: 0 auto;
                        color: #33353b;
                        background: #fff;
                        z-index: 2;
                        position: relative;
                    }
                }
                &-single {
                    display: flex;
                    padding: 10px;
                    border-radius: 5px;
                    position: relative;
                    &:hover {
                        background-color: #f5f6fa;
                        .messages-content-body-messages-single-wrap {
                            .hover-buttons {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                    &:not(:last-child) {
                        margin: 0 0 15px;
                    }
                    &-wrap {
                        width: 100%;
                        .hover-buttons {
                            border: 1px solid #d5d8df;
                            border-radius: 5px;
                            background-color: #ffffff;
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                            position: absolute;
                            right: 15px;
                            padding: 5px 10px;
                            top: -14px;
                            display: none;
                            img {
                                margin: 0;
                                cursor: pointer;
                                // &:nth-of-type(1) {
                                //     margin: 0 10px 0 0;
                                // }
                                &.trash {
                                    margin: 0 0 0 10px;
                                }
                            }
                        }
                        b {
                            color: #33353b;

                            font-size: 14px;
                            font-weight: bold;
                            letter-spacing: 0;
                            display: block;
                            margin: 0 0 5px;
                            span {
                                color: #7c808f;

                                font-size: 11px;
                                letter-spacing: 0;
                                line-height: 18px;
                            }
                        }
                        p {
                            color: #33353b;

                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 18px;
                            margin: 0;
                            white-space: pre-line;
                            a {
                                color: #405cc1;
                            }
                        }
                    }
                    img {
                        margin: 0 18px 0 0;
                    }
                }
            }
        }
    }
}
