.message-menu {

    &-top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        &-text {
            font-size: 14px;
            font-weight: 700;
            color: #484848;
        }

        .write-icon {
            color: #666;
            width: 24px;
            height: 24px;
            display: block;
        }
    }
}

.group-dropdown{
    margin-right: 10px !important;
    margin-top: 4px !important;
    overflow: hidden;
}
.dropdown {
    cursor: pointer !important;
}