.custom-container {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d5d8df;

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        justify-content: flex-start;

        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1024px) 
        and (orientation: portrait) 
        and (-webkit-min-device-pixel-ratio: 1) {
            flex-direction: row !important;
        }

        &-icon{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #EEF5FF;
            border-radius: 2px;
        }

        h1 {
            padding: 0;
            margin: 0;
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 12px;
        }
    }

    .body-section {
        span {
            margin-bottom: 8px;
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 175% */
        }

        h4 {
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            margin-top: 4px;
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 150% */
        }

        .copy{
            display: flex;
            align-items: flex-start;
            width: 100%;
            .custom-details {
                .read-more {
                    max-width: 330px;
                    span {
                        color: #333 !important;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 150% */
                    }
                }
    
            }
            .new-copy-icon {
                margin-left: 4px;
                display: flex;
                cursor: pointer;
                align-items: flex-end;
                margin-top: 4px;
                svg {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }
    }
}

.admin-mgtop {
    margin: 20px 0;
    // width: 391px;

    @media (max-width: 550px) {
        width: calc(100vw - 16px);
    }
}

.unavailble{
    display: none;
}
