dialog {
    margin: 0;
}

.back-people-button {
    color: #365de6;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 22px;

    svg {
        color: #365de6;
        margin-right: 8px;
    }
}

.admin-details-container {
    background: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;

    &-tab {
        display: none;

        .member-tabs-details {
            width: 100%;
            display: flex;
            padding: 7px 8px;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            background: #f2f4f7;
            margin-bottom: 27px;

            div {
                display: flex;
                align-items: center;
                flex: 1/2;
                width: 100%;
                justify-content: center;
                cursor: pointer;
                padding: 10px;

                &:last-child {
                    display: none;
                }
                h1 {
                    margin: 0;
                    padding: 0;
                    color: #666;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    z-index: 99;
                }
            }

            .active {
                display: flex;
                align-items: center;
                border-radius: 4px;
                background: #fff;
                flex: 1/2;

                h1 {
                    color: #1e1e1e;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    &-body {
        display: flex;

        @media (max-width: 550px) {
            flex-direction: column;
        }

        @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                flex-direction: column;

        }
    }

    &-left {
        margin-right: 20px;
        flex: 33.33%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &-right {
        display: flex;
        flex-direction: column;
        flex: 66.66%;
        width: 100%;

        .member-tabs-details {
            display: none;
            padding: 4px;
            padding: 10px 8px;
            align-items: center;
            border-radius: 4px;
            background: #f2f4f7;
            margin-bottom: 27px;

            div {
                display: flex;
                align-items: center;
                flex: 1/2;
                width: 100%;
                justify-content: center;
                cursor: pointer;
                padding: 10px;
                h1 {
                    margin: 0;
                    padding: 0;
                    color: #666;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    z-index: 99;
                }
            }

            .active {
                display: flex;
                align-items: center;
                border-radius: 4px;
                background: #fff;
                flex: 1/2;

                h1 {
                    color: #1e1e1e;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .overview-body {
            &-figures {
                display: flex;
                width: 100%;
                justify-content: space-around;
                flex-wrap: wrap;

                .figures-container {
                    display: flex;
                    padding: 16px 20px;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 4px;
                    background: #fff;
                    border: 1px solid #d5d8df;
                    width: 210px;
                    flex: 1;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;

                        @media (max-width: 1400px) {
                            margin-top: 10px;
                        }

                        @media (max-width: 550px) {
                            margin-right: 4px;
                            margin-top: unset;
                        }
                    }

                    @media (max-width: 550px) {
                        width: 100%;
                        padding: 4px;
                        margin-right: 4px;
                    }

                    .icon-con {
                        display: flex;
                        align-items: center;
                        flex: 66.66%;
                        width: 100%;

                        .icon {
                            margin-right: 8px;
                        }

                        @media (max-width: 550px) {
                            display: none;
                        }

                        h1 {
                            padding: 0;
                            margin: 0;
                            // margin-left: 8px;
                            color: #333;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    span {
                        color: #333;
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;

                        @media (max-width: 550px) {
                            display: none;
                        }
                    }

                    .icon-con-small {
                        display: none;

                        @media (max-width: 550px) {
                            display: flex;
                            margin-right: 4px;
                        }
                    }

                    .icon-con-small-body {
                        display: none;

                        @media (max-width: 550px) {
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                        }

                        h1 {
                            padding: 0;
                            margin: 0;
                            color: #333;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                        span {
                            color: #333;
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            display: none;

                            @media (max-width: 550px) {
                                display: flex;
                            }
                        }
                    }
                }
            }

            &-channels {
                margin: 20px 0;
                display: flex;
                padding: 20px;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                border-radius: 4px;
                background: #fff;
                border: 1px solid #d5d8df;

                .channel-list {
                    list-style: none;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 15px;

                    .channel-body {
                        display: flex;
                        padding: 10px;
                        align-items: flex-start;
                        border-radius: 4px;
                        background: #fafafa;
                        margin-bottom: 8px;
                        margin-right: 8px;
                        flex-direction: column;
                        z-index: 2;

                        .emoji-container {
                            display: flex;
                        }

                        h1 {
                            color: #365de6;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin: 0;
                            padding: 0;
                            margin-left: 4px;
                        }

                        .course-perc-container {
                            padding: 7px 0;
                            display: flex;
                            height: 100%;
                            align-items: center;
                            padding-bottom: 0;

                            .course-perc {
                                border-radius: 50px;
                                width: 100%;
                                height: 8px;
                                background-color: #d5d8df;
                                width: 176px;
                            }
                            .progress {
                                margin-left: 8px;
                                // width: 120px;
                                color: #333;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px; /* 175% */
                            }
                        }
                    }

                    .channel-emoji-container {
                        flex-direction: row;
                    }
                }

                .footer-pagination {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                }

                .items-dialog {
                    box-sizing: border-box;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 24px;
                    border-radius: 4px;
                    border: 1px solid #d5d8df;
                    background: #fff;
                    width: 578px;
                    padding: 32px;
                    z-index: 99 !important;

                    &::backdrop {
                        background: rgba(0, 0, 0, 0.55);
                    }

                    .dialog-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        h1 {
                            margin: 0;
                            padding: 0;
                            color: #343434;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                        span {
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                        }
                    }

                    .dialog-body {
                        margin: 20px 0;

                        h1 {
                            color: #333;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        &-upper {
                            .library-item {
                                display: flex;
                                width: 100%;
                                align-items: flex-start;
                                flex-direction: column;

                                .library-body {
                                    width: 100%;
                                    &-section {
                                        margin-top: 10px;
                                        width: 100%;
                                    }
                                }
                            }

                            .selected-list {
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                margin-top: 14px;

                                div {
                                    display: flex;
                                    background-color: #365de6;
                                    padding: 8px;
                                    border-radius: 4px;
                                    margin-right: 8px;
                                    margin-bottom: 8px;

                                    h1 {
                                        color: #fff;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        margin: 0;
                                        padding: 0;
                                        margin-left: 4px;
                                        margin-right: 8px;
                                    }

                                    span {
                                        display: flex;
                                        cursor: pointer;
                                        svg {
                                            width: 16px;
                                            height: 16px;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        &-lower {
                            margin-top: 20px;
                            .item-list {
                                max-height: 200px;
                                // height: 20%;
                                list-style: none;
                                padding: 16px;
                                display: flex;
                                flex-wrap: wrap;
                                border-radius: 4px;
                                border: 1px solid #d5d8df;
                                overflow: hidden;
                                max-width: 100%;

                                &:hover {
                                    overflow-y: auto;
                                }

                                &::-webkit-scrollbar {
                                    width: 8px; /* Set the width of the scrollbar */
                                    // transition: width 0.3s;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: #aaa; /* Color of the scrollbar thumb */
                                    border-radius: 10px; /* Rounded corners of the scrollbar thumb */
                                }

                                &::-webkit-scrollbar-track {
                                    background-color: #f1f1f1; /* Background color of the scrollbar track */
                                    border-radius: 10px;
                                }

                                .item-body {
                                    display: flex;
                                    padding: 10px;
                                    align-items: center;
                                    border-radius: 4px;
                                    margin-bottom: 8px;
                                    margin-right: 8px;
                                    flex-direction: column;
                                    background: #eef5ff;
                                    height: 40px;

                                    .emoji-container {
                                        display: flex;
                                    }

                                    h1 {
                                        color: #333;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        margin: 0;
                                        padding: 0;
                                        margin-left: 4px;
                                    }

                                    .x-icon {
                                        margin-left: 8px;
                                        display: flex;
                                        svg {
                                            width: 16px;
                                            height: 16px;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .channel-emoji-container {
                                    flex-direction: row;
                                }
                            }
                        }
                    }

                    .footer {
                        display: flex;
                        align-self: center;
                        justify-content: flex-end;

                        button {
                            margin-left: 16px;
                            display: flex;
                            padding: 12px 24px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                        }

                        .save {
                            background: #365de6;
                            color: #fff;
                            text-align: center;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }

                        .discard {
                            border: 1px solid #333;
                            color: #333;
                            text-align: center;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                    .selected-channel {
                        // background-color: #333;
                        display: flex;
                        align-items: center;
                        background: #eef5ff;
                        height: 40px;
                        padding: 4px;
                        height: 34px;
                        border-radius: 4px;

                        .x-icon {
                            display: flex;
                            margin-left: 8px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        .header {
            margin-bottom: 23px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                flex-direction: row !important;
            }

            div {
                display: flex;
                align-items: center;
            }

            h1 {
                margin: 0;
                padding: 0;
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 12px;
            }
        }
    }

    &-noadmin-figures {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex: 1;

        .figures-container {
            display: flex;
            padding: 16px 20px;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            background: #fff;
            border: 1px solid #d5d8df;
            width: 100%;
            flex: 1/3;

            &:not(:last-child) {
                margin-right: 18px;

                @media (max-width: 550px) {
                    margin-right: 4px;
                }
            }

            @media (max-width: 550px) {
                padding: 4px;
            }

            .icon-con {
                display: flex;
                align-items: center;
                // width: 120px;

                @media (max-width: 550px) {
                    display: none;
                }

                h1 {
                    padding: 0;
                    margin: 0;
                    margin-left: 8px;
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            span {
                color: #333;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                @media (max-width: 550px) {
                    display: none;
                }
            }

            .icon-con-small {
                display: none;

                @media (max-width: 550px) {
                    display: flex;
                    margin-right: 4px;
                }
            }

            .icon-con-small-body {
                display: none;

                @media (max-width: 550px) {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                }

                h1 {
                    padding: 0;
                    margin: 0;
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                span {
                    color: #333;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    display: none;

                    @media (max-width: 550px) {
                        display: flex;
                    }
                }
            }
        }
    }

    &-noadmin-channels {
        margin: 20px 0;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #d5d8df;

        .header {
            margin-bottom: 23px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            @media only screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px) 
            and (orientation: portrait) 
            and (-webkit-min-device-pixel-ratio: 1) {
                flex-direction: row !important;
            }

            div {
                display: flex;
                align-items: center;
            }

            h1 {
                margin: 0;
                padding: 0;
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 12px;
            }
        }

        .channel-list {
            list-style: none;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;

            .channel-body {
                display: flex;
                padding: 10px;
                align-items: flex-start;
                border-radius: 4px;
                background: #fafafa;
                margin-bottom: 8px;
                margin-right: 8px;
                flex-direction: column;
                z-index: 2;

                .emoji-container {
                    display: flex;
                }

                h1 {
                    color: #365de6;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                    margin-left: 4px;
                }

                .course-perc-container {
                    padding: 7px 0;
                    display: flex;
                    height: 100%;
                    align-items: center;
                    padding-bottom: 0;

                    .course-perc {
                        border-radius: 50px;
                        width: 100%;
                        height: 8px;
                        background-color: #d5d8df;
                        width: 176px;
                    }
                    .progress {
                        margin-left: 8px;
                        // width: 120px;
                        color: #333;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 175% */
                    }
                }
            }

            .channel-emoji-container {
                flex-direction: row;
            }
        }

        .footer-pagination {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }

        .items-dialog {
            box-sizing: border-box;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 24px;
            border-radius: 4px;
            border: 1px solid #d5d8df;
            background: #fff;
            width: 578px;
            padding: 32px;
            z-index: 99 !important;

            &::backdrop {
                background: rgba(0, 0, 0, 0.55);
            }

            .dialog-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h1 {
                    margin: 0;
                    padding: 0;
                    color: #343434;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                span {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }

            .dialog-body {
                margin: 20px 0;

                h1 {
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                &-upper {
                    .library-item {
                        display: flex;
                        width: 100%;
                        align-items: flex-start;
                        flex-direction: column;

                        .library-body {
                            width: 100%;
                            &-section {
                                margin-top: 10px;
                                width: 100%;
                            }
                        }
                    }

                    .selected-list {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin-top: 14px;

                        div {
                            display: flex;
                            background-color: #365de6;
                            padding: 8px;
                            border-radius: 4px;
                            margin-right: 8px;
                            margin-bottom: 8px;

                            h1 {
                                color: #fff;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin: 0;
                                padding: 0;
                                margin-left: 4px;
                                margin-right: 8px;
                            }

                            span {
                                display: flex;
                                cursor: pointer;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                &-lower {
                    margin-top: 20px;
                    .item-list {
                        max-height: 200px;
                        // height: 20%;
                        list-style: none;
                        padding: 16px;
                        display: flex;
                        flex-wrap: wrap;
                        border-radius: 4px;
                        border: 1px solid #d5d8df;
                        overflow: hidden;
                        max-width: 100%;

                        &:hover {
                            overflow-y: auto;
                        }

                        &::-webkit-scrollbar {
                            width: 8px; /* Set the width of the scrollbar */
                            // transition: width 0.3s;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #aaa; /* Color of the scrollbar thumb */
                            border-radius: 10px; /* Rounded corners of the scrollbar thumb */
                        }

                        &::-webkit-scrollbar-track {
                            background-color: #f1f1f1; /* Background color of the scrollbar track */
                            border-radius: 10px;
                        }

                        .item-body {
                            display: flex;
                            padding: 10px;
                            align-items: center;
                            border-radius: 4px;
                            margin-bottom: 8px;
                            margin-right: 8px;
                            flex-direction: column;
                            background: #eef5ff;
                            height: 40px;

                            .emoji-container {
                                display: flex;
                            }

                            h1 {
                                color: #333;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin: 0;
                                padding: 0;
                                margin-left: 4px;
                            }

                            .x-icon {
                                margin-left: 8px;
                                display: flex;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .channel-emoji-container {
                            flex-direction: row;
                        }
                    }
                }
            }

            .footer {
                display: flex;
                align-self: center;
                justify-content: flex-end;

                button {
                    margin-left: 16px;
                    display: flex;
                    padding: 12px 24px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                }

                .save {
                    background: #365de6;
                    color: #fff;
                    text-align: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .discard {
                    border: 1px solid #333;
                    color: #333;
                    text-align: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .selected-channel {
                // background-color: #333;
                display: flex;
                align-items: center;
                background: #eef5ff;
                height: 40px;
                padding: 4px;
                height: 34px;
                border-radius: 4px;

                .x-icon {
                    display: flex;
                    margin-left: 8px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &-noadmin-left {
        flex: 33.33%;
        width: 100%;
        margin-top: 2px;
    }

    &-noadmin-right {
        display: flex;
        flex: 66.66%;
        width: 100%;
        z-index: 2;

        @media (max-width: 550px) {
            flex-direction: column;
        }

        &-left {
            // flex: 33.33%;
            width: 100%;
            margin: 4px 20px;
            margin-right: 0;

            @media (max-width: 550px) {
                margin: 20px 0;
            }
        }

        &-right {
            flex: 33.33%;
            width: 100%;
        }
    }
}

.admin-details-container-styles {
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #d5d8df;
    flex-direction: row;

    @media (max-width: 550px) {
        padding: 2px;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 30px;
        width: calc(100vw - 10px);
    }
}

.no-course-padding {
    padding: 0;
    border: none;
}

.pop-up-styles {
    background: #fff;
    flex: 1;
    display: flex;
    flex-direction: row;
    border: none;
    padding: 0;

    @media (max-width: 550px) {
        padding: 2px;
        flex-direction: column;
        margin: 0 auto;
        width: calc(100vw - 10px);
    }
}

.people-settings {
    @media (max-width: 550px) {
        width: calc(100vw - 1px) !important;
    }
}

.dropdown {
    width: max-content !important;
}

.no-border {
    border: none;
    padding: 0;
    padding-top: 4px;
}

.dropdown-menu {
    .menu-dots {
        transform: rotate(180deg) !important;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .admin-details-container-styles {
        padding: 2px;
        // flex-direction: column;
        margin: 0 auto;
        margin-top: unset !important;
        width: 100% !important;
    }
}