.group-post-list {
    &-container {
        display: flex;
        height: fit-content;
        overflow: hidden;
        background-color: #ffffff;
        border-top: 1px solid #e7e7e7;
        // margin-top: 20px;
        // border-radius: 4px;
        // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);

        &.topcard {
            border-top: none;
        }
    }

    &-cover {
        &-container {
            width: fit-content;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            cursor: pointer;
            padding: 10px 0 10px 10px;

            &:hover {
                opacity: .8;
            }

            &.hidden-cover {
                display: none;
                padding: 0;
            }
        }

        &-wrapper {
            background-color: #D5D8DE;
            width: 120px;
            height: 85px;
            border-radius: 4px;
            display: flex;
            align-content: center;
            justify-content: center;

            &.hidden-cover {
                background-color: #ffffff;
                width: 80px;
                height: 80px;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
        }

        &-no-image {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-no-cover {
        &-image {
            width: 48px;
            height: 48px;
            min-width: 48px;
            object-fit: cover;
            border-radius: 50%;
            margin: auto;
        }

        &-no-image {
            width: 48px;
            height: 48px;
            min-height: 48px;
            border-radius: 50%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: #ffffff;
        }
    }

    &-content {
        &-container {
            padding-top: 5px;
            padding-right: 10px;
            position: relative;
            width: 100%;

            &.full-width {
                width: 100%;
            }

            @media(max-width: 550px) {
                // width: 60%;
                padding-bottom: 5px;
            }
        }
        &-title {
            &-container {
                margin-left: 10px;
                 
                line-height: 30px;
                width: fit-content;
                max-width: 410px;
                cursor: pointer;
                display: block;

                .read-more {
                    span {
                        transition: .5s;
                        font-size: 16px !important;
                        font-weight: bold !important;
                        color: #333333 !important;

                        &:hover {
                            color: #365DE6 !important;
                        }
                    }
                }

                &.hidden-cover {
                    max-width: 510px;
                }

                @media(max-width: 550px) {
                    max-width: 220px;
    
                    &.hidden-cover {
                        max-width: 350px;
                    }
                }
            }
        }

        &-pinned {
            &-container {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                right: 10px;
                z-index: 99999;

                @media(max-width: 550px) {
                    right: 30px;
                }

                &.hidden-cover {
                    right: 0;
                }
            }
        }

        &-description {
            &-container {
                display:none;
                max-height: 70px;
                overflow: hidden;
                width: 100%;
                max-width: 100%;
                padding-left: 10px;
                margin-top: 5px;

                // mask-image: linear-gradient(180deg, #000 80%, transparent);
                // -webkit-mask-image: linear-gradient(180deg, #000 80%, transparent);

                &.no-title {
                    max-height: 85px;
                }

                @media(max-width: 550px) {
                    max-height: 50px;
                }
            }
        }

        &-footer {
            &-container {
                display: flex;
                align-items: center;
                padding-left: 10px;
                margin-top: 5px;
                margin-bottom: 10px;

                @media(max-width: 550px) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-top: 0
                }
            }

            &-user {
                &-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    width: fit-content;

                    @media(max-width: 550px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }

            &-reaction {
                &-container {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    position: absolute;
                    right: 10px;
                    top: 40%;

                    &.hidden-cover {
                        top: 35%;


                        &-pinned {
                            top: 48%;
                        }
                    }

                    @media(max-width: 550px) {
                        position: unset;
                        top: unset;
                        right: unset;
                        margin-left: 10px;

                        // &.hidden-cover {
                        //     position: absolute;
                        //     right: 10px;
                        //     top: 40%;
                        //     margin-left: 0;
                        // }
                    }
                }
            }

            &-name {
                margin: 0;
                padding: 0;
                color: #868b9a;
                 
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
                max-width: 300px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media(max-width: 550px) {
                    max-width: 200px;
                }
            }

            &-timestamp {
                margin: 0;
                padding: 0;
                margin-left: 5px;
                color: #868b9a;
                 
                font-size: 12px;
                line-height: 16px;

                @media(max-width: 550px) {
                    margin-left: 0;
                }

                span {
                    margin-left: 5px;
                }
            }

            &-unit {
                &-container {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    cursor: pointer;

                    &.no-margin {
                        margin-left: 0;
                    }

                    &.list-view-menu-container {
                        @media(max-width: 550px) {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            // transform: scale(.8);
                        }
                    }
                }

                &-label {
                    margin: 0;
                    padding: 0;
                    margin-left: 6px;
                }
            }
        }
    }
}

.btn-post-dots {
    span {
      background-color: #65676B !important;
    }
}

.post-dropdown-listview {
    z-index: 0 !important;
    width: fit-content !important;
    margin: 0 !important;
    padding: 0 !important;
}