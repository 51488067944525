@media (max-width: 520px) {
    body {
        .messages-wrapper {
            padding: 10px 0px 0px 0px;
            height: calc(100vh - 200px);
    
            .link__to-group {
                padding-left: 5px;
            }
        }
        .messages {
            &-sidebar {
                width: 100% !important;

                ul {
                    li {
                        img {
                            margin: 0 !important;
                        }
                    }
                }
                .group-chats, ul {
                    li {
                        &.active {
                            background-color: #FFFFFF;
                        }
                        a {
                            width: 100%;  
                        }

                        .user-component {
                            .info .name {
                                max-width: 240px;
                            }
                        }

                        &.unread-count {
                            .user-component {
                                .info .name {
                                    max-width: 230px;
                                }
                            }
                        }
                    }
                }
            }
            &-grouped {
                width: 0%;
            }
            &-content {
                width: 0%;
                &-body {
                    padding: 0px 20px 20px 20px;
                    &:fullscreen {
                        height: calc(100% - 220px);
                    }   
                }
                .newgroup {
                    .close {
                        display: none;
                    }
                }
                .messages-input {
                    textarea {
                        &:focus {
                            .messages-content-body {
                                height: calc(100% - 150px);
                            }
                        }
                    }
                }
            }
            &-container {
                &.mobile-view {
                    &.active-members {
                        .messages-content {
                            display: none;
                        }
                        .messages-chat-members {
                            // padding-left: 15px;
                        }
                    }
                    &.active-profile {
                        .messages-content {
                            display: none;
                        }
                        .members-new-profile {
                            .back {
                                margin-left: -230px;
                            }
                        }
                    }
                    .messages-sidebar {
                        width: 0% !important;
                        padding: 0px;
                    }
                    .messages-grouped {
                        width: 100%;
                        .cKQhjW {
                            width: 90%;
                            .messages-add-members-modal {
                                width: 100% !important;
                                .modal__close {
                                    margin-right: 0px;
                                }
                                .search-input {
                                    width: 100%;
                                }
                                &-content {
                                    width: 90%;
                                }
                            }
                        }
                    }
                    .messages-content {
                        width: 100%;
                    }
                }
                .gsztNn {
                    width: 90%;
                    .confirmation-modal {
                        width: 100%;
                    }
                    
                }
            }
        }
    }
}