#master {
  height: 100%;
  position: relative;

  .main__loading {
    background: #365de6;
    display: flex;
    height: 4px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    .bar {
      content: "";
      display: inline;
      width: 0;
      height: 100%;
      left: 50%;
      text-align: center;
    }

    .bar:nth-child(1) {
      background-color: #da4733;
      animation: loading 3s linear infinite;
    }

    .bar:nth-child(2) {
      background-color: #4ee74e;
      animation: loading 3s linear 1s infinite;
    }

    .bar:nth-child(3) {
      background-color: #fdba2c;
      animation: loading 3s linear 2s infinite;
    }

    @keyframes loading {
      from {
        left: 50%;
        width: 0;
        z-index: 100;
      }

      33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
      }

      to {
        left: 0;
        width: 100%;
      }
    }
  }

  .link-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .not-verified {
    top: 80px;
    background-color: #f7cc6e;
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 99;
    transition: .5s;
    font-size: 14px;
  }

  .upgrade-now {
    color: #365de6;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #D5D8DF;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 14px;
  }

  .trial-countdown-not-verified {
    top: 50px;
    display: flex;
    background-color: #f7cc6e;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 99;
    transition: .5s;
  }

  .trial-countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #add8e6;
    width: 100%;
    position: fixed;
    padding: 5px 0px;
    z-index: 99;
    top: 50px;
    transition: 0.5s;

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    @media (max-width: 550px) {
      padding: 5px 8px;
    }

    span {
      min-width: fit-content;
    }

    img {
      margin-right: 5px;
    }

    .upgrade-now {
      text-decoration: underline;
      color: #365de6;
    }

    a {
      cursor: pointer;
    }
  }

  .email-verification-banner-container {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 99;
    background-color: #e4c48c;

    .email-verification-banner {
      width: 100%;
      max-width: 1312px;
      padding: 5px 20px;
      padding-right: 8px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;

      .verify-btn {
        color: #365de6;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #d5d8df;
        padding: 8px;
        font-weight: 600;
        font-size: 14px;
        margin-inline: 20px;
      }

      .verify-text {
        color: #a27125;
        font-weight: 600;
        font-size: 14px;
      }
    }

    @media (max-width: 550px) {
      .email-verification-banner {
        display: flex;
        align-items: center;
        flex-direction: column;

        .verify-btn {
          margin-block: 4px;
          margin-inline: 0;
        }
      }
    }
  }

  .email-verification-banner-bottom-space {
    margin-top: 28px;
  }

  main {
    &#site-content {
      width: 100%;
      min-height: 100vh;
      z-index: 0;
      position: relative;

      &.show-trial-banner {
        .main-wrap {
          margin-top: 30px;
        }

        .sidebar-channel-only-wrap-is-open {
          margin-top: 0px;
        }
      }

      &.show-topbar {
        // padding-top: 50px;
        min-height: calc(100vh - 50px);
      }

      .main-wrap {
        height: 100%;
        display: flex;
        position: relative;
        // min-height: calc(100vh - 100px);
        // min-height: 100%;

        &.channel-preview {
          .content-wrap {
            padding-left: 300px;
          }
        }

        &.channel-preview-notifications {
          .content-wrap {
            overflow: hidden;
          }
        }

        &.channel-sidebar-only {
          .content-wrap {
            padding-left: 242px !important;
          }
        }

        &.sidebar-is-open {
          .content-wrap {
            padding-left: 291px;
          }

          &.channel-preview-notifications {
            .content-wrap {
              overflow: hidden;
            }
          }

          &.empty-user-groups {
            .content-wrap {
              padding-left: 0;
            }
          }
        }

        .sidebar__content-channels-list {
          display: none;
        }

        &.admin-panel {
          .sidebar__content-channels-list {
            display: none !important;
          }
        }

        &.channel-preview {
          position: relative;

          .sidebar__content-channels-list {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            overflow: hidden;
          }
        }

        .content-wrap {
          width: 100%;
          min-height: 100vh;
          position: relative;
          transition: padding-left ease-in-out 0.2s;
          padding-top: 50px;

          &.no-auth {

            .modal {
              position: absolute;
            }
          }

          .content-page-wrap {
            width: 695px;
            padding-top: 50px;
          }
        }
      }
    }
  }
}

#master.res-no-sub-nav main#site-content .main-wrap.sidebar-is-open .content-wrap {
  padding-left: 0;
}

#master {
  &.res-no-sub-nav {
    main#site-content {
      .main-wrap.empty-user-groups {
        .content-wrap {
          padding-left: 0;

          .kolona {
            .plan-selection-head {
              margin-left: 300px;
            }

            .plan-selection-wrapper {
              width: calc(100% - 300px);
              margin-left: 300px;
            }
          }
        }
      }
    }
  }
}

html {
  form {
    label {
      font-size: 14px;
    }
  }

  .editor-js {
    .ce-toolbar__plus {
      left: 0 !important;
    }

    .ce-toolbar__actions {
      right: 0 !important;
    }

    .ce-settings__button {
      float: left;
    }

    .ce-inline-toolbar__buttons {
      width: auto;

      &>* {
        position: relative;
        float: left;
      }
    }

    .ce-conversion-tool div {
      display: initial !important;
    }

    .ce-settings--opened {
      flex-direction: column;

      .cdx-list-settings {
        .cdx-settings-button {
          float: left;
          margin: 0;
        }
      }
    }
  }
}

.gp-trial-banner-remover-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 30px;
  cursor: pointer;

  svg {
    path {
      fill: #000000;
    }
  }
}

.groupapp-home-page{
  padding-top: 100px;

  @media (max-width: 550px) {
   width: calc(100vw - 10px);
   margin: 0 auto;
  }
}