.x-icon {
    color: #505050fe;
    height: "100%";
    width: "20px";
}

.draft-content {
    &-container {
        @media (max-width: 991px) {
            span[data-text="true"] {
                font-size: unset !important;
            }
        }

        .public-DraftEditor-content{
            h1, h2, h3, h4, h5, h6 {
                margin-block-start: 0;
                margin-block-end: 0;
            }
        }
    }
    &-wrapper {
        width: 100%;
        height: fit-content;
        position: relative;

        .public-DraftStyleDefault-block {
            margin: 0 !important;
            line-height: 27px !important;
        }

        .public-DraftStyleDefault-ol,
        .public-DraftStyleDefault-ul {
            padding-left: 20px !important;
        }

        .public-DraftStyleDefault-ol {
            li::before {
                top: 0px !important;
            }
        }

        .DraftEditor-root {
            @media (max-width: 550px) {
                z-index: 0;
            }
            figure {
                margin-block-start: 0.5em;
                margin-block-end: 0.5em;
                margin-inline-start: 0;
                margin-inline-end: 0;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &--restricted {
            max-height: 300px;
            overflow: hidden;
            mask-image: linear-gradient(180deg, #000 80%, transparent);
            -webkit-mask-image: linear-gradient(180deg, #000 80%, transparent);
        }

        &--smallheader {
            h1,
            h2,
            h3 {
                span[data-text="true"] {
                    font-size: 18px !important;
                }
            }
        }

        h1,
        h2,
        h3 {
            span[data-text="true"] {
                line-height: 35px !important;
            }
        }
    }

    &-action {
        &-container {
            width: 100%;
        }

        &-button {
            color: #1d2839;
            font-size: 14px;

            padding: 5px 5px;
            border-radius: 4px;
            font-weight: bold;
            margin: 0;
            padding: 0;
            cursor: pointer;
        }

        &-ellipsis {
            margin: 0;
            padding: 0;
            height: fit-content;
            display: block;
            width: fit-content;
            color: #1d2839;
        }
    }

    &-mention {
        &-text {
            background-color: #d6e7ff !important;
            color: #365de6;
            padding: 1px;
            border-radius: 1px;
        }
    }
}
