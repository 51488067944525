.gp-pb-page-builder-footer {
    &-container {
        width: 100%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }

    &-content {
        &-container {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 900px;
            max-width: 100%;
            padding: 0 10px;
        }
    }

    &-wrapper {
        padding: 5px 12px;
        background-color: #EFF0F2;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #333333;
        border-radius: 4px;
    }

    &-label {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
        color: #1e1e1e;

        &:hover {
            color: #365de6
        }
    }

    &-icon {
        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
        }
    }
}