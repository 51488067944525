.actions-dropdown-menu {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .dots {
        z-index: 99 !important;
        // padding: 10px;
        min-width: 5px;
    }
    .member-actions-dropdown {
        width: 100px !important;
        display: block !important;
        top: 15px !important;
        padding: 5px 0 !important;
        z-index: 100 !important;
        ul {
            width: 100%;
            margin: 0;
            padding: 0;
            li {
                cursor: pointer;
                width: 100%;
                text-align: left;
                justify-content: left !important;
                flex-direction: row !important;
                padding: 0px 10px !important;
                margin: 0 !important;
            }
        }
    }
}

