.onboarding-profile-steps {
    max-width: 640px;
    width: 640px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 100%;
    justify-content: flex-start;

    &-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        h1 {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            color: #333;
            margin: 0;
            padding: 0;
        }

        span {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #666;
        }
    }

    &-photo-upload {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;
        svg {
            cursor: pointer;
        }
        h4 {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0;
            padding: 0;
            color: #365de6;
            cursor: pointer;
            margin-top: 16px;
            margin-bottom: 8px;
        }

        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #929292;
        }

        &-photo {
            width: 115px;
            height: 115px;
            border-radius: 50%;
            background-color: #929292;
            padding: 1px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                box-shadow: 0 0 1px transparent;
            }
        }

        &-new {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    &-profile-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &-general {
            @media (max-width: 768px) {
                width: 100%;
                max-width: calc(100vw - 30px);
                margin: 0 auto;
            }
            h1 {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 800;
                line-height: 17px;
                letter-spacing: 0.30000001192092896px;
                text-align: left;
                color: #c9c9c9;
            }

            &-details {
                margin-top: 24px;

                .profile-fields-section {
                    display: flex;
                    flex-direction: column;
                    width: 620px;
                    margin-bottom: 24px;

                    @media (max-width: 768px) {
                        width: calc(100vw - 32px);
                        // margin: 0 auto;
                    }

                    label {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #333;
                        margin-bottom: 12px;
                    }

                    input {
                        // margin-top: 12px;
                        // margin-bottom: 24px;
                        border: 1px solid #d5d8df;
                        padding: 13px 16px;
                        border-radius: 4px;
                        width: 100%;
                        background-color: #f8f8f8;
                        @media (max-width: 768px) {
                            width: calc(100vw - 32px);
                            margin: 0 auto;
                            // margin-top: 12px;
                            // margin-bottom: 24px;
                        }
                    }

                    .custom-select {
                        margin-top: 12px;
                        margin-bottom: 24px;
                        position: relative;
                    }

                    select {
                        appearance: none;
                        /*  safari  */
                        -webkit-appearance: none;
                        /*  other styles for aesthetics */
                        width: 100%;
                        font-size: 14px;
                        color: #666666;
                        padding: 0.675em 6em 0.675em 1em;
                        border: 1px solid #caced1;
                        border-radius: 0.25rem;
                        cursor: pointer;
                        background-color: #f8f8f8;
                    }

                    .custom-select::before,
                    .custom-select::after {
                        --size: 0.3rem;
                        content: "";
                        position: absolute;
                        right: 1rem;
                        pointer-events: none;
                    }

                    .custom-select::before {
                        border-left: var(--size) solid transparent;
                        border-right: var(--size) solid transparent;
                        border-bottom: var(--size) solid black;
                        top: 40%;
                    }

                    .custom-select::after {
                        border-left: var(--size) solid transparent;
                        border-right: var(--size) solid transparent;
                        border-top: var(--size) solid black;
                        top: 55%;
                    }

                    textarea {
                        resize: none;
                        display: block;
                        height: 134px;
                        color: #666666;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        background: #f8f8f8;
                        border: 1px solid #d5d8df;
                        border-radius: 4px;
                        // margin-top: 12px;
                        // margin-bottom: 24px;
                        padding: 13px 16px;
                    }

                    &-links {
                        display: flex;
                        align-items: center;
                        width: 620px;
                        // margin-top: 12px;
                        // margin-bottom: 24px;
                        @media (max-width: 768px) {
                            max-width: calc(100vw - 30px);
                        }

                        input {
                            border-left: none;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            
                        }
                        &-icon {
                            display: flex;
                            align-self: center;
                            border: 1px solid #d5d8df;
                            padding: 9px 10px;
                            border-radius: 4px;
                            // margin-top: -12px;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            background-color: #f8f8f8;

                            @media (max-width: 768px) {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }

        .link-title {
            margin-top: 24px;
        }
    }

    .cropper-actions {
        // @media (max-width: 768px) {
        bottom: -58px !important;
        // }
    }
}

// hides the arrow
.country-select_customSelect__njXO8::after,
.timezone-select_customSelect__1OB04::after {
    display: none;
}

.css-13cymwt-control{
    background-color: #f8f8f8 !important;
}