.file-button-container {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 54px;
    position: relative;

    .file-icon {
        position: absolute;
        top: -21px;
        color: #888;
        width: 17px;
        height: 17px;
        stroke-width: 2;
    }
}
