.messages-content {
    &.newgroup {
        overflow: overlay;
    }
    &-header.newgroup {
        border-bottom: none !important;
    }
    &-body.newgroup {
        padding: 25px 0px;

        .group-topic {
            box-sizing: border-box;
            height: 42px;
            border: 1px solid #d5d8df;
            border-radius: 4px;
            background-color: #ffffff;
            color: #33353b;
            padding: 5px 10px;

            font-size: 13px !important;
        }
        .group-description {
            @extend .group-topic;
            height: 84px;
            width: 100%;
        }
        .group-members {
            box-sizing: border-box;
            border: 1px solid #d5d8df;
            border-radius: 4px;
            background-color: #ffffff;
            width: 100%;
            color: #84878e;

            font-size: 12px;
            min-height: 84px;
            padding: 10px;
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            // grid-template-columns:  1fr 1fr 1fr 1fr;
            .search-group {
                border-radius: 3px;
                color: #33353b !important;
                padding: 15px;
                width: 180px;
            }
            .selected-member-item {
                padding: 5px;
                border-radius: 3px;
                background-color: #d9e1fb;
                margin-right: 5px;
                margin-bottom: 5px;
                color: #767a8a;
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                width: 180px;

                .user-component {
                    margin-right: 10px;

                    .info-name {
                        .name {
                            font-size: 13px;
                            font-weight: normal;
                            max-width: 80px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
                .selected-close {
                    letter-spacing: 0;
                    line-height: 25px;
                    height: 32px;
                    display: flex;
                    align-items: center;

                    .member-close {
                        height: 12px;
                        width: 12px;
                        text-align: right;
                        cursor: pointer;
                    }
                    .member-close g {
                        fill: #666;
                    }
                }
            }
            .new-message-menu {
                right: 0 !important;
                .search-members-dropdown {
                    top: 54px !important;
                    left: 0 !important;
                    overflow: hidden;
                    width: 180px !important;

                    .user-component {
                        cursor: pointer;
                        .info-name {
                            .name {
                                font-size: 13px;
                                font-weight: normal;
                                max-width: 120px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
        .create {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .create-btn {
                box-sizing: border-box;
                width: 168px;
                height: 48px;
                border: 1px solid #365de6;
                border-radius: 4px;
                background-color: #365de6;
                color: #ffffff;
                font-size: 17px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                text-align: center;
            }
            .cancel-btn {
                box-sizing: border-box;
                width: 168px;
                height: 48px;
                border: 1px solid #19283a;
                border-radius: 4px;
                background-color: #fff;
                color: #19283a;
                font-size: 17px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                text-align: center;
                margin-right: 20px;
            }
        }
        .error-label {
            color: rgb(211, 8, 8);
        }
    }
}
