.messages-content-header.new-direct-message {
    .search-members-dropdown {
        width: 300px !important;
        top: 45px !important;
        left: 0;
        overflow: hidden;

        .user-component {
            cursor: pointer;
            .info-name {
                .name {
                    font-size: 13px;
                    font-weight: normal;
                    max-width: 200px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        &:before {
            left: 30px;
        }
        &:after {
            left: 30px
        }
        .search-icon {
            top: 20px
        }
    }
}
