#master {
  main {
    &#site-content {
      .main-wrap {
        .content-wrap {
          &.no-auth {
            .modal {
              &.home-acc__login {
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .modal {
    &.home-acc__login {
      &.home--acc__login-component {
        max-width: 400px;
      }
      .home--acc__login-media {
        img {
          height: 400px;
        }
      }
    }
  }
  #master {
    main {
      &#site-content {
        .main-wrap {
          .content-wrap {
            &.no-auth {
              .modal {
                &.home-acc__login {
                  max-width: calc(100% - 30px);
                  margin: auto;
                  top: 0;
                  bottom: 0;
                  height: 402px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #master {
    main {
      &#site-content {
        .main-wrap {
          .content-wrap {
            &.no-auth {
              .modal {
                &.home-acc__login {
                  display: block;
                  height: auto;
                  max-width: 320px;
                  margin: 70px auto 80px;
                  position: relative;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    &.home-acc__login {
      .home--acc__login-component {
        height: auto;
        padding: 15px;
      }
      .home--acc__login-media {
        width: 100%;
        img {
          height: 300px;
        }
        .quote {
          padding: 0 15px;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .modal.home-acc__login .home--acc__login-media {
    display: none;
  }
  #master
    main#site-content
    .main-wrap
    .content-wrap.no-auth
    .modal.home-acc__login {
    height: 390px;
  }
  #master.res-login {
    main#site-content .main-wrap .content-wrap.no-auth .modal.home-acc__login {
      height: 390px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      padding: 0;
    }
  }
  .dpxnGB {
    width: 320px;
    .popup-login-modal {
      width: 100%;
      padding: 15px 0;
      .close-modal {
        right: 10px;
      }
    }
    .card--login--wrap.popup form input {
      width: 100% !important;
    }
  }
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  #master.res-login main#site-content .main-wrap .content-wrap,
  #master.res-login main#site-content,
  #master.res-forgot-password main#site-content .main-wrap .content-wrap,
  #master.res-forgot-password main#site-content,
  #master.res-signup main#site-content .main-wrap .content-wrap,
  #master.res-signup main#site-content,
  #master.res-reset-password main#site-content .main-wrap .content-wrap,
  #master.res-reset-password main#site-content {
    min-height: 85vh;
  }
}
