.photo-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 408px;
  height: 386px;
  background: #ffffff;
  border: 1px solid #d5d8df;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: absolute;
  top: 28%;

  .profile-image-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    margin-block: 25px;
  }

  .user-profile-image-wrapper-2 {
    width: 151px;
    height: 151px;
    margin-bottom: 28px;
    border-radius: 50%;
    background-color: #929292 ;
    padding: 1px;
    cursor: pointer;
    position: relative;

    .change-photo {
      font-size: 12px;
      line-height: 17px;
      color: #1e1e1e;
      padding: 4px;
      border: 1px solid #a4a4a4;
      border-radius: 4px;
      cursor: pointer;
      background-color: #d5d8df;
      position: absolute;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      width: max-content;
    }

    .no-image-text-2 {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #ffffff;

    }

    .user-profile-image-2 {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0 0 1px transparent;
    }
  }
  .close-photo-modal {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #1e1e1e;
    cursor: pointer;
  }
  @media (max-width: 550px) {
    .close-photo-modal {
      top: 20px;
      right: 20px;
    }
  }

  .delete-photo-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #eb5757;
    cursor: pointer;
  }
  .change-photo-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    padding: 12px 16px;
    border: 1px solid #1e1e1e;
    border-radius: 4px;
    margin-bottom: 29px;
    cursor: pointer;
  }
}


@media (max-width: 768px) {
  .photo-modal {
    width: 328px;
    height: 386px;
  }
}

.confirmation-modal {
  height: 242px;
  width: 654px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .confirmation-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .confirmation-msg {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      .confirmation-btns {
        display: flex;
        margin-top: 6px;

        .del-btn {
          box-sizing: border-box;
          height: 37px;
          width: 125px;
          border: 1px solid #dd213d;
          border-radius: 4px;
          color: #dd213d;

          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 15px;
          transition: 0.5s;
          margin-right: 12px;

          &:hover {
            color: #ffffff;
            background-color: #dd213d;
          }
        }

        .cancel-btn {
          box-sizing: border-box;
          height: 37px;
          width: 125px;
          border: 1px solid #365de6;
          color: #365de6;
          border-radius: 4px;

          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 15px;
          transition: 0.5s;
          margin-left: 12px;

          &:hover {
            color: #ffffff;
            background-color: #365de6;
          }
        }
      }

      .title {
        color: #000000;

        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin: 0;
      }

      .subtitle {
        margin: 24px 0;
        color: #767a8a;

        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
.content-loader-profile-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 4px;
  padding: 8px;
  padding-left: 44px;
  width: 688px;
  min-width: 688px;
  height: 727px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #ffffff;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .content-loader-profile-details {
    display: none;
    font-size: 12px;
  }
}

.content-loader-profile-details-small {
  display: none;
}

@media (max-width: 768px) {
  .content-loader-profile-details-small {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 18px;
    width: 300px;
    height: auto;
  }
}

.content-loader {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  width: 688px;
  min-width: 688px;
  background-color: #ffffff;
  height: auto;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .content-loader {
    display: none;
    font-size: 12px;
  }
}

.content-loader-small {
  display: none;
}

@media (max-width: 768px) {
  .content-loader-small {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 18px;
    width: 300px;
    height: auto;
  }
}

.content-loader-comment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  padding: 18px;
  width: 688px;
  min-width: 688px;
  background-color: #ffffff;
  height: auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .content-loader-comment {
    display: none;
    font-size: 12px;
  }
}

.content-loader-comment-small {
  display: none;
}

@media (max-width: 768px) {
  .content-loader-comment-small {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 18px;
    width: 300px;
    height: auto;
  }
}

.content-loader-events {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  width: 688px;
  min-width: 688px;
  background-color: #ffffff;
  height: auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .content-loader-events {
    display: none;
    font-size: 12px;
  }
}

.content-loader-events-small {
  display: none;
}

@media (max-width: 768px) {
  .content-loader-events-small {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 18px;
    width: 300px;
    height: auto;
  }
}

.shortened-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
