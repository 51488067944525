.card {
  width: 100%;
  max-width: 695px;
  border: none !important;
  border-radius: .3rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 6px;

  &.is--invited {
    max-width: 100%;
    margin-bottom: 0px !important;

    &.card--group-header {
      width: 100% !important;
    }
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.card-welcome {
    height: 216px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 40px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
      color: #000000;
    }

    span {
      font-size: 14px;
      color: #767a8a;
      margin-bottom: 35px;
    }

    .btn-create-group {
      border: 1px solid #365de6;
      background-color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 4px;
      height: 43px;
      transition: background-color ease-in 200ms;
      padding: 9px;

      span {
        color: #365de6;
        font-weight: 600;
        margin: 0 0 0 10px;
      }

      &:hover {
        color: #ffffff;
        background-color: #365de6;

        path {
          fill: #ffffff;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }

  &.card-recommendation {
    padding: 20px;

    .card-recommendation--content {
      position: relative;
      width: 100%;

      .card-recommendation--content-media {
        padding-right: 15px;

        img {
          width: 53px;
          height: 53px;
          border-radius: 4px;
          background-color: #365de6;
          display: block;
          object-fit: cover;
        }
        .group-name {
          width: 53px;
          height: 53px;
          color: #fff;
          border-radius: 4px;
          font-size: 25px;
          font-weight: 600;
        }
      }

      .card-recommendation--content-page-info {
        h3 {
          margin: 0;
          color: #000000;
          font-size: 18px;
          font-weight: 600;
          line-height: 15px;
          cursor: pointer;
        }

        .page--group-type {
          font-size: 13px;
          color: #797979;
          display: block;
          margin-top: 6px;
          text-transform: capitalize;
        }

        .page--group-content {
          font-size: 14px;
          padding: 0;
          margin: 5px 0 15px;

          button {
            color: #365de6;
            margin-left: 0;
          }
        }

        .page--member-count {
          color: #000;
          font-size: 14px;
        }
      }
    }

    .card-recommendation--action {
      margin-left: 15px;

      button {
        border-radius: 4px;
        border: 1px solid #365de6;
        width: 88px;
        height: 28px;
        min-height: 1px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        background: #365de6;
        img {
          margin-left: 10px;
        }

        span {
          color: #365de6;
        }

        &:hover {
          background-color: #365de6;
          color: #ffffff;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  &.card--group-header {
    border: 0;

    .card--group-header--wrap {
      position: relative;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px 3px 0 0;
      }
    }

    .card--group-header-cover {
      height: 180px;
      width: 100%;
      background-color: #8aa0f0;
      border-radius: 3px 3px 0 0;
      padding: 17px;
      overflow: hidden;

      .btn-edit-group {
        position: relative;
        z-index: 20;
        float: right;
        height: 28px;
        width: 90px;
        border: 1px solid #ffffff;
        background: transparent;
        color: white;
        border-radius: 4px;
        font-weight: bold;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        font-size: 12px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
          transition: background-color 0.3s ease-in;
        }
      }
    }

    .card--group-details--wrap {
      min-height: 190px;
      height: auto;
      padding: 7px 35px 7px;
      border: solid #d5d8df;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 3px 3px;

      .card--group-details-name {
        margin-top: 10px;
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      .card--group-details-status {
        color: #000000;
        font-size: 13px;
      }

      .card--group-details-description {
        white-space: pre-line;
        line-height: 18px;
        margin-top: 7px;
        color: #33353b;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          color: #365de6;
        }

        .read-more {
          display: inline;
          span {
            .read-more__text--remaining {
              display: flex;

              &.read-more__text--show {
                display: flex;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button {
          height: 28px;
          width: 80px;
          border: 1px solid #365de6;
          border-radius: 4px;
          margin-right: 13px;
          font-size: 12px;
          font-weight: bold;
          color: #365de6;
        }
      }

      .actions2 {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .members-count {
          color: #000000;
          font-size: 14px;
          line-height: 16px;
          margin-right: 16px;
        }

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;

          span {
            margin: 0 10px;
          }
        }
      }
    }

    &.is--not__member {
      // margin-top: 50px;
      .card--group-details--wrap {
        min-height: 160px;
        padding-top: 30px;
        border-radius: 0;
      }
      .card--signup--wrap {
        border: solid #d5d8df;
        border-width: 0 1px 1px 1px;
        border-radius: 0 0 3px 3px;
        padding-bottom: 30px;
        padding-top: 30px;
      }
    }
    &.is--not__invited {
      margin-top: 50px;
    }
  }

  &.card-share-post {
    padding: 22px 35px;
    &.card-share-post-active {
      box-shadow: 0 0 10px 5px transparent;
      animation: mymove 1.5s infinite;
    }
    &.media-uploading {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 60px);
        z-index: 99;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100px;
        height: 70px;
        z-index: 99;
      }
    }

    @keyframes mymove {
      50% {
        box-shadow: 0 0 10px 5px #365de6;
      }
    }

    .card-share-post-textarea-wrap {
      position: relative;
      .RichEditor-root {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  &.card-information {
    min-height: 276px;
    padding: 22px 35px;

    .icon {
      opacity: 0.3;
      height: 52.61px;
      width: 59.61px;
      color: #767a85;
      background-size: 100%;
      max-width: 120px;
      margin-bottom: 20px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjNzY3YTg1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTg4LjksOS4zTDY5LjQsMjguOGMtNS45LTIuNy0xMi4yLTQuNS0xOC42LTQuNWMtMjIuMiwwLTQwLjksMTguNS00Ny4xLDI1LjRjLTEuMywxLjQtMS4zLDMuNiwwLDUgICBjMy42LDQsMTEuNiwxMS45LDIyLDE3LjhMMTMuMSw4NS4xbDUuNiw1LjZsNzUuOC03NS44TDg4LjksOS4zeiBNNTIuMyw0NS45Yy0wLjQsMC0wLjktMC4xLTEuNC0wLjFjLTMuNSwwLTYuMywyLjgtNi4zLDYuMyAgIGMwLDAuNSwwLDEsMC4xLDEuNGwtOS41LDkuNWMtMi4xLTMuMS0zLjQtNi44LTMuNC0xMC45YzAtMTAuNiw4LjYtMTkuMiwxOS4yLTE5LjJjNC4xLDAsNy44LDEuMywxMC45LDMuNEw1Mi4zLDQ1Ljl6Ij48L3BhdGg+PHBhdGggZD0iTTk3LjgsNDkuNmMtMi45LTMtOC41LTguNC0xNS41LTEzLjNMNjkuNyw0OC45YzAuMiwxLjEsMC4zLDIuMiwwLjMsMy4zYzAsMTAuNi04LjYsMTkuMi0xOS4yLDE5LjJjLTEuMSwwLTIuMi0wLjEtMy4zLTAuMyAgIGwtNy40LDcuNGMzLjQsMC45LDcsMS40LDEwLjcsMS40YzIyLDAsNDAuNy0xOC4xLDQ3LTI1Qzk5LjIsNTMuMyw5OS4yLDUxLjEsOTcuOCw0OS42eiI+PC9wYXRoPjwvZz48L3N2Zz4=");
    }
  }

  &.card-post {
    padding: 15px 35px;

    .card-header {
      .dropdown-menu {
        .dropdown {
          right: -12px;
          top: 20px;
        }
      }
    }

    .card-content-body {
      white-space: pre-line;
      line-height: 18px;
      font-size: 15px;
      // margin-top: 20px;
      color: #000000;
      font-weight: normal;

      .content-link {
        color: #365de6;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .card-content-action {
      margin-left: -35px;
      margin-right: -35px;
      margin-top: 21px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;

      .share-btn {
        margin-top: 4px;
      }

      button:nth-child(1) {
        margin-left: 35px;
      }

      button {
        margin-right: 20px;
        margin-top: 0px;

        span {
          margin-left: 5px;
        }
      }
    }
  }
  &.card-channel-header {
    padding: 22px 35px;

    &.channel-public-view {
      margin-top: 50px;
    }
    .card-channel-header__head-title {
      color: #365de6;
      font-size: 22px;
      font-weight: bold;
    }
    .card-channel-header__head-status {
      text-transform: capitalize;
      font-size: 12px;
      color: #33353b;
      background-color: #eff0f2;
      border-radius: 11px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
    }
    .card-channel-header__description {
      white-space: pre-line;
      line-height: 18px;
      margin-top: 15px;
      font-size: 14px;
      color: #33353b;

      a {
        color: #365de6;
      }
    }
    .card-channel-header__action {
      margin-top: 20px;
      .card-channel-header__action--members {
        margin: 0px;
        font-size: 12px;
        color: #33353b;
        &.non-admin-channel {
          margin-left: 15px;
        }
      }
    }
  }
}
@media(max-width: 991px) {
  .card.card-channel-header.channel-public-view {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .card {
    &.card--group-header {
      .card--group-details--wrap {
        padding: 15px;
      }
    }
    &.card-share-post {
      padding: 15px;
    }
  }
}
@media(max-width: 550px) {
  .card.card-recommendation {
    .card-recommendation--content {
      max-width: calc(100% - 105px);
    }
  }
}