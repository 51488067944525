.gp-pb-live-page {
    &-container {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &-error {
        &-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 50px;
        }
    }
}